import React, {  Component } from "react";
import { Link } from 'react-router-dom';
import {  Button, FormGroup, Label, Input } from "reactstrap";
import backlogo from '../../../assets/images/svg/Back.svg'
import { setRegistrationEmail } from "../../../reducers/counterSlice";
import { connect } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { toast, Bounce, Slide, ToastContainer } from "react-toastify";
import Loader from "react-loaders";
import axiosInstance from "../../configuration/Request";

const mapStateToProps = (state) => {
  
  return {
    reduxData: state.counterSlice
  };
};

const mapDispatchToProps = {
  setRegistrationEmail
};

class OTP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      createAccountEmail: this.props?.reduxData?.registrationEmail ?? '',
      createAccountOTP: '',
      seconds: 60,
      optErrorMsg: '',
      loader: false,
      count: 1,
    };
  }

  componentDidMount = () => {
    this.interval = setInterval(this.tick, 1000);

  }




  proceedAPI = () => {
    this.setState({ loader: true });
    axiosInstance.get(process.env.REACT_APP_BASE_URL + `api/otps?email=${this.state.createAccountEmail ?? ''}&otp=${this.state.createAccountOTP ?? ''}`)
      .then(res => {
        if (res.data.message === 'OTP verified successfully') {
          this.setState({ optErrorMsg: "" });
          this.props.history.push('/auth/basicDetails');
        } else {
          this.setState({ optErrorMsg: "OTP wrong entered. Please try again." });
        }
      }).catch(err => {
        this.setState({ optErrorMsg: "Something went wrong. Please try again." });
        console.log(err)
      }).finally(() => {
        this.setState({ loader: false });
      });
  }

  handleEmailChange = (e) => {
    this.setState({ createAccountEmail: e.target.value });
  };
  handleOTPChange = (e) => {
    this.setState({ createAccountOTP: e.target.value?.slice(0, 6) });
  };

  tick = () => {
    this.setState(prevState => ({
      seconds: prevState.seconds - 1
    }), () => {
      if (this.state.seconds === 0) {
        clearInterval(this.interval);
      }
    });
  };


  sendOTPFn = () => {

    if (this?.state?.count <= 3) {
      this.setState((prevState) => ({ count: prevState.count + 1, loader: true, createAccountOTP: '', optErrorMsg: '' }));
      axiosInstance
        .post(process.env.REACT_APP_BASE_URL + "api/otps", {
          "data": {
            "email": this.state.createAccountEmail ?? ''
          }
        })
        .then((res) => {
          this.setState({ otpSent: true, seconds: 60 }, () => {
            clearInterval(this.interval)
            this.interval = setInterval(this.tick, 1000);
          })
          toast(
            "OTP sent successfully!",
            {
              transition: Bounce,
              closeButton: true,
              autoClose: 5000,
              position: "top-right",
              type: "success",
            }
          );
        })
        .catch((err) => {
          console.error(" error:", err);
          this.setState({ optErrorMsg: "Something went wrong. Please try after sometime." });
        })
        .finally(() => {
          this.setState({ loader: false });

        });
    } else {
      this.setState((prevState) => ({ count: prevState.count + 1 }));
      toast(
        "Maximum number of OTP sent.Kindly try after sometime.",
        {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "top-right",
          type: "error",
        }
      );
    }
  }


  render() {


    const { seconds, count } = this.state;
    return (
      <>
        <div>
          <Link className='btn-link-primary'
            to={{ pathname: '/auth/register' }}>
            <div className="d-inline-flex gap-2" >
              <img src={backlogo} alt="" />
              <span className="back-text">Back</span>
            </div>
          </Link>
          <h4 className="mb-0">
            <div className="login-text">{'Enter OTP'}</div>
          </h4>


          <div className="my-2 login-subtext">
            We’ve sent an OTP to your Email ID
          </div>

          <div>
            <div>
              <FormGroup floating>
                <Input type="email" name="email" id="exampleEmail" placeholder="Email" disabled
                  value={this.state.createAccountEmail} onChange={this.handleEmailChange}
                />
                <Label for="exampleEmail">
                  Email
                </Label>
              </FormGroup>
              <FormGroup floating>
                <Input type="number" name="otp" id="exampleOTP" placeholder="One Time Password " maxLength={6}
                  value={this.state.createAccountOTP} onChange={this.handleOTPChange} className="no-spinners mt-3"
                />
                <Label for="exampleOTP">
                  One Time Password
                </Label>
              </FormGroup>

            </div>

            <div className="flex-JustifyBetween">
              <div className="otp_timer_body">
                {seconds} seconds
              </div>
              <div className='text-primary-link' onClick={this.sendOTPFn} disabled={(seconds > 0 || count > 4)}>
                Resend OTP
              </div>
            </div>

            <div className="login-button w-100 mt-3">
              {this.state.optErrorMsg &&
                <div className="error-msg-display">
                  {this.state.optErrorMsg}
                </div>
              }
              <Button size="lg" color="primary" className="w-100" onClick={this.proceedAPI} disabled={!(this.state.createAccountEmail && this.state.createAccountOTP)}>
                Verify and Proceed
              </Button>

            </div>

          </div>
        </div>
        <ToastContainer transition={Slide} />
        {this.state.loader &&
          <div className="backdrop">
            <div className="loader">
              <Loader type="ball-pulse-rise" />
            </div>
          </div>
        }
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OTP);

