import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { Button, FormGroup, Label, Input } from "reactstrap";
import backlogo from '../../../assets/images/svg/Back.svg'
import Select from "react-select";
import { setPrimaryRole } from "../../../reducers/counterSlice";
import { connect } from 'react-redux';
import Loader from "react-loaders";
import { removeEmojis } from "../../ReusableComponent/RemoveEmoji";
import axiosInstance from "../../configuration/Request";

const mapStateToProps = (state) => {
    
    return {
        value: state.counterSlice.value
    };
};

const mapDispatchToProps = {
    setPrimaryRole
};

class PrimaryRole extends Component {
    constructor(props) {
        super(props);
        this.state = {
            professionSelected: null,
            professionTypeOptions: [],
            loader: false,
            errorMessage: '',
            otherWorkEntered: '',
        };
    };

    componentDidMount() {

        this.setState({ loader: true })
        axiosInstance.get(`${process.env.REACT_APP_BASE_URL}api/professions`)
            .then(res => {

                let option = res.data.data.reduce((list, item) => {
                    
                    list.push({ value: item.id, label: item?.attributes?.title })
                    return list;
                }, []);

                option.push({ value: 0, label: 'Other' })

                this.setState({ professionTypeOptions: option })

            }).catch(err => {
                console.log(err);
                this.setState({ errorMessage: 'Something went wrong.Please try after sometime.' });
            }).finally(() => {
                this.setState({ loader: false })
            });

    }





    handleChange = (selectedOption) => {
        this.setState({ professionSelected: selectedOption , otherWorkEntered : '' });
    };

    onSkipClick = () => {
        this.props.setPrimaryRole({});
        this.props.history.push('/auth/work');

    }

    onDoneClick = () => {
        if (this.state.professionSelected?.value === 0 &&  this.state.professionSelected?.label === 'Other') {
            this.props.setPrimaryRole({value : 0 , label : this.state?.otherWorkEntered});
        }else{
            this.props.setPrimaryRole(this.state?.professionSelected);
        }
        this.props.history.push('/auth/work');
    }

    handleOtherChange = (e) => {
        const { value } = e.target;
        const filteredValue = removeEmojis(value);
        this.setState({ otherWorkEntered: filteredValue });
    };

    checkDoneBtn = () => {
        if (this.state.professionSelected?.value === 0 &&  this.state.professionSelected?.label === 'Other') {
            if (this.state?.otherWorkEntered)
                return false;
            else
                return true;
        } else
            return !this.state.professionSelected
    }


    render() {


        return (
            <>

                <Link className='btn-link-primary'
                    to={{ pathname: '/auth/basicDetails' }}>
                    <div className="d-inline-flex gap-2" >
                        <img src={backlogo} alt="" />
                        <span className="back-text">Back</span>
                    </div>
                </Link>
                <h4 className="mb-0">
                    <div className="PrimaryRole-text">What’s your primary role?</div>
                </h4>
                <div className="mt-3 login-subtext">
                    This will help us tailor SOSTOS for you.
                </div>
                <div>

                    <div className="mt-2">
                        <FormGroup>
                            <Label for="exampleEmail" style={{ fontSize: '0.75rem', marginBottom: '0rem' }}>Choose Your Profession</Label>
                            <Select styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    // height: '48px',
                                    cursor: "pointer"
                                }),
                                option: (base) => ({
                                    ...base,
                                    cursor: "pointer",
                                }),
                            }} value={this.state.professionSelected} onChange={this.handleChange} options={this.state.professionTypeOptions} />
                        </FormGroup>
                    </div>
                    {(this.state.professionSelected?.value === 0 &&  this.state.professionSelected?.label === 'Other')
                        &&
                        <Input type="text" name="other" id="exampleOther" placeholder="Enter Manually"
                            value={this.state.otherWorkEntered} onChange={this.handleOtherChange}
                            className="mb-3" />}
                    <div>
                        {this.state.errorMessage &&
                            <div className="error-msg-display">
                                {this.state.errorMessage}
                            </div>
                        }
                        <div className="d-flex gap-2 ">

                            <Button className="w-100" color="light" onClick={this.onSkipClick} >
                                Skip
                            </Button>
                            <Button className="w-100" color="primary" onClick={this.onDoneClick} disabled={this.checkDoneBtn()}>
                                Done
                            </Button>
                        </div>
                    </div>

                </div>
                {this.state.loader &&
                    <div className="backdrop">
                        <div className="loader">
                            <Loader type="ball-pulse-rise" />
                        </div>
                    </div>
                }
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrimaryRole);
