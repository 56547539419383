import React, { Fragment, Component } from "react";
import { Link } from 'react-router-dom';
import { Col, Row, Button, Form, FormGroup, Label, Input } from "reactstrap";
import SweetAlert from 'react-bootstrap-sweetalert';
import Loader from "react-loaders";
import { AiOutlineDownload } from "react-icons/ai";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import SOSTOSLogo from "../../assets/images/svg/Sostoslogo.svg";
import SubscriptionChange from "./subscriptionChange";
import axiosInstance from "../configuration/Request";
export default class Billing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cancelPopupVerify: false,
      activePlanDetails: {},
      clickedUpdatePlan: false,
      subscriptionList: [],
    };
  };

  componentDidMount() {

    let highestIdObject = { id: 0 };

    this.props?.loginSubscriptionDetail?.forEach(item => {
      if (item.id > highestIdObject?.id) {
        highestIdObject = { ...item };
      }
    });

    const list = this.props?.loginSubscriptionDetail ;
    const sortedList = list?.length > 0 ? list?.slice()?.sort((a, b) => b?.id - a?.id) : [];
    
    this.setState({ activePlanDetails: highestIdObject, subscriptionList : sortedList })
  };

  onClickCancel = () => {
    this.setState({ cancelPopupVerify: true });
  }

  fetchEndDate = (activePlanDetails) => {

    const utcDate = new Date(activePlanDetails?.attributes?.expiry);
    const formattedDate = new Date(utcDate).toLocaleDateString('en-GB');
    
    return formattedDate;

  }
  fetchDate = (activePlanDetails) => {
    const utcDate = new Date(activePlanDetails?.attributes?.subscription_date);
    const formattedDate = new Date(utcDate).toLocaleDateString('en-GB');
    return formattedDate;
  }

  getAmount = (activePlanDetails) => {
    if (activePlanDetails?.attributes?.currency?.toUpperCase() === 'USD') {
      return `$${activePlanDetails?.attributes?.amount}/${activePlanDetails?.attributes?.frequency?.toUpperCase() === 'YEARLY' ? 'year' : "month"}`
    } else {
      return activePlanDetails?.attributes?.amount
    }
  };


  generatePDF = (item) => {
    this.setState({ loader: true });

    const invoiceContent = `
          <div style="font-family: Arial, sans-serif; width: 210mm; height: 297mm; padding: 20mm; box-sizing: border-box; border: 1px solid #ccc;">
          <div style="text-align: left;">
          <img src=${SOSTOSLogo} alt="" style="width: 120px"/>
          </div>
          <div style="text-align: left; margin-bottom: 20mm;">
            <h1 style="margin: 0;">SOSTOS</h1>
          </div>
            <div style="margin-bottom: 20mm;">
              <h2 style="margin: 0;">Invoice</h2>
              <p style="margin: 5mm 0 0 0;"><strong>Transaction id:</strong> ${item?.attributes?.transaction_id}</p>
            </div>
            <div style="margin-bottom: 20mm;">
              <p style="margin: 0;"><strong>${this.props?.loginDetails?.user?.username}</strong></p>
              <p style="margin: 5mm 0 0 0;">${this.props?.loginDetails?.user?.email}</p>
            </div>
            <table style="width: 100%; border-collapse: collapse; margin-bottom: 20mm;">
              <thead>
                <tr>
                  <th style="border-bottom: 1px solid #ccc; text-align: left; padding-bottom: 5mm;">Item</th>
                  <th style="border-bottom: 1px solid #ccc; text-align: left; padding-bottom: 5mm;">Date</th>
                  <th style="border-bottom: 1px solid #ccc; text-align: left; padding-bottom: 5mm;">Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="padding-top: 5mm;">${item?.attributes?.plan} ${item?.attributes?.frequency?.toUpperCase() === 'YEARLY' ? 'Annual' : "Monthly"}</td>
                  <td style="padding-top: 5mm;">${this.fetchDate(item)}</td>
                  <td style="padding-top: 5mm;">$${item?.attributes?.amount}</td>
                </tr>
              </tbody>
            </table>
            <div style="margin-top: 20mm;">
              <p style="margin: 0;">Questions about your order contact info@sostosllc.com</p>
            </div>
          </div>
        `;

    const hiddenDiv = document.createElement('div');
    hiddenDiv.innerHTML = invoiceContent;
    hiddenDiv.style.position = 'absolute';
    hiddenDiv.style.left = '-9999px';
    document.body.appendChild(hiddenDiv);

    html2canvas(hiddenDiv).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      pdf.addImage(imgData, 'PNG', 0, 0);
      pdf.save("invoice.pdf");
      document.body.removeChild(hiddenDiv); // Clean up the hidden div
    });
    this.setState({ loader: false });
  }

  backClicked = () => {
    this.setState({ clickedUpdatePlan: false })
  }

  onCancelSubscriptionOkClick = () => {
    var date = new Date();
    
    axiosInstance.put(process.env.REACT_APP_BASE_URL + `api/user-subscription-details/${this.state?.activePlanDetails?.id}`,
      {
        "data": {
          "subscription_cancel_date": date?.toISOString() ?? '',
          "is_cancelled": true
        }
      })
      .then(response => {
        axiosInstance
          .get(process.env.REACT_APP_BASE_URL + `api/user-subscription-details?filters[userid][%24eq]=${this.props?.loginDetails?.user?.id}&active=true`)
          .then((res) => {

            this.props?.setloginSubscriptionDetail(res?.data?.data)
            this.setState({ cancelPopupVerify: false })
          })
          .catch((err) => {
            console.error(" error:", err);
          })
          .finally(() => {
          });

      })
      .catch(error => {
        // Handle errors
      });
  };



  render() {
    const { activePlanDetails, clickedUpdatePlan, subscriptionList } = this.state;

    return (
      <>
        {!clickedUpdatePlan ?
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className="Subscription-div">
              <div className="current-plan">
                {`${activePlanDetails?.attributes?.plan} ${activePlanDetails?.attributes?.frequency?.toUpperCase() === 'YEARLY' ? 'Annual' : "Monthly"} Plan: ${activePlanDetails?.attributes?.active ? 'Active' : 'InActive'}`}
              </div>
              {(activePlanDetails?.attributes?.plan?.toUpperCase() !== 'BRONZE' && !activePlanDetails?.attributes?.is_cancelled) && <div className="next-payment">
                Next payment on {this.fetchEndDate(activePlanDetails)} for {this.getAmount(activePlanDetails)}
              </div>}
              {(activePlanDetails?.attributes?.is_cancelled) && <div className="next-payment">
                You have cancelled your current subscription plan. You can use the plan benefits till {this.fetchEndDate(activePlanDetails)}.
              </div>}
              <div className="upgrade-plan d-flex gap-4">
                {(activePlanDetails?.attributes?.plan?.toUpperCase() !== 'BRONZE' && !activePlanDetails?.attributes?.is_cancelled) && <Button size="lg" color="secondary" className="cancel-button" onClick={this.onClickCancel}>Cancel Subscription</Button>}
                <Button size="lg" color="primary" className="upgrade-button" onClick={() => { this.setState({ clickedUpdatePlan: true }) }}>Change Subscription</Button>
              </div>
            </div>
            <div className="order-div">
              <div className="order-text">Order History</div>
              <hr></hr>
              <div className="table-order d-flex">
                <div className="table-top-text-1">Subscription Start Date</div>
                <div className="table-top-text-1">Subscription End Date</div>
                <div className="table-top-text-2" >Type</div>
                <div className="table-top-text-3">Invoice</div>
              </div>
              <hr></hr>
              {
                subscriptionList?.length > 0 &&
                subscriptionList?.map((item, index) => {
                  return (
                    <div className="d-flex">
                      <div className="table-bottom-text-1">{this.fetchDate(item)}</div>
                      <div className="table-bottom-text-1">{this.fetchEndDate(item)}</div>
                      <div className="table-bottom-text-2" >{`${item?.attributes?.plan} ${item?.attributes?.frequency?.toUpperCase() === 'YEARLY' ? 'Annual' : "Monthly"}`}</div>
                      <div className="table-bottom-text-3 " onClick={() => this.generatePDF(item)} role="button">PDF
                        <AiOutlineDownload />
                      </div>
                    </div>
                  )
                })
              }
            </div>
            <div className="payment-div">

            </div>
          </div> :
          <SubscriptionChange backClicked={this.backClicked} activePlanDetails={activePlanDetails}></SubscriptionChange>
        }
        <SweetAlert title="Wish to cancel the subscription?" show={this.state.cancelPopupVerify}
          confirmBtnText='Cancel Subscription'
          onConfirm={this.onCancelSubscriptionOkClick}>
          Your subscription will end on {this.fetchEndDate(activePlanDetails)}. You will still be able to enjoy the benefits until the end of the current subscription period.</SweetAlert>
        {this.state.loader &&
          <div className="backdrop">
            <div className="loader">
              <Loader type="ball-pulse-rise" />
            </div>
          </div>
        }

      </>
    );
  }
}