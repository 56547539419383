import React, { Component } from "react";
import { Button, FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import backlogo from '../../assets/images/svg/Back.svg';
import { connect } from 'react-redux';
import { FaStar } from "react-icons/fa";
import { setSubscriptionDetail, setloginDetails, setloginSubscriptionDetail } from '../../reducers/counterSlice';
import SweetAlert from 'react-bootstrap-sweetalert';
import Loader from "react-loaders";
import { MdStarBorderPurple500 } from "react-icons/md";
import axiosInstance from "../configuration/Request";
import { encryptPassword } from "../../utils/passwordEncryption";


const mapStateToProps = (state) => {

    return {
        reduxData: state.counterSlice
    };
};
const mapDispatchToProps = {
    setSubscriptionDetail, setloginDetails, setloginSubscriptionDetail
};

class SubscriptionChange extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subscriptionPlanTenure: true,
            loader: false,
            subscriptionPlanData: [],
            subscribedPopUp: false,
            confirmationChangePopup: false,
            selectedSubscription: {},
        };

    };


    componentDidMount = () => {
        this.setState({ loader: true });
        axiosInstance.get(process.env.REACT_APP_BASE_URL + `api/subscription-plans?populate=deep&sort=id:ASC`)
            .then((res) => {

                if (res?.data?.data?.length > 0) {
                    let data = [];
                    data.push(res?.data?.data)

                    this.setState({ subscriptionPlanData: res?.data?.data })
                }
            })
            .catch((err) => {
                console.error(" error:", err);
            })
            .finally(() => {
                this.setState({ loader: false });

            });
    };

    onProceedBtnClick = (item) => {

        this.setState({ confirmationChangePopup: true, selectedSubscription: item });

    };

    onProceedClicked = () => {
        var date = new Date();
        const reduxData = this.state.selectedSubscription;
        reduxData['subscriptionPlanTenure'] = this.state?.subscriptionPlanTenure;
        reduxData['purchasedAt'] = date?.toISOString() ?? '';

        this.setState({ confirmationChangePopup: false });

        this.props?.setSubscriptionDetail(reduxData);
        if (reduxData?.pricing?.toUpperCase() === 'FREE') {
            this.setState({ subscribedPopUp: true });
        } else if (this.state.subscriptionPlanTenure) {
            window.location.href = reduxData?.yearly_url;
        } else if (!this.state.subscriptionPlanTenure) {
            window.location.href = reduxData?.monthly_url;
        }
    };

    onSubscribedyesClick = async () => {

        try {
            this.setState({ subscribedPopUp: false, loader: true });
            var date = new Date();
            const encryptedPassword = await encryptPassword(this.props?.reduxData?.basicDetails?.password)

            axiosInstance.post(`${process.env.REACT_APP_BASE_URL}api/auth/local`, {
                identifier: this.props?.reduxData?.registrationEmail,
                password: encryptedPassword,
            })
                .then((res) => {

                    if (res?.data?.user?.confirmed) {
                        this.props.setloginDetails(res?.data);
                        // after payment confiramtion call this API to update role
                        const expiredate = new Date();
                        if (this.state?.subscriptionPlanTenure) {
                            expiredate.setFullYear(date.getFullYear() + 1);
                        } else {
                            expiredate.setDate(date.getDate() + 30);
                        }
                        axiosInstance
                            .post(`${process.env.REACT_APP_BASE_URL}api/user-subscription-details`, {
                                "data": {
                                    "userid": res?.data?.user?.id,
                                    "plan": 'Bronze',
                                    "frequency": !this.state?.subscriptionPlanTenure ? "yearly" : "monthly",
                                    "amount": 'Free',
                                    "subscription_date": date.toISOString() ?? '',
                                    "active": true,
                                    "expiry": expiredate.toISOString() ?? '',
                                    "currency": '',
                                    "subscription_id": null,
                                    "session_id": '',
                                    "is_cancelled": false,
                                    "transaction_id": `uf_${Math.floor(1000 + Math.random() * 9000)}`,
                                }
                            })
                            .then((response) => {

                                axiosInstance
                                    .get(process.env.REACT_APP_BASE_URL + `api/user-subscription-details?filters[userid][%24eq]=${res?.data?.user?.id}&active=true`)
                                    .then((res) => {
                                        // 
                                        // let highestIdObject = { id: 0 };

                                        // res?.data?.data.forEach(item => {
                                        //     if (item.id > highestIdObject?.id) {
                                        //         highestIdObject = { ...item };
                                        //     }
                                        // });
                                        // 
                                        this.props?.setloginSubscriptionDetail(res?.data?.data)
                                        this.props.history.push('/app/cancerType');
                                    })

                                // this.props?.setloginSubscriptionDetail(response?.data?.data)
                            })
                            .finally(() => {
                                this.setState({ loader: false })
                            });

                    }
                })
                .catch((err) => {
                    // Handle authentication error
                    console.error("Authentication error:", err);
                    this.setState({ loader: false })

                })
                .finally(() => {
                });
        } catch (error) {
            console.error('Failed to encrypt password:', error);
        }
    };

    onQueryClicked = () => {
        this.setState({ confirmationChangePopup: false });
    };

    fetchEndDate = (activePlanDetails) => {

        const utcDate = new Date(activePlanDetails?.attributes?.expiry);
        const formattedDate = new Date(utcDate).toLocaleDateString('en-GB');
        return formattedDate;
    }

    render() {

        return (
            <>
                <div>
                    <div lg="11" md="9" sm="10" className="box-container">
                        <div className="d-inline-flex gap-2" role="button" onClick={this.props.backClicked}>
                            <img src={backlogo} alt="" />
                            <span className="back-text">Back</span>
                        </div>
                        <div className="header-container-subscription">


                            <div className="Sub-title-subscription">Select your Subscription Plan</div>

                            <div className="mt-3 subtext-subscription">
                                Take your desired plan to get access to our content.
                            </div>
                            <div className="monthlyYearlyBox">
                                <div className="d-flex gap-2" style={{ fontSize: "1rem" }}>
                                    <span>
                                        Monthly
                                    </span>
                                    <FormGroup switch className="custom-switch">
                                        <Input
                                            type="switch"
                                            checked={this.state.subscriptionPlanTenure}
                                            onClick={() => {
                                                this.setState((prevState) => ({ subscriptionPlanTenure: !prevState.subscriptionPlanTenure }));
                                            }}
                                        />
                                        <Label check>Yearly</Label>
                                    </FormGroup>
                                </div>
                                <div class="box-text" style={{ marginLeft: "5px" }}>
                                    <FaStar />
                                    <span style={{ marginLeft: "4px" }}>
                                        Choose yearly for better discounts
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid-container-subscription">
                        {this.state.subscriptionPlanData?.map((item, index) => {
                            const { title, description, pricing, yearly_pricing, feature_lists, monthly_url, yearly_url } = item.attributes;
                            return (
                                <div key={index} className="grid-item-subscription" style={{ backgroundColor: 'white' }} >
                                    {title?.toUpperCase() === 'SILVER' && <div className="Recommended_container">
                                        Recommended for you
                                    </div>}
                                    <div>
                                        <div className="mb-0 plan-text">{title}</div>
                                        {(this.state?.subscriptionPlanTenure && pricing !== 'Free') ?
                                            <>
                                                <div className="mb-0 price-text">${yearly_pricing}/year</div>
                                                <div className="mb-0 mt-0 plan-text">${(yearly_pricing / 12)?.toFixed(2) ?? ''}/month</div>
                                            </>
                                            : (!this.state?.subscriptionPlanTenure && pricing !== 'Free') ?
                                                <div className="mb-0 price-text">${pricing}/month</div> :
                                                <div className="mb-0 price-text">Free</div>
                                        }

                                        <div className="mb-0 plan-text">{description}</div>
                                        <div class="stroke-div mb-2" />
                                        {feature_lists?.data?.map((item, index) => {
                                            return (
                                                <div className="d-flex ">
                                                    <MdStarBorderPurple500 style={{ height: '21px', marginRight: '4px' }} />
                                                    <div key={index} class="mb-0 plan-features">{item?.attributes?.description}</div>
                                                </div>
                                            )
                                        })
                                        }
                                        {/* <div class="mb-0 plan-features">Free access to the basic features</div>
                                <div class="mb-0 plan-features">Free access to the basic features</div> */}
                                    </div>
                                    <Button type="submit" size="sm" color="primary" className="subscription_proceed_btn w-100"
                                        onClick={() => this.onProceedBtnClick(item.attributes)}
                                    >
                                        Proceed
                                    </Button>

                                </div>


                            )
                        })}
                    </div>
                </div >
                {
                    this.state?.loader &&
                    <div className="backdrop">
                        <div className="loader">
                            <Loader type="ball-pulse-rise" />
                        </div>
                    </div>
                }
                < SweetAlert title="Success!" show={this.state?.subscribedPopUp}
                    confirmBtnText='Go to dashboard'
                    onConfirm={this.onSubscribedyesClick} >
                    You have successfully subscribed to Bronze plan.You can now view drugs and discussion.Thank you!
                </SweetAlert >
                <Modal isOpen={this.state.confirmationChangePopup} toggle={this.onQueryClicked} className="centered-modal">
                    <ModalHeader className='QueryModalHeader' toggle={this.onQueryClicked}><span className="QueryModalTitle">Confirmation</span></ModalHeader>
                    <ModalBody>
                        <div>
                            Your subscription will end on {this.fetchEndDate(this.props.activePlanDetails)}. You are about to change  your subscription.Please note that this change will be reflected immediately. Do you wish to continue?
                        </div>
                    </ModalBody>
                    <ModalFooter className='QueryModalFooter'>
                        <Button style={{ width: '30%' }} color="secondary" onClick={() => { this.setState({ confirmationChangePopup: false }) }}  >
                            Cancel
                        </Button>
                        <Button style={{ width: '30%' }} color="primary" onClick={this.onProceedClicked} >
                            Proceed
                        </Button>
                    </ModalFooter>
                </Modal>
            </>

        );
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionChange);