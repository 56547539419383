import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { Col } from "reactstrap";
import leftPanel from '../../assets/images/png/LeftPanel.png'
import sostoslogo from '../../assets/images/svg/Sostoslogo.svg';

export default class LeftPanel extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (

            <Col lg={this?.props?.isSubscriptionPath ? "4" : "7"} className="d-none d-lg-block .bg-secondary login-left-panel" style={{ backgroundImage: `url(${leftPanel})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                {/* <img src={leftPanel} /> */}
                <div className="slider-light  Sostos-text ">
                    {/* <img src={SOSTOSLogo} alt="" style={{ width: '120px' }} /> */}
                    <Link
                        className="btn-link-primary"
                        to={{ pathname: `/` }}
                    >
                        <img src={sostoslogo} style={{ height: "3.375rem", width: "8.813rem" }} />
                    </Link>
                </div>
                <div className="catos-text">
                    <div className="catos-text-1">
                        CATOS
                    </div>
                    <div className="catos-text-2i">
                        <u>Ca</u>ncer <u>T</u>reatment <u>O</u>ptimization <u>S</u>olutions  (CATOS)
                    </div>
                    <div class="copyright1" >
                        ©2024 by Sostos LLC. All rights reserved.
                    </div>
                </div>

            </Col>





        );
    }
}
