import React, { Component } from "react";
import { Button, FormGroup, Input, FormFeedback } from "reactstrap";
import { connect } from 'react-redux';
import Loader from "react-loaders";
import { toast, Bounce, Slide, ToastContainer } from "react-toastify";
import axiosInstance from "../configuration/Request";
import { encryptPassword } from "../../utils/passwordEncryption";

const mapStateToProps = (state) => {

  return {
    reduxData: state.counterSlice
  };
};

class Password extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oldPassword: '',
      newPassword: '',
      isValidPassword: '',
      confirmPassword: '',
      isValidConfirmPassword: '',
      passwordMisMatch: false,
      passwordValid: true,
      errorMessage: '',
      loader: false,

    };
  };

  handleOldPassChange = (e) => {
    this.setState({ oldPassword: e.target.value });
  };
  handleNewPassChange = (e) => {
    this.setState({ newPassword: e.target.value });
  };
  handleConfirmPassChange = (e) => {

    this.setState({ confirmPassword: e.target.value, passwordMisMatch: this.state.password !== e.target.value });
  };


  handleSubmit = async(e) => {

    try {
      e.preventDefault();
      this.setState({ loader: true });
      const { oldPassword, newPassword, confirmPassword } = this.state;
      const encryptedOldPassword = await encryptPassword(oldPassword);
      const encryptedNewPassword = await encryptPassword(newPassword);

      axiosInstance
        .post(`${process.env.REACT_APP_BASE_URL}api/auth/local`, {
          identifier: this?.props?.reduxData?.loginDetails?.user?.email,
          password: encryptedOldPassword,
        })
        .then((res) => {
          // Handle authentication success

          if (res?.data?.user?.confirmed) {
            if (oldPassword === newPassword) {
              this.setState({ isValidPassword: 'Old password and new password cannot be same.', isValidConfirmPassword: '', errorMessage: "" })
            } else if (newPassword?.length < 8) {
              this.setState({ isValidPassword: 'Password must contain at least eight characters.', isValidConfirmPassword: '', errorMessage: "" })
            } else if (!(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,}$/.test(newPassword))) {
              this.setState({ isValidPassword: 'Password must contain at least one number, both lower and uppercase letters, and one special character.', isValidConfirmPassword: '', errorMessage: "" });
            } else if (confirmPassword?.length < 8) {
              this.setState({ isValidConfirmPassword: 'Password must contain at least eight characters.', isValidPassword: '', errorMessage: "" })
            } else if (!(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,}$/.test(confirmPassword))) {
              this.setState({ isValidConfirmPassword: 'Password must contain at least one number, both lower and uppercase letters, and one special character.', isValidPassword: '', errorMessage: "" });
            } else if (newPassword !== confirmPassword) {
              this.setState({ isValidPassword: '', isValidConfirmPassword: '', errorMessage: "Passwords do not match. Please try again." });
            } else {
              this.setState({ errorMessage: '', loader: true });
              axiosInstance.put(process.env.REACT_APP_BASE_URL + `api/users/${this.props?.reduxData?.loginDetails?.user?.id}`, {
                'password': encryptedNewPassword
              })
                .then(response => {
                  this.setState({ errorMessage: '', oldPassword: '', newPassword: '', confirmPassword: '' });
                  toast(
                    "Your password has been successfully changed. You can now use your new password to log in securely. Thank you!",
                    {
                      transition: Bounce,
                      closeButton: true,
                      autoClose: 5000,
                      position: "top-right",
                      type: "success",
                    }
                  );
                })
                .catch(error => {
                  this.setState({ errorMessage: 'Something went wrong.Please try after sometime.' });
                })
                .finally(() => {
                  this.setState({ loader: false });
                });
            }

          } else {
            this.setState({ errorMessage: "Old password is wrong.Please check and try again." });
          }
        })
        .catch((err) => {
          console.error("Authentication error:", err);
          this.setState({ errorMessage: "Old password is wrong.Please check and try again after sometime." });
        })
        .finally(() => {
          this.setState({ loader: false })
        });

    } catch (error) {
      console.error('Failed to encrypt password:', error);
    }

  }



  render() {

    return (
      <>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div className="input-password">
            <Input type="password" name="oldPassword" id="exampleEmail" placeholder="Old Password" maxLength={16}
              value={this.state.oldPassword} onChange={this.handleOldPassChange} />
            <br></br>
            <FormGroup >
              <Input type="password" name="newPassword" id="examplePassword" placeholder="New Password" maxLength={16}
                value={this.state.newPassword} onChange={this.handleNewPassChange} invalid={this.state.isValidPassword} />
              <FormFeedback>{this?.state?.isValidPassword}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Input type="password" name="ConfirmPassword" id="exampleConfirmPassword" placeholder="Confirm New Password" maxLength={16}
                value={this.state.confirmPassword} onChange={this.handleConfirmPassChange} invalid={this.state?.isValidConfirmPassword} />
              <FormFeedback>{this?.state?.isValidConfirmPassword}</FormFeedback>
            </FormGroup>
            {this.state.errorMessage &&
              <div className="error-msg-display">
                {this.state.errorMessage}
              </div>
            }
            <br></br>
            <div className="forgot-pass-text">
              Should contain at least eight characters, at least one number, both lower and uppercase letters, and one special character.
            </div>
          </div>
          <div className="save-button-div-password">


            <Button type="submit" size="lg" color="primary" className="save-button-password"
              onClick={this.handleSubmit}
              disabled={!(this.state.oldPassword && this.state.newPassword && this.state.confirmPassword)}>
              Save
            </Button>

          </div>
        </div>
        <ToastContainer transition={Slide} />
        {this.state.loader &&
          <div className="backdrop">
            <div className="loader">
              <Loader type="ball-pulse-rise" />
            </div>
          </div>
        }

      </>
    );
  }
}

export default connect(mapStateToProps)(Password);
