import "./polyfills";
import React from 'react';
import { createRoot } from 'react-dom/client';
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/react";
import { HashRouter } from "react-router-dom";
import "./assets/base.scss";
import Main from "./Pages/Main";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store'; 
import { ErrorBoundary } from "@sentry/react";

const MyFallbackComponent = () => {
  return (
    <div>
      <h1>Something went wrong!</h1>
      <p>We're working on fixing the issue.</p>
    </div>
  );
}; 

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

const renderApp = (Component) => {
  root.render(
    <ErrorBoundary FallbackComponent={MyFallbackComponent}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <HashRouter>
            <Component />
          </HashRouter>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  );
};

Sentry.init({
  dsn: process.env.REACT_APP_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring (optional)
  tracesSampleRate: 1.0, // Capture 100% of transactions (adjust as needed)
  // tracePropagationTargets: ["localhost",/^http:\/\/uat\.sostos-ai\.com\/api/], // Control distributed tracing
  tracePropagationTargets: [/^http:\/\/uat\.sostos-ai\.com\/api/, /^https:\/\/newuat\.sostos-ai\.com\/api/ ,  /^https:\/\/newuat\.sostos-ai\.com\/$/, /^https:\/\/newuat\.sostos-ai\.com/, "newuat.sostos-ai.com", /^\//], // Control distributed tracing
  // Session Replay (optional)
  replaysSessionSampleRate: 0.1, // Sample rate for all sessions (adjust as needed)
  replaysOnErrorSampleRate: 1.0, // Sample rate for sessions with errors
});

renderApp(Main);

if (module.hot) {
  module.hot.accept("./Pages/Main", () => {
    const NextApp = require("./Pages/Main").default;
    renderApp(NextApp);
  });
}

serviceWorker.unregister();
