import React, { Component } from "react";
import {
    FormGroup,
    Input,
    Button,
    Label
} from "reactstrap";
import Loader from "react-loaders";
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import { BiFile } from "react-icons/bi";
import axiosInstance from "../configuration/Request";
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import plugins from 'suneditor/src/plugins';

const mapStateToProps = (state) => {

    return {
        reduxData: state.counterSlice
    };
};


class NewDiscussion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            fetchedData: '',
            title: '',
            editorContent: '',
            discussionSavedPopup: false,
            communityGuideline: '',
            discussionSavedPopupContent: '',
            editorVisible: false,
        };
        this.editor = null;
    }

    componentDidMount() {
        this.setState({ loader: true });
        axiosInstance.get(process.env.REACT_APP_BASE_URL + `api/community-guideline`)
            .then(res => {
                this.setState({ communityGuideline: res.data?.data?.attributes?.guidelines })
            }).catch(err => {
                console.log(err)
            }).finally(() => {
                this.setState({ loader: false });
            });
        setTimeout(() => {
            this.setState({ editorVisible: true });
        }, 500);
    };

    onPublishClicked = () => {
        this.editor.core.focus();
        document.activeElement.blur();
        this.setState({ loader: true });
        var date = new Date();

        axiosInstance.get(process.env.REACT_APP_BASE_URL + `api/forum-setting`)
            .then((res) => {

                axiosInstance
                    .post(process.env.REACT_APP_BASE_URL + "api/forums", {
                        "data": {
                            "title": this.state?.title ?? '',
                            "body": this.state?.editorContent ?? '',
                            "date": date?.toISOString() ?? '',
                            "posted_by": this.props?.reduxData?.loginDetails?.user?.id ?? '',
                            ...(res?.data?.data?.attributes?.is_admin_approval_required ? { publishedAt: null } : {})
                        }
                    })
                    .then((response) => {
                        const content = res?.data?.data?.attributes?.is_admin_approval_required ? 'Your thread has been successfully created. Kindly wait until it gets approved. Thank you.' : 'Your thread has been successfully created. Thank you';
                        this.editor.core.setContents('');
                        this.setState({ discussionSavedPopup: true, discussionSavedPopupContent: content, title: '', editorContent: '' })
                    })
                    .catch((err) => {
                        console.error(" error:", err);
                    })
                    .finally(() => {
                        this.setState({ loader: false });
                    });

            })
            .catch((err) => {
                console.error(" error:", err);
            })
            .finally(() => {
            });


    };

    getSunEditorInstance = (sunEditor) => {
        this.editor = sunEditor;
    };

    handleEditorChange = (event) => {
        console.log(event, 'event')
        this.setState({
            editorContent: event,
        });
    };

    handleTitleChange = (event) => {
        this.setState({ title: event.target.value })
    }

    handleInputBlur = () => {
        this.setState((prevState) => ({
            title: prevState.title?.trim()
        }));
    };

    handleEditorBlur = () => {
        this.setState((prevState) => ({
            editorContent: prevState.editorContent?.trim()
        }));
    };



    render() {

        const { loader, title, communityGuideline, discussionSavedPopupContent } = this.state;

        return (
            <>
                <div class="containerForum">
                    <div class="left_columnForum">
                        <div className="left_columnForumHeaderText">
                            Start a new discussion
                        </div>
                        <div className="mt-4">
                            <FormGroup >
                                <Label for="exampleTitle">
                                    Title
                                </Label>
                                <Input
                                    id="exampleTitle"
                                    name="text"
                                    placeholder="Enter title"
                                    type="text"
                                    value={title}
                                    onChange={this.handleTitleChange}
                                    onBlur={this.handleInputBlur}
                                    minLength={3}
                                    maxLength={100}
                                />
                            </FormGroup>
                            <FormGroup style={{ display: this.state?.editorVisible ? 'block' : 'none' }}>
                                <Label >
                                    Body
                                </Label>
                                <SunEditor
                                    getSunEditorInstance={this.getSunEditorInstance}
                                    placeholder="Please type here..."
                                    width="100%"
                                    height="230px"
                                    setOptions={{
                                        height: '230px',
                                        buttonList: [
                                            ['undo', 'redo'],
                                            ['font', 'fontSize', 'formatBlock'],
                                            ['bold', 'underline', 'italic', 'strike'],
                                            ['removeFormat'],
                                            ['fontColor', 'hiliteColor'],
                                            ['align', 'list', 'table'],
                                            ['link', 'image'],
                                            ['fullScreen']
                                        ],
                                        plugins: [plugins.table, plugins.image, plugins.link, plugins.fontColor, plugins.fontSize, plugins.font, plugins.formatBlock, plugins.hiliteColor, plugins.align, plugins.list],
                                        mode: 'classic',
                                    }}
                                    onChange={this.handleEditorChange}
                                    onBlur={this.handleEditorBlur}
                                    autoFocus={false}
                                />
                            </FormGroup>
                        </div>
                        <div style={{ textAlign: "right" }}>
                            <Button className="mb-2 me-2" color="primary" style={{ borderRadius: '4px', height: '48px', fontSize: '1rem', width: '25%' }} onClick={this.onPublishClicked} disabled={!(this.state?.title?.length > 2 && this.state?.editorContent?.length > 2)}>
                                Publish
                            </Button>
                        </div>
                    </div>

                    <div class="right_columnForum">
                        <div className="right_columnForumbody">
                            {communityGuideline}
                        </div>
                        <hr></hr>
                        <div className="right_columnForumHeader" onClick={() => { this.props?.history?.push('/app/guidelines') }}>
                            <BiFile size={'20px'} /> Community guidelines
                        </div>

                    </div>
                </div>
                {loader &&
                    <div className="backdrop">
                        <div className="loader">
                            <Loader type="ball-pulse-rise" />
                        </div>
                    </div>
                }
                <SweetAlert title="Success!" show={this.state?.discussionSavedPopup}
                    confirmBtnText='Go to dashboard'
                    onConfirm={() => { this.setState({ discussionSavedPopup: false }); this.props.history.push('/app/forum'); }}>
                    {discussionSavedPopupContent}
                </SweetAlert>
            </>
        );
    }
}

export default connect(mapStateToProps)(NewDiscussion);
