import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { Button, Input } from "reactstrap";
import backlogo from '../../../assets/images/svg/Back.svg'
import Loader from "react-loaders";
import { connect } from 'react-redux';
import { removeEmojis } from "../../ReusableComponent/RemoveEmoji";
import { setSubscriptionDetail, setloginDetails, setloginSubscriptionDetail } from '../../../reducers/counterSlice';
import axiosInstance from "../../configuration/Request";
import { encryptPassword } from "../../../utils/passwordEncryption";
const mapStateToProps = (state) => {

    return {
        reduxData: state.counterSlice
    };
};

const mapDispatchToProps = {
    setSubscriptionDetail, setloginDetails, setloginSubscriptionDetail
};

class Work extends Component {
    constructor(props) {
        super(props);
        this.state = {
            workOptions: [],
            selectedWorkoption: '',
            otherWorkEntered: '',
            loader: false,
            errorMessage: '',
        };

    };



    componentDidMount() {

        this.setState({ loader: true })
        axiosInstance.get(`${process.env.REACT_APP_BASE_URL}api/works`)
            .then(res => {
                let option = res.data.data.reduce((list, item) => {

                    list.push({ value: item.id, label: item?.attributes?.title, selected: false })
                    return list;
                }, []);

                option.push({ value: 0, label: 'Other', selected: false })

                this.setState({ workOptions: option })

            }).catch(err => {
                console.log(err)
            }).finally(() => {
                this.setState({ loader: false })
            });
    }

    userApiCall = (newData) => {
        this.setState({ loader: true })
        axiosInstance.post(process.env.REACT_APP_BASE_URL + `api/users`, newData)
            .then(response => {

                //Subscription_Commented_Code
                // this.props.history.push('/auth/subscription'); //Uncomment this Subscription_Commented_Code
                this.onSubscribedyesClick();  //Remove this Subscription_Commented_Code
            })
            .catch(error => {
                this.setState({ errorMessage: 'Something went wrong.Please try after sometime.' });
            })
            .finally(() => {
                this.setState({ loader: false })
            });
    }

    onSubscribedyesClick = async() => {

        try {
            this.setState({ loader: true });

            const encryptedPassword = await encryptPassword(this.props?.reduxData?.basicDetails?.password)
            var date = new Date();

            axiosInstance.post(`${process.env.REACT_APP_BASE_URL}api/auth/local`, {
                identifier: this.props?.reduxData?.registrationEmail,
                password: encryptedPassword,
            })
                .then((res) => {

                    if (res?.data?.user?.confirmed) {
                        this.props.setloginDetails(res?.data);
                        // after payment confiramtion call this API to update role
                        const expiredate = new Date();

                        expiredate.setDate(date.getDate() + 30);

                        axiosInstance
                            .post(`${process.env.REACT_APP_BASE_URL}api/user-subscription-details`, {
                                "data": {
                                    "userid": res?.data?.user?.id,
                                    "plan": 'Bronze',
                                    "frequency": "monthly",
                                    "amount": 'Free',
                                    "subscription_date": date.toISOString() ?? '',
                                    "active": true,
                                    "expiry": expiredate.toISOString() ?? '',
                                    "currency": '',
                                    "subscription_id": null,
                                    "session_id": '',
                                    "is_cancelled": false,
                                    "transaction_id": `uf_${Math.floor(1000 + Math.random() * 9000)}`,
                                }
                            })
                            .then((response) => {

                                axiosInstance
                                    .get(process.env.REACT_APP_BASE_URL + `api/user-subscription-details?filters[userid][%24eq]=${res?.data?.user?.id}&active=true`)
                                    .then((res) => {
                                        // 
                                        // let highestIdObject = { id: 0 };

                                        // res?.data?.data.forEach(item => {
                                        //     if (item.id > highestIdObject?.id) {
                                        //         highestIdObject = { ...item };
                                        //     }
                                        // });
                                        // 
                                        this.props?.setloginSubscriptionDetail(res?.data?.data)
                                        this.props.history.push('/app/cancerType');
                                    })
                                    .catch((err) => {
                                        console.error(" error:", err);
                                    })
                                    .finally(() => {
                                    });
                                // this.props?.setloginSubscriptionDetail(response?.data?.data)
                            })
                            .catch((err) => {
                                console.error("Authentication error:", err);
                            }).finally(() => {
                                this.setState({ loader: false })
                            });

                    }
                })
                .catch((err) => {
                    // Handle authentication error
                    console.error("Authentication error:", err);
                    this.setState({ loader: false })

                })
                .finally(() => {
                });

        } catch (error) {
            console.error('Failed to encrypt password:', error);
        }
    }

    onSkipClick = async () => {

        try {
            this.setState({ loader: true });

            const encryptedPassword = await encryptPassword(this.props?.reduxData?.basicDetails?.password)


            const newData = {
                "username": this.props?.reduxData?.basicDetails?.name ?? '',
                "email": this.props?.reduxData?.registrationEmail ?? '',
                "password": encryptedPassword ?? '',
                "role": 3,
                "profession": this.props?.reduxData?.primaryRole?.label ?? '',
                "work": '',
                "confirmed": true,
            };
            this.userApiCall(newData);

        } catch (error) {
            console.error('Failed to encrypt password:', error);
        }

    }

    onDoneClick = async () => {

        try {
            this.setState({ loader: true });

            const encryptedPassword = await encryptPassword(this.props?.reduxData?.basicDetails?.password)
            let selectedOption = this.state?.workOptions?.filter(obj => obj?.selected).map(obj => obj?.label !== 'Other' ? obj?.label : this.state?.otherWorkEntered);


            const newData = {
                "username": this.props?.reduxData?.basicDetails?.name ?? '',
                "email": this.props?.reduxData?.registrationEmail ?? '',
                "password": encryptedPassword ?? '',
                "role": 3,
                "profession": this.props?.reduxData?.primaryRole?.label ?? '',
                "work": selectedOption?.toString() ?? '',
                "confirmed": true,
            };

            this.userApiCall(newData);
        } catch (error) {
            console.error('Failed to encrypt password:', error);
        }
    }

    onworkSelected = (event) => {

        let workOptionsArray = this.state.workOptions.map(obj => {
            if (obj.value === event.value) {
                // If the object's id matches the idToUpdate, update its age
                return { ...obj, selected: !obj?.selected };
            }
            return obj;
        });
        this.setState({ workOptions: workOptionsArray })
    }


    handleOtherChange = (e) => {
        const { value } = e.target;
        const filteredValue = removeEmojis(value);
        this.setState({ otherWorkEntered: filteredValue });
    };


    checksubmitBtn = () => {

        let isButton = !this.state.workOptions.some(obj => obj.selected && obj.label !== 'Other')

        let other = this.state.workOptions.find(obj => obj.label === 'Other')
        if (other && other?.selected && this.state.otherWorkEntered !== '') {
            isButton = false
        }


        return isButton
    }




    render() {


        return (
            <>

                <Link className='btn-link-primary'
                    to={{ pathname: '/auth/primaryRole' }}>
                    <div className="d-inline-flex gap-2" >
                        <img src={backlogo} alt="" />
                        <span className="back-text">Back</span>
                    </div>
                </Link>
                <h4 className="mb-0">
                    <div className="PrimaryRole-text">What kind of work do you do?</div>
                </h4>
                <div className="mt-3 login-subtext">
                    This will help us tailor SOSTOS for you.
                </div>
                <div className="regTableData">
                    {this.state?.workOptions?.length > 0 ?
                        this.state?.workOptions?.map(list => {
                            return (
                                <button key={list?.id} className={list?.selected ? 'regTableListSelected' : "regTableList"} onClick={() => this.onworkSelected(list)}>{list?.label}</button>
                            )
                        })
                        : !this.state.loader && 'N/A'}
                </div>
                {(this.state.workOptions[this.state.workOptions?.length - 1]?.label === 'Other' && this.state.workOptions[this.state.workOptions?.length - 1]?.selected)
                    &&
                    <Input type="text" name="other" id="exampleOther" placeholder="Enter Manually"
                        value={this.state.otherWorkEntered} onChange={this.handleOtherChange}
                        className="mb-3" />}
                <div>



                    <div className="d-flex gap-2 ">
                        {this.state.errorMessage &&
                            <div className="error-msg-display">
                                {this.state.errorMessage}
                            </div>
                        }
                        <Button className="w-100" color="light" onClick={this.onSkipClick} >
                            Skip
                        </Button>
                        <Button className="w-100" color="primary" onClick={this.onDoneClick} disabled={this.checksubmitBtn()}>
                            Done
                        </Button>
                    </div>
                </div>
                {this.state.loader &&
                    <div className="backdrop">
                        <div className="loader">
                            <Loader type="ball-pulse-rise" />
                        </div>
                    </div>
                }
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Work);
