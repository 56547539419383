import React, { Fragment, Component } from "react";
import { Link } from 'react-router-dom';
import { Col, Row, Button, Form, FormGroup, Label, Input, FormFeedback } from "reactstrap";
import backlogo from '../../../assets/images/svg/Back.svg'
import { connect } from 'react-redux';
import { setBasicDetails } from "../../../reducers/counterSlice";
import Loader from "react-loaders";
import { removeEmojis } from "../../ReusableComponent/RemoveEmoji";

const mapStateToProps = (state) => {
  
  return {
    value: state.counterSlice.value
  };
};
const mapDispatchToProps = {
  setBasicDetails
};

class BasicDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      password: '',
      isValidPassword: '',
      confirmPassword: '',
      isValidConfirmPassword: '',
      errorMessage: '',
      loader: false,
    };

  };

  componentDidMount = () => {
    
  }

  handleNameChange = (e) => {
    const { value } = e.target;
    const filteredValue = removeEmojis(value);
    this.setState({ name: filteredValue });
  };

  handleInputBlur = () => {
    this.setState((prevState) => ({
      name: prevState.name.trim()
    }));
  };

  handlePassChange = (e) => {
    this.setState({ password: e.target.value });
  };
  handleConfirmPassChange = (e) => {
    
    this.setState({ confirmPassword: e.target.value });
  };


  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ loader: true });
    const { password, confirmPassword } = this.state;
    const isValid = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,}$/.test(password);

    if (password?.length < 8) {
      this.setState({ isValidPassword: 'Password must contain at least eight characters.' , isValidConfirmPassword: '', errorMessage: "" })
    } else if (!(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,}$/.test(password))) {
      this.setState({ isValidPassword: 'Password must contain at least one number, both lower and uppercase letters, and one special character.', isValidConfirmPassword: '', errorMessage: ""});
    } else if (confirmPassword?.length < 8) {
      this.setState({ isValidConfirmPassword: 'Password must contain at least eight characters.' ,isValidPassword: '', errorMessage: "" })
    } else if (!(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,}$/.test(confirmPassword))) {
      this.setState({ isValidConfirmPassword: 'Password must contain at least one number, both lower and uppercase letters, and one special character.' , isValidPassword: '', errorMessage: "" });
    } else if (password !== confirmPassword) {
      this.setState({ isValidPassword: '', isValidConfirmPassword: '', errorMessage: "Passwords do not match. Please try again." });
    } else {
      this.setState({ isValidPassword: '', isValidConfirmPassword: '', errorMessage: "" });
      this.props.setBasicDetails({ name: this.state?.name, password: this.state?.password })
      this.props.history.push('/auth/primaryRole');
    }
    this.setState({ loader: false });
  }



  render() {
    return (
      <>
        <Link className='btn-link-primary'
          to={{ pathname: '/auth/register' }}>
          <div className="d-inline-flex gap-2" >
            <img src={backlogo} alt="" />
            <span className="back-text">Back</span>
          </div>
        </Link>
        <h4 className="mb-0">
          <div className="login-text">Basic Details</div>
        </h4>
        <div className="mt-3 login-subtext">
          Enter your name, set a password for your account.
        </div>
        <div>
          <div>
            <FormGroup floating>
              <Input type="text" name="name" id="exampleFullName" placeholder="Name" maxLength={50}
                value={this.state.name} onChange={this.handleNameChange} onBlur={this.handleInputBlur} />
              <Label for="exampleFullName">
                Full Name
              </Label>
            </FormGroup>
            <FormGroup floating>
              <Input type="password" name="password" id="examplePassword" placeholder="Password" maxLength={16} 
                value={this.state.password} onChange={this.handlePassChange} invalid={this.state.isValidPassword} />
              <Label for="examplePassword">
                Password
              </Label>
              <FormFeedback>{this?.state?.isValidPassword}</FormFeedback>
            </FormGroup>
            <FormGroup floating>
              <Input type="password" name="ConfirmPassword" id="exampleConfirmPassword" placeholder="Confirm Password" maxLength={16}
                value={this.state.confirmPassword} onChange={this.handleConfirmPassChange} invalid={this.state?.isValidConfirmPassword} />
              <Label for="exampleConfirmPassword">
                Confirm Password
              </Label>
              <FormFeedback>{this?.state?.isValidConfirmPassword}</FormFeedback>
            </FormGroup>
            <div className="forgot-pass-text">
              Should contain at least eight characters, at least one number, both lower and uppercase letters, and one special character.
            </div>
          </div>

          <div className="login-button w-100 mt-3">
            {this.state.errorMessage &&
              <div className="error-msg-display">
                {this.state.errorMessage}
              </div>
            }
            <Button type="submit" size="lg" color="primary" className=" w-100"
              onClick={this.handleSubmit}
              disabled={!(this.state.name && this.state.password && this.state.confirmPassword)}>
              Next
            </Button>

          </div>

        </div>
        {this.state.loader &&
          <div className="backdrop">
            <div className="loader">
              <Loader type="ball-pulse-rise" />
            </div>
          </div>
        }

      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BasicDetails);
