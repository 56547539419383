import React, { Fragment } from "react";
import { Route, useLocation } from "react-router-dom";
import CRMDashboard from "../../Pages/SOSTOSDashboard";
import cancerTypeSelectionPage from '../../Pages/SOSTOSDashboard/cancerType';
import DrugPage from '../../Pages/SOSTOSDashboard/drugpage';
import Header from '../../Pages/Header/Header';
import ProfileEdit from '../../Pages/ProfileEdit/index';
import ForumDashboard from "../../Pages/Forum/ForumDashboard";
import NewDiscussion from "../../Pages/Forum/NewDiscussion";
import ForumDescription from "../../Pages/Forum/ForumDescription";
import Guidelines from "../../Pages/Forum/Guidelines";

const PostAuthLayout = ({ match , history}) => {
    const location = useLocation();

    return (
        <Fragment>
            <div className="">
                <div>
                    <Header location={location} history={history}/>
                </div>
                <div className="app-main">
                    <div className="app-main__outer">
                        <div className="app-main__inner">
                            <Route path={`${match.url}/cancerType`} component={CRMDashboard} />
                            <Route path={`${match.url}/cancerTypeSelection`} component={cancerTypeSelectionPage} />
                            <Route path={`${match.url}/drugpage`} component={DrugPage} />
                            <Route path={`${match.url}/profileEdit`} component={ProfileEdit} />
                            <Route path={`${match.url}/forum`} component={ForumDashboard} />
                            <Route path={`${match.url}/forumNew`} component={NewDiscussion} />
                            <Route path={`${match.url}/forumDescription/:id`} component={ForumDescription} />
                            <Route path={`${match.url}/guidelines`} component={Guidelines} />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
};

export default PostAuthLayout;
