import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { Col, Row, Button, Form, FormGroup, Label, Input } from "reactstrap";
import backlogo from '../../../assets/images/svg/Back.svg';
import { setPrimaryRole } from "../../../reducers/counterSlice";
import { connect } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { toast, Bounce, Slide, ToastContainer } from "react-toastify";
import Loader from "react-loaders";
import axiosInstance from "../../configuration/Request";

const mapStateToProps = (state) => {
    
    return {
        reduxData: state.counterSlice
    };
};

const mapDispatchToProps = {
    setPrimaryRole
};


class ForgetPasswordConfirmation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            errorMessage: ''
        };
    }


    onProceedClick = () => {
        this.setState({ loader: true });

        axiosInstance.post(process.env.REACT_APP_BASE_URL + "api/resetPassword", {
                "data": {
                    "email": this.props?.reduxData?.forgetPasswordEmail ?? ''
                }
            })
            .then((res) => {
                toast(
                    "Reset password email sent successfully!",
                    {
                        transition: Bounce,
                        closeButton: true,
                        autoClose: 5000,
                        position: "top-right",
                        type: "success",
                    }
                );
            })
            .catch((err) => {
                console.error(" error:", err);
                this.setState({ errorMessage: 'Something went wrong.Please try after sometime.' });
            })
            .finally(() => {
                this.setState({ loader: false });
            });
    }




    render() {
        return (
            <>

                <div >
                    <Link className='btn-link-primary'
                        to={{ pathname: '/auth/forgotpassword' }}>
                        <div className="d-inline-flex gap-2" >
                            <img src={backlogo} alt="" />
                            <span className="back-text">Back</span>
                        </div>
                    </Link>

                    <div className="login-text">Reset your password</div>

                    <div className="login-subtext">
                        If an account exists for &nbsp;
                        <span style={{ fontWeight: "bold" }}>{this.props?.reduxData?.forgetPasswordEmail ?? ''}</span>
                        , we’ll send an email with a link to reset your password.
                    </div>
                    <div className="login-subtext">
                        Reset your password using the link sent to your email.
                    </div>
                    <div>
                        {this.state.errorMessage &&
                            <div className="error-msg-display">
                                {this.state.errorMessage}
                            </div>
                        }
                        <Button size="lg" color="primary" className="mt-3 w-100" onClick={this.onProceedClick}>
                            Resend Email
                        </Button>

                    </div>
                    <div className="remember-pass">
                        <div className="remember-pass-text">
                            Remember Your Password?
                        </div>
                        <div >
                            <Link
                                to={{ pathname: '/auth/login' }}>
                                <button className="btn btn-outline-primary "  >Sign In</button>
                            </Link>

                        </div>
                    </div>
                </div>
                <ToastContainer transition={Slide} />
                {this.state.loader &&
                    <div className="backdrop">
                        <div className="loader">
                            <Loader type="ball-pulse-rise" />
                        </div>
                    </div>
                }


            </>
        );
    } ks
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPasswordConfirmation);
