import React, {  Component } from "react";
import { Link } from 'react-router-dom';
import {  Button,FormGroup, Label, Input , FormFeedback } from "reactstrap";
import backlogo from '../../../assets/images/svg/Back.svg'
import { setRegistrationEmail } from "../../../reducers/counterSlice";
import { connect } from 'react-redux';
import Loader from "react-loaders";
import axiosInstance from "../../configuration/Request";

const mapStateToProps = (state) => {
  
  return {
    value: state.counterSlice.value
  };
};

const mapDispatchToProps = {
  setRegistrationEmail
};
class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      createAccountEmail: '',
      isValidEmail: true,
      errorMessage: '',
      loader: false,
    };
  }

  authenticationCreate = () => {

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex?.test(this.state?.createAccountEmail)) {

      this.setState({ isValidEmail: true });
      this.setState({ loader: true });
      axiosInstance.get(process.env.REACT_APP_BASE_URL + `api/users?filters[email][$eq]l=${this.state?.createAccountEmail}`)
        .then((res) => {
          if (res?.data?.length > 0) {
            this.setState({ errorMessage: 'This email address is already registered. Please try signing in or use a different email address to register.' })
          } else {
            this.setState({ errorMessage: '' });
            this.otpAPICallFn();
          }
        })
        .catch((err) => {
          console.error(" error:", err);
          this.setState({ errorMessage: 'Something went wrong.Please try after sometime.' });
        })
        .finally(() => {
          this.setState({ loader: false });
        });
    } else {
      this.setState({ isValidEmail: false });
    }



  };


  otpAPICallFn = () => {

    this.setState({ loader: true });
    axiosInstance
      .post(process.env.REACT_APP_BASE_URL + "api/otps", {
        "data": {
          "email": this.state.createAccountEmail ?? ''
        }
      })
      .then((res) => {
        // if (res?.data?.otp) {
        this.setState({ otpSent: true })
        this.props.setRegistrationEmail(this.state.createAccountEmail ?? '');
        this.props.history.push('/auth/otpVerify');
        // }
      })
      .catch((err) => {
        console.error(" error:", err);
        this.setState({ errorMessage: 'Something went wrong.Please try after sometime.' });
      })
      .finally(() => {
        this.setState({ loader: false })
      });
  }


  handleEmailChange = (e) => {
    this.setState({ createAccountEmail: e.target.value });
  };



  render() {


    const { otpSent, errorMessage } = this.state;
    return (
      <>
        <div className=''>
          <Link className='btn-link-primary'
            to={{ pathname: '/auth/login' }}>
            <div className="d-inline-flex gap-2" >
              <img src={backlogo} alt="" />
              <span className="back-text">Back</span>
            </div>
          </Link>
          <h4 className="mb-0">
            <div className="login-text">{'Create an Account'}</div>
          </h4>

          <h6 className="my-2 login-subtext">
            Or{" "}

            <Link className='text-primary-link'
              to={{ pathname: '/auth/login' }}>
              Login
            </Link>
          </h6>

          <div style={{ maxWidth: '24rem' }}>
            <FormGroup floating>
              <Input type="email" name="email" id="exampleEmail" placeholder="Email" disabled={otpSent}
                value={this.state.createAccountEmail} onChange={this.handleEmailChange} invalid={!this.state.isValidEmail}
              />
              <Label for="exampleEmail">
                Email
              </Label>
              <FormFeedback>Please enter a valid email address.</FormFeedback>
            </FormGroup>
            <div className="login-button mt-3 ">
              {errorMessage &&
                <div className="error-msg-display">
                  {errorMessage}
                </div>
              }
              <Button size="lg" color="primary" className="w-100" onClick={this.authenticationCreate} disabled={!this.state.createAccountEmail}>
                Create Account
              </Button>

            </div>

          </div>
        </div>
        {this.state.loader &&
          <div className="backdrop">
            <div className="loader">
              <Loader type="ball-pulse-rise" />
            </div>
          </div>
        }
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Register);

