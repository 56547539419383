import React, { Component } from "react";
import {FormGroup,Button} from "reactstrap";
import Select from "react-select";
import { setCancerType } from "../../reducers/counterSlice";
import { connect } from 'react-redux';
import axiosInstance from "../configuration/Request";
import Loader from "react-loaders";

const mapStateToProps = (state) => {
  
  return {
    value: state.counterSlice.value
  };
};

const mapDispatchToProps = {
  setCancerType
};


class SOSTOSDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null,
      cancerTypeOptions: [],
      loader: false,
    };

  }


  componentDidMount() {

    this.setState({ loader: true })
    axiosInstance
      .get(`${process.env.REACT_APP_BASE_URL}api/drug-informations?fields=main_repositioning`)
      .then(res => {
        let option = res.data.data.reduce((list, item) => {
          
          list.push({ value: item.id, label: item?.attributes?.main_repositioning })
          return list;
        }, []);

        // to remove duplicate value from obj array
        option = option.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.label === value.label && value?.label !== 'NA'
          ))
        )

        this.setState({ cancerTypeOptions: option })
      }
      ).catch(err => {
        console.log(err)
      }).finally(() => {
        this.setState({ loader: false })
      });
  }

  handleChange = (event) => {
    this.setState({ selectedOption: event });
  };

  onProceedClick = () => {
    this.props.setCancerType(this.state?.selectedOption)
    this.props.history.push('/app/cancerTypeSelection');
  }


  render() {

    const { selectedOption } = this.state;

    return (
      <>
        <div className="CancerTypeContainer">
          <div >
            <div className="cancerType-header">
              Select Cancer Type
            </div>
            <div className="cancerType-subheader">
              Select the cancer type you would like to explore
            </div>
            <div style={{ marginTop: '24px' }}>
              <FormGroup>
                {/* <Label for="exampleEmail">Cancer Type</Label> */}
                <Select
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      height: '48px',
                      cursor: "pointer"
                    }),
                    option: (base) => ({
                      ...base,
                      cursor: "pointer",
                    }),
                  }}
                  value={selectedOption} onChange={this.handleChange} options={this.state.cancerTypeOptions} />
              </FormGroup>
            </div>
            <div className="text-center mt-4">
              <Button className="mb-2 me-2 w-100" color="primary" disabled={!selectedOption} style={{ borderRadius: '4px', height: '48px', fontSize: '1rem' }} onClick={this.onProceedClick}>    
                Proceed
              </Button>
            </div>
          </div>
        </div>
        {
          this.state.loader &&
          <div className="backdrop">
            <div className="loader">
              <Loader type="ball-pulse-rise" />
            </div>
          </div>
        }
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SOSTOSDashboard);
