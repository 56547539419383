import CryptoJS from 'crypto-js';

// Function to encrypt a given text using AES
export const encryptPassword = async (password) => {
  try {
    // Ensure the encryption key is properly parsed from environment variables
    const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_KEY); // Replace with your encryption key from environment variables
    const iv = CryptoJS.enc.Hex.parse('00000000000000000000000000000000'); // Example IV (all zeroes)

    // Encrypt the plaintext password with custom IV, no additional randomness
    const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(password), key, {
      iv: iv,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC
    });

    // Convert encrypted data to a string format (Base64)
    const encryptedPassword = encrypted.ciphertext.toString(CryptoJS.enc.Base64);

    return encryptedPassword;
  } catch (error) {
    console.error('Error encrypting password:', error);
    throw error; // Rethrow error to be handled by the calling function
  }
};
