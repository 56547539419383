import React, { Component } from "react";
import { FormGroup, Label, Input } from "reactstrap";
import {
    TabContent,
    TabPane,
    Button,
} from "reactstrap";
import classnames from "classnames";
import Select from "react-select";
import backlogo from '../../assets/images/svg/Back.svg'
import { Link } from 'react-router-dom';
import Loader from "react-loaders";
import ReactPaginate from 'react-paginate';
import { connect } from 'react-redux';
import axiosInstance from "../configuration/Request"
import SweetAlert from 'react-bootstrap-sweetalert';
import { removeGeneName } from "../ReusableComponent/RemoveEmoji";
import { IoMdInformationCircle } from "react-icons/io";
import { AiOutlineSearch } from "react-icons/ai";


const mapStateToProps = (state) => {
    return {
        reduxData: state.counterSlice
    };
};


class CancerType extends Component {
    constructor(props) {
        super(props);
        this.state = {
            targetselectedOption: null,
            cancerselectedOption: null,
            drugList: {
                sensitive: [],
                resistant: [],
                other: [],
                defaultData: []
            },
            pathwayTypeOptions: [],
            cancerTypeOptions: [],
            loader: false,
            checkboxValues: {
                fda: false,
                clinical: false,
                chemical: false,
                cancer: false,
                noncancer: false,
                phaseI: false,
                phaseII: false,
                phaseIII: false,
                pastCaseReport: false,
            },
            selectedOption: 'OR',
            pageCount: {
                sensitive: 1,
                resistant: 1,
                other: 1,
                defaultData: 1
            },
            selectedPage: {
                sensitive: 1,
                resistant: 1,
                other: 1,
                defaultData: 1
            },
            showAdvancedOptions: false,
            advancedOptions: [
                { name: "mRNA/protein expression", enabled: false, value1: "", value2: "", header1: "Up-regulated", header2: 'Down-regulated', value1ErrorList: '', value2ErrorList: '' },
                { name: "miRNA expression", enabled: false, value1: "", value2: "", header1: "Up-regulated", header2: 'Down-regulated', value1ErrorList: '', value2ErrorList: '' },
                { name: "Copy Number Variation", enabled: false, value1: "", value2: "", header1: "Amplification", header2: 'Deletion', value1ErrorList: '', value2ErrorList: '' },
                { name: "DNA Methylation", enabled: false, value1: "", header1: "", value1ErrorList: '' },
                { name: "Mutation", enabled: false, value1: "", header1: "", value1ErrorList: '' },
                { name: "Fusion", enabled: false, value1: "", header1: "", value1ErrorList: '' }
            ],
            tempAdvancedOptions: [
                { name: "mRNA/protein expression", enabled: false, value1: "", value2: "", header1: "Up-regulated", header2: 'Down-regulated', value1ErrorList: '', value2ErrorList: '' },
                { name: "miRNA expression", enabled: false, value1: "", value2: "", header1: "Up-regulated", header2: 'Down-regulated', value1ErrorList: '', value2ErrorList: '' },
                { name: "Copy Number Variation", enabled: false, value1: "", value2: "", header1: "Amplification", header2: 'Deletion', value1ErrorList: '', value2ErrorList: '' },
                { name: "DNA Methylation", enabled: false, value1: "", header1: "", value1ErrorList: '' },
                { name: "Mutation", enabled: false, value1: "", header1: "", value1ErrorList: '' },
                { name: "Fusion", enabled: false, value1: "", header1: "", value1ErrorList: '' }
            ], // State to temporarily hold changes

            geneNameValidateLoader: false,
            advancedSearchApplied: false,
            activeTab: "sensitive",

        };
        this.textareaRefs = [];

    }

    componentDidMount() {

        this.setState({ loader: true })
        axiosInstance
            .get(`${process.env.REACT_APP_BASE_URL}api/drug-informations?fields=main_repositioning`)
            .then(res => {
                let option = res.data.data.reduce((list, item) => {

                    list.push({ value: item.id, label: item?.attributes?.main_repositioning })
                    return list;
                }, []);

                // to remove duplicate value from obj array
                option = option.filter((value, index, self) =>
                    index === self.findIndex((t) => (
                        t.label === value.label && value?.label !== 'NA'
                    ))
                )

                this.setState({ cancerTypeOptions: option, cancerselectedOption: this.props?.reduxData?.cancerType ?? {} }, () => {
                    this.drugListApifn(1, 'sensitive');
                })

            }).catch(err => {
                console.log(err)
            }).finally(() => {
            });

        axiosInstance.get(`${process.env.REACT_APP_BASE_URL}api/drug-informations?fields=target_pathway`)
            .then(res => {
                let option = res.data.data.reduce((list, item) => {

                    list.push({ value: item.id, label: item?.attributes?.target_pathway })
                    return list;
                }, []);

                // to remove duplicate value from obj array
                option = option.filter((value, index, self) =>
                    index === self.findIndex((t) => (
                        t.label === value.label
                    ))
                )

                this.setState({ pathwayTypeOptions: option })
            }).catch(err => {
                console.log(err)
            })


    }

    componentDidUpdate() {
        this.textareaRefs.forEach((ref, index) => {
            if (ref && ref.current) {
                ref.current.style.height = 'auto';
                ref.current.style.height = `${ref.current.scrollHeight}px`;
            }
        });
    }


    drugListApifn = (pageNo, typeName) => {

        this.setState({ loader: true });


        let highestIdObject = { id: 0 };

        this.props?.reduxData?.loginSubscriptionDetail?.forEach(item => {
            if (item.id > highestIdObject?.id) {
                highestIdObject = { ...item };
            }
        });

        const level = highestIdObject?.attributes?.plan?.toLowerCase()

        let URL = '';
        if (this.state.selectedOption === 'AND') {
            URL = `${process.env.REACT_APP_BASE_URL}api/drug-informations?${this.state?.cancerselectedOption?.label ? `filters[main_repositioning]=${this.state?.cancerselectedOption?.label}` : ''}${this.state.checkboxValues?.fda ? '&filters[fdas][$gt] = 0' : ''}${this.state.checkboxValues?.chemical ? '&filters[drug_development_status][%24eqi]=chemical compound' : ''}${this.state.checkboxValues?.cancer ? '&filters[%24or][1][indication_category]=cancer drug&filters[%24or][1][indication_category]=cancer' : ''}${this.state.checkboxValues?.noncancer ? '&filters[%24or][1][indication_category]=Non cancer drug&filters[%24or][1][indication_category]=non-cancer' : ''}${this.state.checkboxValues?.pastCaseReport ? '&filters[past_case_reports][%24gt] = 0' : ''}&filters[is_accessible_for_${level}][$eq]=true${this.state?.advancedSearchApplied ? '' : `&pagination[page]=${pageNo ?? 1}`}&sort=drug:ASC`

        } else {
            URL =
                `${process.env.REACT_APP_BASE_URL}api/drug-informations?${this.state?.cancerselectedOption?.label ? `filters[main_repositioning]=${this.state?.cancerselectedOption?.label}` : ''}${this.state.checkboxValues?.fda ? '&filters[$or][1][fdas][$gt] = 0' : ''}${this.state.checkboxValues?.chemical ? '&filters[%24or][2][drug_development_status][%24eqi]=chemical compound' : ''}${this.state.checkboxValues?.cancer ? '&filters[%24or][1][indication_category]=cancer drug&filters[%24or][1][indication_category]=cancer' : ''}${this.state.checkboxValues?.noncancer ? '&filters[%24or][1][indication_category]=Non cancer drug&filters[%24or][1][indication_category]=non-cancer' : ''}${this.state.checkboxValues?.pastCaseReport ? '&filters[%24or][3][past_case_reports][%24gt] = 0' : ''}&filters[is_accessible_for_${level}][$eq]=true${this.state?.advancedSearchApplied ? '' : `&pagination[page]=${pageNo ?? 1}`}&sort=drug:ASC`
        }

        if (this.state?.checkboxValues?.clinical) {
            let data = {
                "url": URL,
                "phase1": this.state?.checkboxValues?.phaseI ?? false,
                "phase2": this.state?.checkboxValues?.phaseII ?? false,
                "phase3": this.state?.checkboxValues?.phaseIII ?? false
            }
            axiosInstance
                .post(process.env.REACT_APP_BASE_URL + `api/getUrl`, data)
                .then(res => {
                    this?.apiCallFn(res?.data?.data, pageNo, typeName)

                }).catch(error => {
                    console.log(error)
                }).finally(() => {

                });;
        } else {
            this?.apiCallFn(URL, pageNo, typeName)
        }

    };

    apiCallFn = (URL, pageNo, typeName) => {
        const { advancedOptions } = this.state
        if (this.state?.advancedSearchApplied) {
            let data =
            {
                "url": URL,
                "mrnaProtein": {
                    "up": [],
                    "down": []
                },

                "mrnaExpression": {
                    "up": [],
                    "down": []
                },


                "cnv": {
                    "amp": [],
                    "del": []
                },

                "methylation": {
                    "genes": []
                },


                "mutation": {
                    "genes": []
                },

                "fusion": {
                    "genes": []
                },
                "condition": this.state.selectedOption,
                "level": typeName
            }

            advancedOptions.forEach(option => {
                switch (option.name) {
                    case "mRNA/protein expression":
                        data.mrnaProtein.up = (option?.value1.length > 0 && option?.enabled && !option?.value1ErrorList) ? option.value1.split(',') : [];
                        data.mrnaProtein.down = (option?.value2.length > 0 && option?.enabled && !option?.value2ErrorList) ? option.value2.split(',') : [];
                        break;
                    case "miRNA expression":
                        data.mrnaExpression.up = (option?.value1.length > 0 && option?.enabled && !option?.value1ErrorList) ? option.value1.split(',') : [];
                        data.mrnaExpression.down = (option?.value2.length > 0 && option?.enabled && !option?.value2ErrorList) ? option.value2.split(',') : [];
                        break;
                    case "Copy Number Variation":
                        data.cnv.amp = (option?.value1.length > 0 && option?.enabled && !option?.value1ErrorList) ? option.value1.split(',') : [];
                        data.cnv.del = (option?.value2.length > 0 && option?.enabled && !option?.value2ErrorList) ? option.value2.split(',') : [];
                        break;
                    case "DNA Methylation":
                        data.methylation.genes = (option?.value1.length > 0 && option?.enabled && !option?.value1ErrorList) ? option.value1.split(',') : [];
                        break;
                    case "Mutation":
                        data.mutation.genes = (option?.value1.length > 0 && option?.enabled && !option?.value1ErrorList) ? option.value1.split(',') : [];
                        break;
                    case "Fusion":
                        data.fusion.genes = (option?.value1.length > 0 && option?.enabled && !option?.value1ErrorList) ? option.value1.split(',') : [];
                        break;
                    default:
                        break;
                }
            });


            axiosInstance
                .post(process.env.REACT_APP_BASE_URL + `api/drugs?page=${pageNo}`, data)
                .then((res) => {

                    this.setState(prevState => ({
                        drugList: {
                            ...prevState.drugList,
                            [typeName]: res?.data
                            // sensitive : res?.data?.data
                        },
                        pageCount: {
                            ...prevState.pageCount,

                            [typeName]: res?.data?.meta?.pagination?.pageCount

                        },
                        selectedPage: {
                            ...prevState.selectedPage,
                            [typeName]: res?.data?.meta?.pagination?.page
                        }

                    }));
                    // this.setState({ drugList: res?.data, pageCount: res?.data?.meta?.pagination?.pageCount, selectedPage: res?.data?.meta?.pagination?.page })
                })
                .catch((err) => {
                    console.log(err)
                    this.setState(prevState => ({
                        drugList: {
                            ...prevState.drugList,
                            [typeName]: []
                            // sensitive : res?.data?.data
                        },
                        pageCount: {
                            ...prevState.pageCount,

                            [typeName]: 1

                        },
                        selectedPage: {
                            ...prevState.selectedPage,
                            [typeName]: 1
                        }

                    }));
                })
                .finally(() => {
                    this.setState({ loader: false })
                });
        }
        else {
            axiosInstance.get(URL)
                .then(res => {

                    this.setState(prevState => ({
                        drugList: {
                            ...prevState.drugList,
                            defaultData: res?.data
                            // sensitive : res?.data?.data
                        },
                        pageCount: {
                            ...prevState.pageCount,
                            // defaultData: 1
                            defaultData: res?.data?.meta?.pagination?.pageCount
                        },
                        selectedPage: {
                            ...prevState.selectedPage,
                            defaultData: res?.data?.meta?.pagination?.page
                        }

                    }));
                    // this.setState({ drugList: res?.data, pageCount: res?.data?.meta?.pagination?.pageCount, selectedPage: res?.data?.meta?.pagination?.page })
                }).catch(error => {
                    console.log(error)
                }).finally(() => {
                    this.setState({ loader: false })

                });;
        }
    }

    onCancel = () => {
        this.setState({
            checkboxValues: {
                fda: false,
                clinical: false,
                chemical: false,
                cancer: false,
                noncancer: false,

            }
            , selectedOption: 'OR',
            targetselectedOption: null,
            advancedOptions: [
                { name: "mRNA/protein expression", enabled: false, value1: "", value2: "", header1: "Up-regulated", header2: 'Down-regulated', value1ErrorList: '', value2ErrorList: '' },
                { name: "miRNA expression", enabled: false, value1: "", value2: "", header1: "Up-regulated", header2: 'Down-regulated', value1ErrorList: '', value2ErrorList: '' },
                { name: "Copy Number Variation", enabled: false, value1: "", value2: "", header1: "Amplification", header2: 'Deletion', value1ErrorList: '', value2ErrorList: '' },
                { name: "DNA Methylation", enabled: false, value1: "", header1: "", value1ErrorList: '' },
                { name: "Mutation", enabled: false, value1: "", header1: "", value1ErrorList: '' },
                { name: "Fusion", enabled: false, value1: "", header1: "", value1ErrorList: '' }
            ],
            tempAdvancedOptions: [
                { name: "mRNA/protein expression", enabled: false, value1: "", value2: "", header1: "Up-regulated", header2: 'Down-regulated', value1ErrorList: '', value2ErrorList: '' },
                { name: "miRNA expression", enabled: false, value1: "", value2: "", header1: "Up-regulated", header2: 'Down-regulated', value1ErrorList: '', value2ErrorList: '' },
                { name: "Copy Number Variation", enabled: false, value1: "", value2: "", header1: "Amplification", header2: 'Deletion', value1ErrorList: '', value2ErrorList: '' },
                { name: "DNA Methylation", enabled: false, value1: "", header1: "", value1ErrorList: '' },
                { name: "Mutation", enabled: false, value1: "", header1: "", value1ErrorList: '' },
                { name: "Fusion", enabled: false, value1: "", header1: "", value1ErrorList: '' }
            ],
            advancedSearchApplied: false,
        }, () => {
            this.drugListApifn(1, 'sensitive');
        })
    };

    advanceApplyBtnDisableCheck = () => {
        const { tempAdvancedOptions, geneNameValidateLoader } = this.state;

        const allDisabled = tempAdvancedOptions.every(option => !option.enabled);
        if (allDisabled) {
            return true;
        }

        if (geneNameValidateLoader) {
            return true;
        }
        for (let option of tempAdvancedOptions) {
            if (option.enabled) {
                if (option.value1 === "" && (option.value2 === "" || !option.value2)) {
                    return true;
                }
                if (option.value1ErrorList || option.value2ErrorList) {
                    return true;
                }
            }
        }


        return false;

    }

    checkApplyDisabled = () => {
        if (this.state.selectedOption === 'AND') {
            if (this.state.checkboxValues.fda || this.state.checkboxValues.clinical || this.state.checkboxValues.chemical || this.state?.checkboxValues?.pastCaseReport) {
                return false;
            } else {
                return true;
            }

        } else {
            return false;
        }
    }

    // handleCheckboxChange = (event) => {
    //     const { name, checked } = event.target;
    //     if (name === "fda" && checked) {
    //         // Unchecking checkbox2, also uncheck nestedCheckbox
    //         this.setState(prevState => ({
    //             checkboxValues: {
    //                 ...prevState.checkboxValues,
    //                 fda: true,
    //                 cancer: true,
    //                 noncancer: true,
    //             }
    //         }));
    //     } else if (name === "fda" && !checked) {
    //         // Unchecking checkbox2, also uncheck nestedCheckbox
    //         this.setState(prevState => ({
    //             checkboxValues: {
    //                 ...prevState.checkboxValues,
    //                 fda: false,
    //                 cancer: false,
    //                 noncancer: false,
    //             }
    //         }));
    //     } else if (name === "cancer" && checked) {
    //         // Unchecking checkbox2, also uncheck nestedCheckbox
    //         this.setState(prevState => ({
    //             checkboxValues: {
    //                 ...prevState.checkboxValues,
    //                 fda: true,
    //                 cancer: true,
    //                 // noncancer: false,
    //             }
    //         }));
    //     } else if (name === "noncancer" && checked) {
    //         // Unchecking checkbox2, also uncheck nestedCheckbox
    //         this.setState(prevState => ({
    //             checkboxValues: {
    //                 ...prevState.checkboxValues,
    //                 fda: true,
    //                 // cancer: true,
    //                 noncancer: true,
    //             }
    //         }));
    //     }
    //     else if (name === "cancer" && !checked) {
    //         // Unchecking checkbox2, also uncheck nestedCheckbox
    //         this.setState(prevState => ({
    //             checkboxValues: {
    //                 ...prevState.checkboxValues,
    //                 cancer: false,
    //                 fda: prevState.checkboxValues?.noncancer ? true : false
    //             }
    //         }));
    //     }
    //     else if (name === "noncancer" && !checked) {
    //         // Unchecking checkbox2, also uncheck nestedCheckbox
    //         this.setState(prevState => ({
    //             checkboxValues: {
    //                 ...prevState.checkboxValues,
    //                 noncancer: false,
    //                 fda: prevState.checkboxValues?.cancer ? true : false
    //             }
    //         }));
    //     }
    //     else {
    //         this.setState(prevState => ({
    //             checkboxValues: {
    //                 ...prevState.checkboxValues,
    //                 [name]: checked
    //             }
    //         }));
    //     }
    // };

    handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        if (name === "fda" && checked) {
            this.setState(prevState => ({
                checkboxValues: {
                    ...prevState.checkboxValues,
                    fda: true,
                    cancer: true,
                    noncancer: true,
                }
            }));
        } else if (name === "fda" && !checked) {
            this.setState(prevState => ({
                checkboxValues: {
                    ...prevState.checkboxValues,
                    fda: false,
                    cancer: false,
                    noncancer: false,
                }
            }));
        } else if (name === "cancer" && checked) {
            this.setState(prevState => ({
                checkboxValues: {
                    ...prevState.checkboxValues,
                    fda: true,
                    cancer: true,
                }
            }));
        } else if (name === "noncancer" && checked) {
            this.setState(prevState => ({
                checkboxValues: {
                    ...prevState.checkboxValues,
                    fda: true,
                    noncancer: true,
                }
            }));
        } else if (name === "cancer" && !checked) {
            this.setState(prevState => ({
                checkboxValues: {
                    ...prevState.checkboxValues,
                    cancer: false,
                    fda: prevState.checkboxValues?.noncancer ? true : false
                }
            }));
        } else if (name === "noncancer" && !checked) {
            this.setState(prevState => ({
                checkboxValues: {
                    ...prevState.checkboxValues,
                    noncancer: false,
                    fda: prevState.checkboxValues?.cancer ? true : false
                }
            }));
        } else if (name === "clinical" && checked) {
            this.setState(prevState => ({
                checkboxValues: {
                    ...prevState.checkboxValues,
                    clinical: true,
                    phaseI: true,
                    phaseII: true,
                    phaseIII: true,
                }
            }));
        } else if (name === "clinical" && !checked) {
            this.setState(prevState => ({
                checkboxValues: {
                    ...prevState.checkboxValues,
                    clinical: false,
                    phaseI: false,
                    phaseII: false,
                    phaseIII: false,
                }
            }));
        } else if (name === "phaseI" && checked) {
            this.setState(prevState => ({
                checkboxValues: {
                    ...prevState.checkboxValues,
                    phaseI: true,
                    clinical: true
                }
            }));
        } else if (name === "phaseI" && !checked) {
            this.setState(prevState => ({
                checkboxValues: {
                    ...prevState.checkboxValues,
                    phaseI: false,
                    clinical: prevState.checkboxValues?.phaseII || prevState.checkboxValues?.phaseIII ? true : false
                }
            }));
        } else if (name === "phaseII" && checked) {
            this.setState(prevState => ({
                checkboxValues: {
                    ...prevState.checkboxValues,
                    phaseII: true,
                    clinical: true
                }
            }));
        } else if (name === "phaseII" && !checked) {
            this.setState(prevState => ({
                checkboxValues: {
                    ...prevState.checkboxValues,
                    phaseII: false,
                    clinical: prevState.checkboxValues?.phaseI || prevState.checkboxValues?.phaseIII ? true : false
                }
            }));
        } else if (name === "phaseIII" && checked) {
            this.setState(prevState => ({
                checkboxValues: {
                    ...prevState.checkboxValues,
                    phaseIII: true,
                    clinical: true,
                }
            }));
        } else if (name === "phaseIII" && !checked) {
            this.setState(prevState => ({
                checkboxValues: {
                    ...prevState.checkboxValues,
                    phaseIII: false,
                    clinical: prevState.checkboxValues?.phaseI || prevState.checkboxValues?.phaseII ? true : false
                }
            }));
        } else {
            this.setState(prevState => ({
                checkboxValues: {
                    ...prevState.checkboxValues,
                    [name]: checked
                }
            }));
        }
    };

    handleOptionChange = (event) => {
        this.setState({
            selectedOption: event.target.value
        });
    }

    handleChange = (targetselectedOption) => {
        this.setState({ targetselectedOption: targetselectedOption });
    };

    handleChangeCancerType = (cancerselectedOption) => {
        this.setState({ cancerselectedOption: cancerselectedOption });
    };

    handlePageClick = (event) => {

        // this.setState({ selectedPage: event?.selected + 1 })
        this.drugListApifn(event?.selected + 1, this.state?.activeTab);
    }

    handleShowAdvancedOptions = () => {
        this.setState({
            showAdvancedOptions: true,
            tempAdvancedOptions: this.state?.advancedOptions
        });
    }

    handleHideAdvancedOptions = () => {

        this.setState(prevState => ({
            showAdvancedOptions: false,
            tempAdvancedOptions: this.state?.advancedOptions,
            advancedSearchApplied: prevState?.advancedSearchApplied
        }));
    }

    onAdvanceClear = () => {

        this.setState({
            tempAdvancedOptions: [
                { name: "mRNA/protein expression", enabled: false, value1: "", value2: "", header1: "Up-regulated", header2: 'Down-regulated', value1ErrorList: '', value2ErrorList: '' },
                { name: "miRNA expression", enabled: false, value1: "", value2: "", header1: "Up-regulated", header2: 'Down-regulated', value1ErrorList: '', value2ErrorList: '' },
                { name: "Copy Number Variation", enabled: false, value1: "", value2: "", header1: "Amplification", header2: 'Deletion', value1ErrorList: '', value2ErrorList: '' },
                { name: "DNA Methylation", enabled: false, value1: "", header1: "", value1ErrorList: '' },
                { name: "Mutation", enabled: false, value1: "", header1: "", value1ErrorList: '' },
                { name: "Fusion", enabled: false, value1: "", header1: "", value1ErrorList: '' }
            ],
            // advancedOptions: [
            //     { name: "mRNA/protein expression", enabled: false, value1: "", value2: "", header1: "Up-regulated", header2: 'Down-regulated', value1ErrorList: '', value2ErrorList: '' },
            //     { name: "miRNA expression", enabled: false, value1: "", value2: "", header1: "Up-regulated", header2: 'Down-regulated', value1ErrorList: '', value2ErrorList: '' },
            //     { name: "Copy Number Variation", enabled: false, value1: "", value2: "", header1: "Amplification", header2: 'Deletion', value1ErrorList: '', value2ErrorList: '' },
            //     { name: "DNA Methylation", enabled: false, value1: "", header1: "", value1ErrorList: '' },
            //     { name: "Mutation", enabled: false, value1: "", header1: "", value1ErrorList: '' },
            //     { name: "Fusion", enabled: false, value1: "", header1: "", value1ErrorList: '' }
            // ],
            // advancedSearchApplied: false,
        });
    };

    onAdvanceApplyClick = () => {
        this.setState({ advancedOptions: this.state?.tempAdvancedOptions, showAdvancedOptions: false, advancedSearchApplied: true }, () => {
            this.toggle("sensitive");
            this.drugListApifn(1, 'sensitive');
        });
    }

    handleAdvancedOptionChange = (index, field, value, option) => {

        const newOptions = JSON.parse(JSON.stringify(this.state.tempAdvancedOptions));

        if (field === 'value1' || field === 'value2') {
            const newvalue = removeGeneName(value)
            newOptions[index][field] = newvalue;
            if (!value) {
                newOptions[index][field + 'ErrorList'] = '';
            }
        } else if (field === 'enabled') {
            if (option?.value2) {
                newOptions[index].value2 = '';
                newOptions[index].value2ErrorList = '';
            }
            newOptions[index].value1 = '';
            newOptions[index].value1ErrorList = '';
            newOptions[index][field] = value;
        } else {
            newOptions[index][field] = value;

        }
        this.setState({ tempAdvancedOptions: newOptions });
    }

    handleAdvancedOptionOnBlurChange = (index, field, value, name) => {


        if (value) {
            this.setState({ geneNameValidateLoader: true });
            const typeName = (name === 'miRNA expression') ? 'mirna' : 'genes';
            axiosInstance
                .post(process.env.REACT_APP_BASE_URL + `api/verifyGenes?criteria=${typeName}`, {
                    "geneNames": value
                })
                .then((res) => {

                    const newOptions = JSON.parse(JSON.stringify(this.state.tempAdvancedOptions));
                    newOptions[index][`${field}ErrorList`] = res.data?.invalidGeneNames?.toString() ?? '';
                    this.setState({ tempAdvancedOptions: newOptions });
                })
                .catch((err) => {
                    console.error(" error:", err);
                })
                .finally(() => {
                    this.setState({ geneNameValidateLoader: false })
                });
        }
    }

    countGenes = (geneString) => {
        geneString = geneString.trim();

        if (geneString === '') {
            return 0;
        }

        const geneArray = geneString.split(',');
        const validGenes = geneArray.filter(gene => gene.trim() !== '');
        return validGenes.length === 1 ? true : false;
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    }

    getDruglist = (list) => {
        const expressionMap = {
            mrnaUpSensitive: 'up-regulated mRNA/protein expression',
            mrnaDownSensitive: 'down-regulated mRNA/protein expression ',
            mirnaUpSensitive: 'up-regulated miRNA expression',
            mirnaDownSensitive: 'down-regulated miRNA expression',
            cnvAmpSensitive: 'amplification copy number variation',
            cnvDelSensitive: 'deletion copy number variation',
            methySensitive: 'methylation',
            mutaSensitive: 'mutation',
            fusiSensitive: 'fusion',
            mrnaUpResistant: 'up-regulated mRNA/protein expression',
            mrnaDownResistant: 'down-regulated mRNA/protein expression',
            mirnaUpResistant: 'up-regulated miRNA expression',
            mirnaDownResistant: 'down-regulated miRNA expression ',
            cnvAmpResistant: 'amplification copy number variation',
            cnvDelResistant: 'deletion copy number variation',
            methyResistant: 'methylation',
            mutaResistant: 'mutation',
            fusiResistant: 'fusion',
        };

        const parts = [];

        list?.forEach((item) => {
            const key = item.split('-')[0];
            const template = item.substring(item.indexOf('-') + 1);
            if (expressionMap[key]) {
                parts.push(`${expressionMap[key]} of ${template}`);
            }
        });

        let resultString = '';
        if (parts.length === 1) {
            resultString = parts[0];
        } else if (parts.length === 2) {
            resultString = `${parts[0]} and ${parts[1]}`;
        } else if (parts.length > 2) {
            resultString = `${parts.slice(0, -1).join(', ')} and ${parts[parts.length - 1]}`;
        }


        return `Drugs with ${resultString}`
    }
    getPaginationCheck = () => {

        return this.state.drugList?.[`${this.state.activeTab}`]?.meta?.pagination?.pageCount > 1
    }

    render() {
        const { cancerselectedOption, checkboxValues, selectedOption, showAdvancedOptions, tempAdvancedOptions, advancedSearchApplied } = this.state;
        return (
            <>
                <div className="">
                    <div className="row">
                        <div className="col-lg-3 col-md-4 col-sm-12 mb-3">
                            <div className="card card-cancerType" style={{ 'backgroundColor': '#F2F2F2' }}>
                                <div className="card-body">
                                    <Link className='btn-link-primary'
                                        to={{ pathname: '/app/cancerType' }}>
                                        <div className="d-inline-flex">
                                            <img src={backlogo} alt="" />
                                            <span className="ms-1" style={{ color: '#333333', fontWeight: 400 }}>Back</span>
                                        </div>
                                    </Link>
                                    <div className="mt-2">
                                        <FormGroup>
                                            <Label for="exampleEmail" style={{ fontSize: '0.75rem', marginBottom: '0rem' }}>Cancer Type</Label>
                                            <Select styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    cursor: "pointer"
                                                }),
                                                option: (base) => ({
                                                    ...base,
                                                    cursor: "pointer",
                                                }),
                                            }} value={cancerselectedOption} onChange={this.handleChangeCancerType} options={this.state.cancerTypeOptions} />
                                        </FormGroup>
                                    </div>
                                    <hr style={{ 'width': '100%', 'text-align': 'left', 'margin-left': "0", margin: '0.5rem 0' }}></hr>
                                    <h5 className="my-2" style={{ color: '#333333', fontWeight: 'bold', fontSize: '0.875rem' }}>Drug Groups</h5>
                                    <div className="drugGroup-body">
                                        <h6 className="fw-normal mb-2 text-body-secondary" style={{ fontSize: '0.75rem' }}>Select the drug group you would like to explore</h6>
                                        <div>
                                            <span style={{ fontSize: '0.75rem' }}>
                                                {/* {this.state.selectedOption === 'AND' && <span style={{ color: 'red' }}>*</span>} */}
                                                Select the drug groups
                                            </span>
                                            <div className="d-flex flex-column mt-1">
                                                <div>
                                                    <div className="d-inline-flex">
                                                        <input type="checkbox" name="fda" checked={checkboxValues.fda} onChange={this.handleCheckboxChange} />
                                                        <label className="ps-1" style={{ fontSize: '0.75rem' }}>FDA Approved</label>
                                                    </div>
                                                    <ul style={{ listStyle: 'none', marginBottom: '0rem' }}>
                                                        <li>
                                                            <label className="d-inline-flex" style={{ fontSize: '0.75rem' }}>
                                                                <input type="checkbox" className="subOption me-1" name="cancer" checked={checkboxValues.cancer} onChange={this.handleCheckboxChange} />
                                                                Cancer Drugs
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <label className="d-inline-flex" style={{ fontSize: '0.75rem' }}>
                                                                <input type="checkbox" className="subOption me-1" name="noncancer" checked={checkboxValues.noncancer} onChange={this.handleCheckboxChange} />
                                                                Non Cancer Drugs
                                                            </label>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div>
                                                    <div className="d-inline-flex">
                                                        <input type="checkbox" name="clinical" checked={checkboxValues.clinical} onChange={this.handleCheckboxChange} />
                                                        <label className="ps-1" style={{ fontSize: '0.75rem' }}>In Clinical trials</label>
                                                    </div>
                                                    <ul style={{ listStyle: 'none', marginBottom: '0rem' }}>
                                                        <li>
                                                            <label className="d-inline-flex" style={{ fontSize: '0.75rem' }}>
                                                                <input type="checkbox" className="subOption me-1" name="phaseI" checked={checkboxValues.phaseI} onChange={this.handleCheckboxChange} />
                                                                Phase I
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <label className="d-inline-flex" style={{ fontSize: '0.75rem' }}>
                                                                <input type="checkbox" className="subOption me-1" name="phaseII" checked={checkboxValues.phaseII} onChange={this.handleCheckboxChange} />
                                                                Phase II
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <label className="d-inline-flex" style={{ fontSize: '0.75rem' }}>
                                                                <input type="checkbox" className="subOption me-1" name="phaseIII" checked={checkboxValues.phaseIII} onChange={this.handleCheckboxChange} />
                                                                Phase III
                                                            </label>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="d-inline-flex">
                                                    <input type="checkbox" name="chemical" checked={checkboxValues.chemical} onChange={this.handleCheckboxChange} />
                                                    <label className="ps-1" style={{ fontSize: '0.75rem' }}>Chemical Compounds</label>
                                                </div>
                                                <div className="d-inline-flex">
                                                    <input type="checkbox" name="pastCaseReport" checked={checkboxValues.pastCaseReport} onChange={this.handleCheckboxChange} />
                                                    <label className="ps-1" style={{ fontSize: '0.75rem' }}>Past Case Reports</label>
                                                </div>
                                            </div>
                                        </div>
                                        <hr style={{ 'width': '100%', 'text-align': 'left', 'margin-left': "0", margin: '0.5rem 0' }}></hr>
                                        {!showAdvancedOptions && (
                                            <div style={{ display: 'flex', position: 'relative', alignItems: 'center' }}>
                                                <div
                                                    className="text-link"
                                                    style={{ fontSize: '0.75rem', cursor: 'pointer', marginLeft: 'auto', textAlign: 'right' }}
                                                    onClick={this.handleShowAdvancedOptions}
                                                >
                                                    Advanced Search
                                                </div>
                                                {advancedSearchApplied && (
                                                    <div
                                                        style={{
                                                            position: 'absolute',
                                                            top: 0,
                                                            right: '5px',
                                                            transform: 'translate(50%, -50%)',
                                                            fontSize: '1rem',
                                                            color: 'red',
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        ●
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                        <div>
                                            <span style={{ fontSize: '0.75rem' }}>Select the grouping criteria</span>
                                            <div className="d-flex mt-1 gap-5">
                                                <div className="d-inline-flex">
                                                    <input type="radio" value="AND" checked={selectedOption === 'AND'} onChange={this.handleOptionChange} />
                                                    <label className="fw-bold ps-1" style={{ fontSize: '0.75rem' }}>AND</label>
                                                </div>
                                                <div className="d-inline-flex">
                                                    <input type="radio" value="OR" checked={selectedOption === 'OR'} onChange={this.handleOptionChange} />
                                                    <label className="fw-bold ps-1" style={{ fontSize: '0.75rem' }}>OR</label>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <hr style={{ 'width': '100%', 'text-align': 'left', 'margin-left': "0", margin: '0.5rem 0' }}></hr>
                                        <div className="mt-2">
                                            <FormGroup>
                                                <Label for="exampleEmail" style={{ fontSize: '0.75rem', marginBottom: '0rem' }}>
                                                    {this.state.selectedOption === 'AND' && <span style={{ color: 'red' }}>*</span>}Targeted Pathways
                                                </Label>
                                                <Select
                                                    styles={{
                                                        control: (baseStyles, state) => ({
                                                            ...baseStyles,
                                                            cursor: "pointer",
                                                            position: "relative",
                                                        }),
                                                        option: (base) => ({
                                                            ...base,
                                                            cursor: "pointer",
                                                        }),
                                                        menu: (base) => ({
                                                            ...base,
                                                            zIndex: 9999,
                                                            position: 'absolute',
                                                        }),
                                                        menuPortal: (base) => ({
                                                            ...base,
                                                            zIndex: 9999,
                                                        }),
                                                    }}
                                                    menuPortalTarget={document.body}
                                                    value={targetselectedOption}
                                                    onChange={this.handleChange}
                                                    options={this.state.pathwayTypeOptions}
                                                />
                                            </FormGroup>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="drugGroup-button">
                                    {advancedSearchApplied &&
                                        <div className="d-flex gap-2 mb-2">
                                            <div className="text-center">
                                                <IoMdInformationCircle style={{ width: 16, height: 16 }} />
                                            </div>
                                            <div style={{ fontSize: '12px', color: 'rgb(105, 105, 105)' }}>
                                                Advanced search is applied.
                                            </div>
                                        </div>}
                                    <div className="d-flex gap-2">
                                        <Button className="w-100" color="light" onClick={this.onCancel}>Cancel</Button>
                                        <Button className="w-100" color="primary" onClick={() => this.drugListApifn(1, 'sensitive')} disabled={this.checkApplyDisabled()}>Apply</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9 col-md-8 col-sm-12 mb-3">
                            <div className="card card-cancerType" style={{ backgroundColor: '#F2F2F2', minHeight: this.state.drugList?.data?.length > 0 ? 'auto' : '75vh' }}>
                                <div className="card-body card-body-cancerType" >
                                    {(!this.state?.advancedSearchApplied && !this.state?.loader) ?
                                        this.state.drugList?.defaultData?.data?.length > 0 ?
                                            <div className="drug-list-defaultDataConatiner">
                                                {this.state.drugList?.defaultData?.data.map(list => (
                                                    <div className="drug-list-item" key={list?.attributes?.drug} style={{ backgroundColor: '#FFFFFF', padding: '1rem', borderRadius: '0.5rem' }}>
                                                        <Link className='btn-link-primary' target="_blank" to={{ pathname: `/app/drugpage?id=${list?.attributes?.disease_drugID}` }}>
                                                            <div className="drugList-header">{list?.attributes?.drug}</div>
                                                            <div className="drugList-desc">{list?.attributes?.descr}</div>
                                                        </Link>
                                                    </div>
                                                ))}
                                            </div>
                                            :
                                            !this.state.loader && <div className="cancerPage-noDataFound">
                                                <AiOutlineSearch style={{ width: 22, height: 22, marginRight: '4px' }} />
                                                <span className=""> No data found matching your filter. Please adjust your filter settings to see results.</span>
                                            </div>
                                        :
                                        !this.state.loader && <>
                                            {/* <Card className="main-card mb-3"> */}
                                            < div className="headerButtonCancerList">
                                                <Button outline
                                                    className={
                                                        "border-0 btn-transition " +
                                                        classnames({ active: this.state.activeTab === "sensitive" })
                                                    }
                                                    color="primary"
                                                    onClick={() => {
                                                        this.toggle("sensitive");
                                                        this.drugListApifn(1, 'sensitive');
                                                    }}>
                                                    Sensitive Drugs
                                                </Button>
                                                <Button outline
                                                    className={
                                                        "me-1 ms-1 border-0 btn-transition " +
                                                        classnames({ active: this.state.activeTab === "resistant" })
                                                    }
                                                    color="primary"
                                                    onClick={() => {
                                                        this.toggle("resistant");
                                                        this.drugListApifn(1, 'resistant');
                                                    }}>
                                                    Resistant Drugs
                                                </Button>
                                                <Button outline
                                                    className={
                                                        "border-0 btn-transition " +
                                                        classnames({ active: this.state.activeTab === "other" })
                                                    }
                                                    color="primary"
                                                    onClick={() => {
                                                        this.toggle("other");
                                                        this.drugListApifn(1, 'other');
                                                    }}>
                                                    No Matching
                                                </Button>
                                            </div>
                                            <div >
                                                <TabContent activeTab={this.state.activeTab}>
                                                    <TabPane tabId="sensitive" className={`cancerPage_tabPane ${this.state.drugList?.sensitive?.data?.length > 0 ? '' : 'cancerPage-tabPane-noDataFound'}`}>
                                                        {this.state.drugList?.sensitive?.data?.length > 0 ?
                                                            this.state.drugList?.sensitive?.data?.map((list, index) => (
                                                                <>
                                                                    {list?.data[0]?.sources?.length > 0 && <div className="Druglist-headerTitle">
                                                                        {this.getDruglist(list?.data[0]?.sources)}
                                                                    </div>}
                                                                    {/* <div className={`drug-list-item ${index === 0 ? 'drug-list-item-first-item' : ''}`} key={list?.attributes?.drug}> */}
                                                                    {list?.data?.map((innerList, index) => (
                                                                        <div className={`drug-list-item `} key={innerList?.attributes?.drug}>
                                                                            <Link className='btn-link-primary' target="_blank" to={{ pathname: `/app/drugpage?id=${innerList?.attributes?.disease_drugID}` }}>
                                                                                <div className="drugList-header">{innerList?.attributes?.drug}</div>
                                                                                <div className="drugList-desc">{innerList?.attributes?.descr}</div>
                                                                            </Link>
                                                                        </div>
                                                                    ))}
                                                                </>
                                                            ))
                                                            :
                                                            !this.state.loader && <div className="cancerPage-noDataFound">
                                                                <AiOutlineSearch style={{ width: 46, height: 22, marginRight: '4px' }} />
                                                                <span className="">We couldn't find any results matching your search in <span className="font-weight-bold">Sensitive Drugs</span>. Don't worry, your search terms might be a good fit for <span className="font-weight-bold">Resistant Drugs</span>. Check Resistant Drugs tab to see if your desired results are there.</span>
                                                            </div>
                                                        }
                                                    </TabPane>
                                                    <TabPane tabId="resistant" className={`cancerPage_tabPane ${this.state.drugList?.resistant?.data?.length > 0 ? '' : 'cancerPage-tabPane-noDataFound'}`}>
                                                        {this.state.drugList?.resistant?.data?.length > 0 ?
                                                            this.state.drugList?.resistant?.data?.map((list, index) => (
                                                                <>
                                                                    {list?.data[0]?.sources?.length > 0 && <div className="Druglist-headerTitle">
                                                                        {this.getDruglist(list?.data[0]?.sources)}
                                                                    </div>}
                                                                    {list?.data?.map((innerList, index) => (
                                                                        <div className={`drug-list-item`} key={innerList?.attributes?.drug}>
                                                                            <Link className='btn-link-primary' target="_blank" to={{ pathname: `/app/drugpage?id=${innerList?.attributes?.disease_drugID}` }}>
                                                                                <div className="drugList-header">{innerList?.attributes?.drug}</div>
                                                                                <div className="drugList-desc">{innerList?.attributes?.descr}</div>
                                                                            </Link>
                                                                        </div>
                                                                    ))}
                                                                </>
                                                            ))
                                                            :
                                                            !this.state.loader && <div className="cancerPage-noDataFound">
                                                                <AiOutlineSearch style={{ width: 46, height: 22, marginRight: '4px' }} />
                                                                <span className="">We couldn't find any results matching your search in <span className="font-weight-bold">Resistant Drugs</span>. Don't worry, your search terms might be a good fit for <span className="font-weight-bold">Sensitive Drugs</span>. Check Sensitive Drugs tab to see if your desired results are there.</span>
                                                            </div>
                                                        }
                                                    </TabPane>
                                                    <TabPane tabId="other" className={`cancerPage_tabPane ${this.state.drugList?.other?.data?.length > 0 ? '' : 'cancerPage-tabPane-noDataFound'}`}>
                                                        {this.state.drugList?.other?.data?.length > 0 ?
                                                            this.state.drugList?.other?.data?.map((list, index) => (
                                                                <>
                                                                    {list?.sources?.length > 0 && <div className="Druglist-headerTitle">
                                                                        {this.getDruglist(list?.sources)}
                                                                    </div>}
                                                                    <div className={`drug-list-item`} key={list?.attributes?.drug}>
                                                                        <Link className='btn-link-primary' target="_blank" to={{ pathname: `/app/drugpage?id=${list?.attributes?.disease_drugID}` }}>
                                                                            <div className="drugList-header">{list?.attributes?.drug}</div>
                                                                            <div className="drugList-desc">{list?.attributes?.descr}</div>
                                                                        </Link>
                                                                    </div>
                                                                </>
                                                            ))
                                                            :
                                                            !this.state.loader && <div className="cancerPage-noDataFound">
                                                                <AiOutlineSearch style={{ width: 22, height: 22, marginRight: '4px' }} />
                                                                <span className=""> No data found matching your filter. Please adjust your filter settings to see results.</span>
                                                            </div>
                                                        }
                                                    </TabPane>
                                                </TabContent>
                                            </div >
                                            {/* </Card> */}
                                        </>
                                    }

                                </div>
                                {
                                    // this.getPaginationCheck() &&
                                    // this.state.drugList?.meta?.pagination?.pageCount > 1 &&
                                    (((this.state?.advancedSearchApplied) ? this.state.drugList?.[`${this.state.activeTab}`]?.meta?.pagination?.pageCount > 1 : this.state.drugList?.defaultData?.meta?.pagination?.pageCount > 1) && !this.state.loader) &&
                                    <ReactPaginate
                                        nextLabel=">"
                                        onPageChange={this.handlePageClick}
                                        pageRangeDisplayed={3}
                                        marginPagesDisplayed={2}
                                        pageCount={this.state?.advancedSearchApplied ? this.state?.pageCount?.[`${this.state.activeTab}`] : this.state?.pageCount?.defaultData}
                                        forcePage={(this.state?.advancedSearchApplied ? this.state.selectedPage?.[`${this.state.activeTab}`] : this.state.selectedPage?.defaultData) - 1}
                                        previousLabel="<"
                                        pageClassName="page-item"
                                        pageLinkClassName="page-link"
                                        previousClassName="page-item"
                                        previousLinkClassName="page-link"
                                        nextClassName="page-item"
                                        nextLinkClassName="page-link"
                                        breakLabel="..."
                                        breakClassName="page-item"
                                        breakLinkClassName="page-link"
                                        containerClassName="pagination pagination-custom-cancerType"
                                        activeClassName="active"
                                        renderOnZeroPageCount={null}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div >
                <SweetAlert
                    custom
                    show={showAdvancedOptions}
                    showCancel
                    showCloseButton
                    style={{ width: 'auto', padding: '1rem' }}
                    customButtons={
                        <React.Fragment>
                            <button className="btn btn-lg btn-light" style={{ marginRight: '8px' }} onClick={this.onAdvanceClear}>
                                Clear
                            </button>
                            <button
                                className="btn btn-lg btn-primary"
                                style={{ marginRight: '8px', boxShadow: 'rgba(0, 0, 0, 0.075) 0px 1px 1px inset, rgb(165, 202, 234) 0px 0px 8px' }}
                                onClick={this.onAdvanceApplyClick}
                                disabled={this.advanceApplyBtnDisableCheck()}
                            >
                                Apply
                            </button>
                        </React.Fragment>
                    }
                >

                    <div style={{ position: 'relative' }}>
                        <button
                            style={{
                                position: 'absolute',
                                top: '-30px',
                                right: '-15px',
                                background: 'transparent',
                                border: 'none',
                                fontSize: '1.5em',
                                cursor: 'pointer',
                            }}
                            onClick={
                                this.handleHideAdvancedOptions
                            }
                        >
                            &times;
                        </button>
                        <div className="advance-header">
                            Advanced Search
                        </div>
                        <div className="advance-subheader">
                            Patient Responder Characteristics
                        </div>
                        <div className="advance-paragraph">
                            Add gene names separated by comma
                        </div>
                        <div className="mt-2" style={{ position: 'relative', maxHeight: '58vh', overflowY: 'scroll' }}>
                            <table className="advance_table">
                                <tbody>
                                    {tempAdvancedOptions.map((option, index) => (
                                        <tr key={index}>
                                            <td style={{ verticalAlign: 'top', padding: '2rem 0.5rem' }}>
                                                <div style={{ display: 'table-cell', verticalAlign: 'top' }}>
                                                    <Input
                                                        type="checkbox"
                                                        checked={option.enabled}
                                                        onChange={(e) => this.handleAdvancedOptionChange(index, 'enabled', e.target.checked, option)}
                                                        style={{ height: '18px', width: '18px' }}
                                                    />
                                                </div>
                                            </td>
                                            <td style={{ verticalAlign: 'top', padding: '2rem 0.5rem' }}>
                                                <div style={{ display: 'table-cell', verticalAlign: 'top' }}>
                                                    <div style={{ fontSize: '14px', color: '#696969' }}>
                                                        {option.name}
                                                    </div>
                                                </div>
                                            </td>
                                            <td colSpan={option.value2 !== undefined ? 1 : 2} >
                                                <FormGroup className="mb-0">
                                                    <Label for={`exampleHeader1_${index}`} style={{ fontSize: '12px', marginBottom: '0' }}>
                                                        {option.header1}
                                                    </Label>
                                                    <Input
                                                        type="textarea"
                                                        id={`exampleHeader1_${index}`}
                                                        name={`text_${index}`}
                                                        placeholder="Gene Names"
                                                        value={option.value1}
                                                        onChange={(e) => this.handleAdvancedOptionChange(index, 'value1', e.target.value, option)}
                                                        disabled={!option.enabled}
                                                        onBlur={(e) => this.handleAdvancedOptionOnBlurChange(index, 'value1', e.target.value, option?.name)}
                                                        innerRef={(ref) => this.textareaRefs[index] = React.createRef(ref)}

                                                        className="auto-expand"

                                                        style={{
                                                            width: option.value2 !== undefined ? '250px' : '100%', // Fixed width
                                                            height: '44px', // Initial height
                                                            overflowY: 'auto', // Scroll if content exceeds maxHeight
                                                        }}
                                                    />
                                                    {(option?.value1ErrorList && option?.value1ErrorList.length > 0) &&
                                                        <div className="advance_error">{this.countGenes(option?.value1ErrorList) ? 'Invalid gene name is' : 'Following are invalid gene name'} : {option?.value1ErrorList}</div>}
                                                </FormGroup>
                                            </td>
                                            {option.value2 !== undefined &&
                                                <td>
                                                    <FormGroup className="mb-0">
                                                        <Label for={`exampleHeader2_${index}`} style={{ fontSize: '12px', marginBottom: '0' }}>
                                                            {option.header2}
                                                        </Label>
                                                        <Input
                                                            id={`exampleHeader2_${index}`}
                                                            name={`text_${index}`}
                                                            placeholder="Gene Names"
                                                            type="textarea"
                                                            value={option.value2}
                                                            onChange={(e) => this.handleAdvancedOptionChange(index, 'value2', e.target.value, option)}
                                                            disabled={!option.enabled}
                                                            onBlur={(e) => this.handleAdvancedOptionOnBlurChange(index, 'value2', e.target.value, option?.name)}
                                                            className="auto-expand"
                                                            style={{
                                                                width: '250px',
                                                                height: '44px',
                                                                overflowY: 'auto',
                                                            }}
                                                        />
                                                        {(option?.value2ErrorList && option?.value2ErrorList.length > 0) &&
                                                            <div className="advance_error">{this.countGenes(option?.value2ErrorList) ? 'Invalid gene name is' : 'Following are invalid gene name'} : {option?.value2ErrorList}</div>}
                                                    </FormGroup>
                                                </td>
                                            }
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="advance-upload">
                            Upload patient data
                        </div>
                    </div>

                </SweetAlert>
                {
                    this.state.loader &&
                    <div className="backdrop">
                        <div className="loader">
                            <Loader type="ball-pulse-rise" />
                        </div>
                    </div>
                }
            </>
        );
    }

}

export default connect(mapStateToProps)(CancerType);