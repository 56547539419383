import React, { Component } from "react";
import { Button } from "reactstrap";
import Loader from "react-loaders";
import { saveAs } from 'file-saver';
import FileViewerComponent from './Embed'
import DrugPageLikeQuery from "./DrugPageLikeQuery";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import eventEmitter from "../ReusableComponent/EventEmitter";
import { connect } from 'react-redux';
import { AiOutlineLock } from "react-icons/ai";
import axiosInstance from "../configuration/Request";

const mapStateToProps = (state) => {
    return {
        reduxData: state.counterSlice
    };
};

class DrugPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapse: false,
            accordion: [true, false, false],
            custom: [false, false],
            status: "Closed",
            fadeIn: true,
            timeout: 300,
            drugData: [],
            refrencesList: [],
            upRegulated: '',
            downRegulated: '',
            RMA_URL: [],
            RMA_data: [],
            CPRData: [],
            CPRGeneData: {
                mRNA_Resistant: [],
                mRNA_Sensitive: [],
                CNV_amp_Resistant: [],
                CNV_amp_Sensitive: [],
                CNV_del_Resistant: [],
                CNV_del_Sensitive: [],
                mutation_Resistant: [],
                mutation_Sensitive: [],
                fusion_Resistant: [],
                fusion_Sensitive: [],
                methylation_Resistant: [],
                methylation_Sensitive: [],
                miRNA_Resistant: [],
                miRNA_Sensitive: [],
                mRNA_Resistant_Expanded: [],
                mRNA_Sensitive_Expanded: [],
                CNV_amp_Resistant_Expanded: [],
                CNV_amp_Sensitive_Expanded: [],
                CNV_del_Resistant_Expanded: [],
                CNV_del_Sensitive_Expanded: [],
                mutation_Resistant_Expanded: [],
                mutation_Sensitive_Expanded: [],
                fusion_Resistant_Expanded: [],
                fusion_Sensitive_Expanded: [],
                methylation_Resistant_Expanded: [],
                methylation_Sensitive_Expanded: [],
            },
            efficacy_ic_50_ec_50s_data: [],
            correlation_ic_icorrs: [],
            loader: false,
            dynamicContent: "This is some dynamic content.",
            imagesLoaded: false,
            subscriptionLevel: '',
            accessList: [],
            base64Images: [],
            base64ImagesForPage: [],
            imagesLoadedForPage: false,
            tooltipOpen: {},
        };
        this.exportPdf = this.exportPdf.bind(this);
        this.printContentById = this.printContentById.bind(this);
        this.pdfContentDownload = this.pdfContentDownload.bind(this);
    }


    componentDidMount() {

        let highestIdObject = { id: 0 };

        this.props?.reduxData?.loginSubscriptionDetail?.forEach(item => {
            if (item.id > highestIdObject?.id) {
                highestIdObject = { ...item };
            }
        });

        this.setState({ subscriptionLevel: highestIdObject?.attributes?.plan?.toLowerCase() })

        eventEmitter.on('callhandleDownloadPDF', this.pdfContentDownload);
        eventEmitter.on('callhandlePrintPDF', this.printContentById);

        this.setState({ loader: true })

        const queryString = new URLSearchParams(this.props?.location?.search);
        const id = queryString.get("id");

        Promise.all([
            axiosInstance.get(`${process.env.REACT_APP_BASE_URL}api/drug-informations?filters[disease_drugID][$eq]=${id}&populate=*&fields=*`),
            axiosInstance.get(`${process.env.REACT_APP_BASE_URL}api/drug-informations?filters[disease_drugID][$eq]=${id}&populate=references`),
            axiosInstance.get(`${process.env.REACT_APP_BASE_URL}api/field-level-accesses?filters[is_accessible_for_${highestIdObject?.attributes?.plan?.toLowerCase()}][$eq]=true`),
            axiosInstance.get(`${process.env.REACT_APP_BASE_URL}api/drug-informations?filters[disease_drugID][$eq]=${id}&populate[mi_rna_targeted_genes][populate][0]=miRNAtargetedGenes_img`),
            axiosInstance.get(`${process.env.REACT_APP_BASE_URL}api/drug-informations?filters[disease_drugID][$eq]=${id}&populate[efficacy_ic_50_ec_50s][populate][0]=efficacy_img&populate[efficacy_ic_50_ec_50s][populate][1]=efficacyIC50EC50_detail`),
            axiosInstance.get(`${process.env.REACT_APP_BASE_URL}api/drug-informations?filters[disease_drugID][$eq]=${id}&populate[ic_icorrs][populate][0]=ICIcorr_detail&populate[ic_icorrs][populate][1]=ICIcorr_image`)
        ])
            .then(([drugInfoRes, referencesRes, accessListRes, cprRes, efficacyRes, correlationRes]) => {
                let up_mo_aid = [], down_mo_aid = [], mRNA_Resistant = [],
                    mRNA_Sensitive = [],
                    CNV_amp_Resistant = [],
                    CNV_amp_Sensitive = [],
                    CNV_del_Resistant = [],
                    CNV_del_Sensitive = [],
                    methylation_Resistant = [],
                    methylation_Sensitive = [],
                    miRNA_Sensitive = [],
                    miRNA_Resistant = [],
                    mutation_Resistant = [],
                    mutation_Sensitive = [],
                    fusion_Resistant = [],
                    fusion_Sensitive = [],
                    mRNA_Resistant_Expanded = [],
                    mRNA_Sensitive_Expanded = [],
                    CNV_amp_Resistant_Expanded = [],
                    CNV_amp_Sensitive_Expanded = [],
                    CNV_del_Resistant_Expanded = [],
                    CNV_del_Sensitive_Expanded = [],
                    methylation_Resistant_Expanded = [],
                    methylation_Sensitive_Expanded = [],
                    mutation_Resistant_Expanded = [],
                    mutation_Sensitive_Expanded = [],
                    fusion_Resistant_Expanded = [],
                    fusion_Sensitive_Expanded = [];

                const moAID = [...new Set(drugInfoRes?.data?.data[0]?.attributes?.mo_aid?.data?.map(list => list?.attributes?.MoAID))];

                this.filterData(drugInfoRes?.data?.data[0]?.attributes?.mo_aid?.data)

                const RMA_groupedData = drugInfoRes?.data?.data[0]?.attributes?.mo_aid?.data?.reduce((acc, curr) => {
                    const { MoAID, ...rest } = curr.attributes;
                    if (!acc[MoAID]) {
                        acc[MoAID] = [];
                    }
                    acc[MoAID].push(rest);
                    return acc;
                }, {});


                for (const key in RMA_groupedData) {
                    const list = RMA_groupedData[key];
                    const listIDs = list.map(item => item.listID);

                    // Filter unique listIDs for the current key
                    const uniqueIDs = Array.from(new Set(listIDs));

                    // Update the RMA_groupedData object with unique listIDs for the current key
                    RMA_groupedData[key].listID = uniqueIDs;
                }


                Object.keys(RMA_groupedData)?.length !== 0 && this.RMAFileAPIcall(RMA_groupedData)



                if (drugInfoRes?.data?.data[0]?.attributes?.mo_aid?.data?.length > 0) {
                    for (let i = 0; i < drugInfoRes?.data?.data[0]?.attributes?.mo_aid?.data?.length; i++) {
                        if (drugInfoRes?.data?.data[0]?.attributes?.mo_aid?.data[i]?.attributes?.up_down === 'up') {
                            up_mo_aid.push(drugInfoRes?.data?.data[0]?.attributes?.mo_aid?.data[i]?.attributes?.geneName)

                        } else if (drugInfoRes?.data?.data[0]?.attributes?.mo_aid?.data[i]?.attributes?.up_down === 'down') {
                            down_mo_aid.push(drugInfoRes?.data?.data[0]?.attributes?.mo_aid?.data[i]?.attributes?.geneName)

                        }
                    }
                }

                if (drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data?.length > 0) {
                    for (let i = 0; i < drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data?.length; i++) {
                        if (drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.level === 'mRNA&protein' && drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.resistant_sensitive === 'Resistant' && drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.expanded === '0') {
                            mRNA_Resistant.push(drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.geneName)
                        } else if (drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.level === 'mRNA&protein' && drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.resistant_sensitive === 'Sensitive' && drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.expanded === '0') {
                            mRNA_Sensitive.push(drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.geneName)
                        }
                        else if (drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.level === 'CNV_amp' && drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.resistant_sensitive === 'Resistant' && drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.expanded === '0') {
                            CNV_amp_Resistant.push(drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.geneName)
                        } else if (drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.level === 'CNV_amp' && drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.resistant_sensitive === 'Sensitive' && drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.expanded === '0') {
                            CNV_amp_Sensitive.push(drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.geneName)
                        }
                        else if (drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.level === 'CNV_del' && drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.resistant_sensitive === 'Resistant' && drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.expanded === '0') {
                            CNV_del_Resistant.push(drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.geneName)
                        } else if (drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.level === 'CNV_del' && drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.resistant_sensitive === 'Sensitive' && drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.expanded === '0') {
                            CNV_del_Sensitive.push(drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.geneName)
                        }
                        else if (drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.level === 'methylation' && drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.resistant_sensitive === 'Resistant' && drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.expanded === '0') {
                            methylation_Resistant.push(drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.geneName)
                        } else if (drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.level === 'methylation' && drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.resistant_sensitive === 'Sensitive' && drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.expanded === '0') {
                            methylation_Sensitive.push(drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.geneName)
                        }
                        else if (drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.level === 'mutation' && drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.resistant_sensitive === 'Resistant' && drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.expanded === '0') {
                            mutation_Resistant.push(drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.geneName)
                        } else if (drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.level === 'mutation' && drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.resistant_sensitive === 'Sensitive' && drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.expanded === '0') {
                            mutation_Sensitive.push(drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.geneName)
                        }
                        else if (drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.level === 'fusion' && drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.resistant_sensitive === 'Resistant' && drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.expanded === '0') {
                            fusion_Resistant.push(drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.geneName)
                        } else if (drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.level === 'fusion' && drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.resistant_sensitive === 'Sensitive' && drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.expanded === '0') {
                            fusion_Sensitive.push(drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.geneName)
                        }

                        if (drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.level === 'mRNA&protein' && drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.resistant_sensitive === 'Resistant') {
                            mRNA_Resistant_Expanded.push(drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.geneName)
                        } else if (drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.level === 'mRNA&protein' && drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.resistant_sensitive === 'Sensitive') {
                            mRNA_Sensitive_Expanded.push(drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.geneName)
                        }
                        else if (drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.level === 'CNV_amp' && drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.resistant_sensitive === 'Resistant') {
                            CNV_amp_Resistant_Expanded.push(drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.geneName)
                        } else if (drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.level === 'CNV_amp' && drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.resistant_sensitive === 'Sensitive') {
                            CNV_amp_Sensitive_Expanded.push(drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.geneName)
                        }
                        else if (drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.level === 'CNV_del' && drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.resistant_sensitive === 'Resistant') {
                            CNV_del_Resistant_Expanded.push(drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.geneName)
                        } else if (drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.level === 'CNV_del' && drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.resistant_sensitive === 'Sensitive') {
                            CNV_del_Sensitive_Expanded.push(drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.geneName)
                        }
                        else if (drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.level === 'methylation' && drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.resistant_sensitive === 'Resistant') {
                            methylation_Resistant_Expanded.push(drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.geneName)
                        } else if (drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.level === 'methylation' && drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.resistant_sensitive === 'Sensitive') {
                            methylation_Sensitive_Expanded.push(drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.geneName)
                        }

                        else if (drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.level === 'mutation' && drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.resistant_sensitive === 'Resistant') {
                            mutation_Resistant_Expanded.push(drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.geneName)
                        } else if (drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.level === 'mutation' && drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.resistant_sensitive === 'Sensitive') {
                            mutation_Sensitive_Expanded.push(drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.geneName)
                        }
                        else if (drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.level === 'fusion' && drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.resistant_sensitive === 'Resistant') {
                            fusion_Resistant_Expanded.push(drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.geneName)
                        } else if (drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.level === 'fusion' && drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.resistant_sensitive === 'Sensitive') {
                            fusion_Sensitive_Expanded.push(drugInfoRes?.data?.data[0]?.attributes?.responder_genes?.data[i]?.attributes?.geneName)
                        }
                    }
                }
                if (drugInfoRes?.data?.data[0]?.attributes?.responder_mi_rnas?.data?.length > 0) {

                    for (let i = 0; i < drugInfoRes?.data?.data[0]?.attributes?.responder_mi_rnas?.data?.length; i++) {
                        if (drugInfoRes?.data?.data[0]?.attributes?.responder_mi_rnas?.data[i]?.attributes?.resistant_sensitive === 'Resistant') {
                            miRNA_Resistant.push(drugInfoRes?.data?.data[0]?.attributes?.responder_mi_rnas?.data[i]?.attributes?.miRNAName)
                        } else if (drugInfoRes?.data?.data[0]?.attributes?.responder_mi_rnas?.data[i]?.attributes?.resistant_sensitive === 'Sensitive') {
                            miRNA_Sensitive.push(drugInfoRes?.data?.data[0]?.attributes?.responder_mi_rnas?.data[i]?.attributes?.miRNAName)
                        }
                    }
                }



                console.log(up_mo_aid, down_mo_aid, mRNA_Resistant,
                    mRNA_Sensitive,
                    CNV_amp_Resistant,
                    CNV_amp_Sensitive,
                    CNV_del_Resistant,
                    CNV_del_Sensitive,
                    methylation_Resistant,
                    methylation_Sensitive, miRNA_Resistant, miRNA_Sensitive, 'up');

                this.setState((prevState) => {
                    return {
                        drugData: drugInfoRes?.data?.data[0],
                        upRegulated: up_mo_aid,
                        downRegulated: down_mo_aid,
                        CPRGeneData: {
                            ...prevState.CPRGeneData,
                            mRNA_Resistant: mRNA_Resistant,
                            mRNA_Sensitive: mRNA_Sensitive,
                            CNV_amp_Resistant: CNV_amp_Resistant,
                            CNV_amp_Sensitive: CNV_amp_Sensitive,
                            CNV_del_Resistant: CNV_del_Resistant,
                            CNV_del_Sensitive: CNV_del_Sensitive,
                            mutation_Resistant: mutation_Resistant,
                            mutation_Sensitive: mutation_Sensitive,
                            fusion_Resistant: fusion_Resistant,
                            fusion_Sensitive: fusion_Sensitive,
                            methylation_Resistant: methylation_Resistant,
                            methylation_Sensitive: methylation_Sensitive,
                            miRNA_Resistant: miRNA_Resistant,
                            miRNA_Sensitive: miRNA_Sensitive,
                            mRNA_Resistant_Expanded: mRNA_Resistant_Expanded?.length > 0 ? mRNA_Resistant_Expanded?.toString() : 'N/A',
                            mRNA_Sensitive_Expanded: mRNA_Sensitive_Expanded?.length > 0 ? mRNA_Sensitive_Expanded?.toString() : 'N/A',
                            CNV_amp_Resistant_Expanded: CNV_amp_Resistant_Expanded?.length > 0 ? CNV_amp_Resistant_Expanded?.toString() : 'N/A',
                            CNV_amp_Sensitive_Expanded: CNV_amp_Sensitive_Expanded?.length > 0 ? CNV_amp_Sensitive_Expanded?.toString() : 'N/A',
                            CNV_del_Resistant_Expanded: CNV_del_Resistant_Expanded?.length > 0 ? CNV_del_Resistant_Expanded?.toString() : 'N/A',
                            CNV_del_Sensitive_Expanded: CNV_del_Sensitive_Expanded?.length > 0 ? CNV_del_Sensitive_Expanded?.toString() : 'N/A',
                            methylation_Resistant_Expanded: methylation_Resistant_Expanded?.length > 0 ? methylation_Resistant_Expanded?.toString() : 'N/A',
                            methylation_Sensitive_Expanded: methylation_Sensitive_Expanded?.length > 0 ? methylation_Sensitive_Expanded?.toString() : 'N/A',
                            mutation_Resistant_Expanded: mutation_Resistant_Expanded?.length > 0 ? mutation_Resistant_Expanded?.toString() : 'N/A',
                            mutation_Sensitive_Expanded: mutation_Sensitive_Expanded?.length > 0 ? mutation_Sensitive_Expanded?.toString() : 'N/A',
                            fusion_Resistant_Expanded: fusion_Resistant_Expanded?.length > 0 ? fusion_Resistant_Expanded?.toString() : 'N/A',
                            fusion_Sensitive_Expanded: fusion_Sensitive_Expanded?.length > 0 ? fusion_Sensitive_Expanded?.toString() : 'N/A',
                        },
                        refrencesList: referencesRes?.data?.data[0]?.attributes?.references?.data,
                        accessList: accessListRes?.data?.data,
                        CPRData: cprRes?.data?.data[0]?.attributes?.mi_rna_targeted_genes?.data || [],
                        efficacy_ic_50_ec_50s_data: efficacyRes?.data?.data[0]?.attributes?.efficacy_ic_50_ec_50s?.data || [],
                        correlation_ic_icorrs: correlationRes?.data?.data[0]?.attributes?.ic_icorrs?.data || [],
                        imagesLoaded: false,
                        loader: false
                    };
                }, () => {
                    this.loadImages();
                    this.convertImagesToBase64();
                });
            })
            .catch(err => {
                console.error('Error fetching data:', err);
            });



    };

    toDataURL = (url) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.crossOrigin = 'anonymous';
            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                canvas.width = img.width;
                canvas.height = img.height;
                ctx.drawImage(img, 0, 0);
                resolve(canvas.toDataURL('image/png'));
            };
            img.onerror = (error) => {
                reject(`Error loading image from URL: ${url}. Error: ${error.message}`);
            };
            img.src = url;
        });
    }

    convertImagesToBase64 = async () => {
        const { drugData, CPRData, efficacy_ic_50_ec_50s_data, correlation_ic_icorrs } = this.state;

        const convertImages = async (data, key) => {
            return Promise.all(data.map(async (item) => {
                const images = item[key];
                let imgUrl = '';
                if (key === 'drugData_img') {
                    imgUrl = item?.attributes?.chemStruct?.data?.attributes?.url
                } else if (key === 'miRNAtargetedGenes_img') {
                    imgUrl = item?.attributes?.miRNAtargetedGenes_img?.data?.attributes?.url
                } else if (key === 'efficacy_img') {
                    imgUrl = item?.attributes?.efficacy_img?.data?.attributes?.url
                } else if (key === 'ICIcorr_image') {
                    imgUrl = item?.attributes?.ICIcorr_image?.data?.attributes?.url
                }
                try {
                    const base64 = await this.toDataURL(imgUrl); // Adjust the key if necessary
                    return { ...item, base64 }
                        ;
                } catch (error) {
                    console.error('Error converting image to base64:', error);
                    return { ...item, base64: '' }; // Provide fallback or placeholder
                }
            }));
        };


        try {
            const drugDataWithBase64 = await convertImages([drugData], 'drugData_img');
            const CPRDataWithBase64 = await convertImages(CPRData, 'miRNAtargetedGenes_img');
            const efficacyDataWithBase64 = await convertImages(efficacy_ic_50_ec_50s_data, 'efficacy_img');
            const correlationDataWithBase64 = await convertImages(correlation_ic_icorrs, 'ICIcorr_image');

            console.log(drugData, drugDataWithBase64, CPRDataWithBase64, efficacyDataWithBase64, correlationDataWithBase64, 'ttttttt')
            this.setState({
                drugData: drugDataWithBase64[0],
                CPRData: CPRDataWithBase64,
                efficacy_ic_50_ec_50s_data: efficacyDataWithBase64,
                correlation_ic_icorrs: correlationDataWithBase64,
                imagesLoaded: true
            }, () => {
                console.log(drugData, drugDataWithBase64, CPRDataWithBase64, efficacyDataWithBase64, correlationDataWithBase64, 'ttttttt')

            }
            )
        } catch (error) {
            console.error('Error converting images:', error);
            this.setState({ imagesLoaded: true });
        }
    }

    RMAFileAPIcall = (RMA_groupedData) => {
        axiosInstance.get(`${process.env.REACT_APP_BASE_URL}api/upload/files`)
            .then(res => {
                let filteredFiles = RMA_groupedData;
                res?.data?.forEach(fileItem => {
                    Object.keys(filteredFiles).forEach(key => {
                        // filteredFiles[key].forEach(geneItem => {
                        if (fileItem.name.includes(key)) {
                            if (!filteredFiles[key]) {
                                filteredFiles[key] = [];
                            }

                            filteredFiles[key].fileData = fileItem;
                        }
                    });
                });

                this.setState({ RMA_data: filteredFiles })
            }).catch(err => {
                console.log(err)
            }).finally(() => {

            });
    }

    filterData = (data) => {

        // Use Array.reduce() to group data by MoAID
        const groupedData = data?.reduce((acc, curr) => {
            const { MoAID, ...rest } = curr.attributes;
            if (!acc[MoAID]) {
                acc[MoAID] = [];
            }
            acc[MoAID].push(rest);
            return acc;
        }, {});


        Object.entries(groupedData).map(([MoAID, items]) => (
            console.log(MoAID, items)
        ))
    };

    checkLinkPatent = (text) => {

        return <p>
            {text.split("contact Sostos").map((part, index) => {
                return (
                    <React.Fragment key={index}>
                        {part}
                        {index !== text.split("contact Sostos").length - 1 && (
                            <a href="">contact Sostos</a>
                        )}
                    </React.Fragment>
                );
            })}
        </p>

    }

    checkReferenceLinkFn = (text) => {

        const regex = /(https?:\/\/\S+)/g;
        const newText = text.replace(regex, (match) => {
            match = match.endsWith('.') ? match.slice(0, -1) : match
            return `<a href="${match}" target="_blank">${match}</a>`;
        });
        const jsxText = <div dangerouslySetInnerHTML={{ __html: newText }} />;
        return jsxText;

    };

    downloadFile = (fileUrl, name) => {

        this.setState({ loader: true })
        fetch(fileUrl)
            .then(response => response.blob())
            .then(blob => {
                saveAs(blob, name)
                this.setState({ loader: false })
            }
            )
            .catch(error => console.error('Error downloading file:', error));
    };


    toggleCustom(tab) {
        const prevState = this.state.custom;
        const state = prevState.map((x, index) => (tab === index ? !x : false));

        this.setState({
            custom: state,
        });
    }

    handleClickScroll = (referenceId) => {
        const element = document.getElementById(`section-${referenceId}`);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    checkForCitations = (content) => {
        const { refrencesList } = this.state;
        refrencesList?.forEach(reference => {
            if (content) {
                const citationRegex = new RegExp(reference?.attributes?.citation?.replace("[", "\\[").replace("]", "\\]"), "g");
                if (citationRegex.test(content)) {
                    this.updateCSS(reference.id);
                }
            }
        });
    }

    updateCSS(referenceId) {
        const citationElements = document.getElementsByClassName(`citation-${referenceId}`);
        Array.from(citationElements).forEach(citationElement => {
            citationElement.style.color = '#376faa';
            citationElement.style.cursor = 'pointer';
            citationElement.addEventListener('click', () => this.handleCitationClick(referenceId));
        })
    }

    handleCitationClick(referenceId) {

        this.handleClickScroll(referenceId)
    };

    renderHTMLReferenceCheck(content) {

        this.checkForCitations(content)
        return (
            <div>
                {content.split(/(\(.*?\))/).map((part, index) => {
                    const reference = this.state.refrencesList.find(ref => ref.attributes.citation === part);
                    if (reference) {
                        return (
                            <span
                                key={index}
                                className={`citation-${reference.id}`}
                            >
                                {reference.attributes.citation}
                            </span>
                        );
                    } else {
                        const regex = /(?:IC|EC)50/g;
                        const updatedContent = part?.replace(regex, (match) => {
                            return match.replace("50", "<sub>50</sub>");
                        });
                        const jsxText = <div style={{ display: "inline" }} dangerouslySetInnerHTML={{ __html: updatedContent }} />;
                        return jsxText;
                    }
                })}
            </div>
        );


    };

    async loadImages() {

        const imageObjects = this?.state?.CPRData
            .filter(item => item.attributes.expanded_y.toLowerCase() === 'yes')
            .map(item => ({
                url: `${item.attributes.miRNAtargetedGenes_img.data.attributes.url}`,
                description: item.attributes.description
            }));



        const toDataURL = (url) =>
            new Promise((resolve, reject) => {
                const img = new Image();
                img.crossOrigin = 'anonymous';
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    canvas.width = img.width;
                    canvas.height = img.height;
                    ctx.drawImage(img, 0, 0);
                    resolve(canvas.toDataURL('image/png'));
                };
                img.onerror = reject;
                img.src = url;
            });
        const promises = imageObjects?.map(obj =>
            toDataURL(obj.url).then(dataURL => ({
                base64: dataURL,
                description: obj.description
            }))
        );

        const base64Images = await Promise.all(promises);

        this.setState({ base64Images, imagesLoaded: true });

    }

    checkAccessFn = (fnName) => {
        const { accessList, subscriptionLevel } = this.state;
        const found = accessList?.find(item => item.attributes?.field?.toUpperCase() === fnName?.toUpperCase());

        return found ? found.attributes[`is_accessible_for_${subscriptionLevel}`] : false;
    }

    restrictedDiv = () => {
        return (
            <div className="restrictedSection">
                <div className="text-center">
                    <AiOutlineLock style={{ width: 32, height: 32 }} />
                </div>
                <div>
                    {/* Subscription_Commented_Code */}
                    {/* Uncomment this Subscription_Commented_Code */}
                    {/* This section is locked.Kindly <span className="text-link" onClick={() => this.props?.history?.push({ pathname: '/app/profileEdit', state: { param: 'billing' } })}>upgrade</span> to view this section. */}

                    {/* Remove this Subscription_Commented_Code */}
                    To be released.
                </div>
            </div>
        )
    }

    loaderChange = (val) => {
        this.setState({ loader: val })
    }

    async exportPdf() {
        if (!this.state.imagesLoaded) {
            console.error('Images are not loaded yet.');
            return;
        }

        this.setState({ loader: true });
        const pdf = new jsPDF('p', 'mm', 'a4');
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        const margin = 10;
        const effectivePageHeight = pageHeight - 2 * margin;

        const mRNA_Resistant_Expanded = this.state?.CPRGeneData?.mRNA_Resistant_Expanded != 'N/A' || this.state?.CPRGeneData?.mRNA_Sensitive_Expanded != 'N/A';
        const CNV_amp_Resistant_Expanded = this.state?.CPRGeneData?.CNV_amp_Resistant_Expanded != 'N/A' || this.state?.CPRGeneData?.CNV_amp_Sensitive_Expanded != 'N/A' || this.state?.CPRGeneData?.CNV_del_Resistant_Expanded != 'N/A' || this.state?.CPRGeneData?.CNV_del_Sensitive_Expanded != 'N/A';
        const mutation_Resistant_Expanded = this.state?.CPRGeneData?.mutation_Resistant_Expanded != 'N/A' || this.state?.CPRGeneData?.mutation_Sensitive_Expanded != 'N/A';
        const methylation_Resistant_Expanded = this.state?.CPRGeneData?.methylation_Resistant_Expanded != 'N/A' || this.state?.CPRGeneData?.methylation_Sensitive_Expanded != 'N/A';
        const fusion_Resistant_Expanded = this.state?.CPRGeneData?.fusion_Resistant_Expanded != 'N/A' || this.state?.CPRGeneData?.fusion_Sensitive_Expanded != 'N/A';

        const contentHTML = [
            `<p style="font-family:'Arial',sans-serif; font-weight: bold; font-size: 14px; ">Drug Sensitivity Classification : </p>
            <p style="font-family:'Arial',sans-serif; font-size: 12px ">
              Cell lines were classified as resistant if their half-maximal inhibitory concentration (IC50) or half-maximal effective concentration (EC50) values of the studied drug exceeded the maximum dose (10 µm); conversely cell lines were classified as sensitive if their IC50 or EC50 values of the studied drug were below the minimum dose (0.0006 µm). Utilizing the mean ± 0.5 standard deviations of the drug sensitivity measurements the remaining cell lines were categorized into three groups: sensitive, partial response, or resistant (Ye Mohamed et al. 2021; Ye Singh et al. 2021).
            </p>`,
            `<p style="font-family:'Arial',sans-serif; font-weight: bold; font-size: 14px; text-decoration: underline;" >Genes Associated with Drug Sensitivity and Resistance</p>`,

            mRNA_Resistant_Expanded ?
                `<div>
                <div  style="font-family:'Arial',sans-serif; font-size: 12px; text-decoration: underline; font-weight: bold;">Genes with concordant mRNA and protein expression:</div>
                <div  style="font-family:'Arial',sans-serif; font-size: 12px;">Resistant genes were expressed significantly higher at both mRNA and protein level (p < 0.05; two-sample t-tests) in resistant versus sensitive NSCLC cell lines. Similarly, sensitive genes were expressed significantly higher at both mRNA and protein level (p < 0.05; two-sample t-tests) in sensitive versus resistant NSCLC cell lines (DepMap 2023; Nusinow et al. 2020).</div>
                </div>` : '',

            mRNA_Resistant_Expanded ?
                `
            <table style="width: 100%; border-collapse: collapse; word-wrap: break-word; table-layout: fixed;">
                <thead>
                 <tr>
                     <th style="border: 1px solid black; padding: 8px; font-weight: bold; max-width: 50%; overflow-wrap: break-word;">Resistant Genes</th>
                     <th style="border: 1px solid black; padding: 8px; font-weight: bold; max-width: 50%; overflow-wrap: break-word;">Sensitive Genes</th>
                  </tr>
                  </thead>
              <tbody>
                  <tr>
                  <td style="border: 1px solid black; padding: 8px; max-width: 50%; overflow-wrap: break-word;">${this.state?.CPRGeneData?.mRNA_Resistant_Expanded}</td>
                  <td style="border: 1px solid black; padding: 8px; max-width: 50%; overflow-wrap: break-word;">${this.state?.CPRGeneData?.mRNA_Sensitive_Expanded}</td>
              </tr>
            </tbody>
            </table>`: '',


            CNV_amp_Resistant_Expanded ?
                `<div>
                <div  style="font-family:'Arial',sans-serif; font-size: 12px; text-decoration: underline; font-weight: bold;">DNA Copy Number Variation:</div>
                <div  style="font-family:'Arial',sans-serif; font-size: 12px;">Resistant genes were 1) amplified, 2) expressed significantly higher at mRNA or protein level with no conflict in resistant NSCLC cell lines. Similarly, sensitive genes were 1) amplified, 2) expressed significantly higher at mRNA or protein level with no conflict in sensitive NSCLC cell lines (DepMap 2023; Nusinow et al. 2020). Copy number values were represented as log2(copy number ratio + 1). The data were further categorized into amplification (copy number ratio ≥ 1.5), deletion (copy number ratio ≤ 0.5), and normal (0.5 < copy number ratio < 1.5). DNA copy number variations associated with drug sensitivity were identified based on two criteria: 1) for continuous copy number data, significant difference in sensitive versus resistant NSCLC cell lines (p < 0.05; two-sample t-tests), and 2) for categorized copy number data, significant association between amplification/deletion and drug sensitivity/resistance (p < 0.05, Chi-squared tests).</div>
                </div>` : '',

            CNV_amp_Resistant_Expanded ?
                `<table style="width: 100%; border-collapse: collapse; word-wrap: break-word; table-layout: fixed;">
              <thead>
                <tr>
                  <th style="border: 1px solid black; padding: 8px;">CNV</th>
                  <th style="border: 1px solid black; padding: 8px;">Resistant Genes</th>
                  <th style="border: 1px solid black; padding: 8px;">Sensitive Genes</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="border: 1px solid black; padding: 8px;">Amplification</td>
                  <td style="border: 1px solid black; padding: 8px;">${this.state?.CPRGeneData?.CNV_amp_Resistant_Expanded}</td>
                  <td style="border: 1px solid black; padding: 8px;">${this.state?.CPRGeneData?.CNV_amp_Sensitive_Expanded}</td>
                </tr>
                <tr>
                  <td style="border: 1px solid black; padding: 8px;">Deletion</td>
                  <td style="border: 1px solid black; padding: 8px;">${this.state?.CPRGeneData?.CNV_del_Resistant_Expanded}</td>
                  <td style="border: 1px solid black; padding: 8px;">${this.state?.CPRGeneData?.CNV_del_Sensitive_Expanded}</td>
                </tr>
              </tbody>
            </table>`: '',


            mutation_Resistant_Expanded ?
                `<div>
            <div  style="font-family:'Arial',sans-serif; font-size: 12px; text-decoration: underline; font-weight: bold;">Gene Mutation:</div>
            <div  style="font-family:'Arial',sans-serif; font-size: 12px;">Resistant genes 1) had significantly more mutations (p < 0.05, Chi-squared tests), 2) differentially expressed at mRNA or protein level (p < 0.05, two-sample t-tests) with no conflict in resistant NSCLC cell lines. Similarly, sensitive genes were 1) had significantly more mutations (p < 0.05, Chi-squared tests), 2) differentially expressed at mRNA or protein level (p < 0.05, two-sample t-tests) with no conflict in sensitive NSCLC cell lines (DepMap 2023; Nusinow et al. 2020).</div>
            </div>` : '',

            mutation_Resistant_Expanded ?
                `<table style="width: 100%; border-collapse: collapse; word-wrap: break-word; table-layout: fixed;">
             <thead>
             <tr>
                 <th style="border: 1px solid black; padding: 8px; font-weight: bold; max-width: 50%; overflow-wrap: break-word;">Resistant Genes</th>
                 <th style="border: 1px solid black; padding: 8px; font-weight: bold; max-width: 50%; overflow-wrap: break-word;">Sensitive Genes</th>
              </tr>
              </thead>
                 <tbody>
              <tr>
              <td style="border: 1px solid black; padding: 8px; max-width: 50%; overflow-wrap: break-word;">${this.state?.CPRGeneData?.mutation_Resistant_Expanded}</td>
              <td style="border: 1px solid black; padding: 8px; max-width: 50%; overflow-wrap: break-word;">${this.state?.CPRGeneData?.mutation_Sensitive_Expanded}</td>
            </tr>
           </tbody>
               </table>`: '',


            methylation_Resistant_Expanded ?
                `<div>
            <div  style="font-family:'Arial',sans-serif; font-size: 12px; text-decoration: underline; font-weight: bold;">CpG Sites with DNA Methylation:</div>
            <div  style="font-family:'Arial',sans-serif; font-size: 12px;">Resistant genes 1) had more DNA methylations at CpG sites, 2) were expressed significantly higher at mRNA or protein level (p < 0.05, two-sample t-tests) with no conflict in resistant NSCLC cell lines. Similarly, sensitive genes 1) had more DNA methylations at CpG sites, 2) were expressed significantly higher at mRNA or protein level (p < 0.05, two-sample t-tests) with no conflict in sensitive NSCLC cell lines. DNA methylation data were categorized in two ways (Luo et al. 2020): 1) Unmethylated site (RRBS ≤ 0.2), Full-methylated site (RRBS ≥ 0.75), and Middle-methylated site (0.2 < RRBS < 0.75); 2) with a single cutoff of 0.6. DNA methylations associated with drug sensitivity were identified based on two criteria: 1) for continuous DNA methylation RRBS values, significant difference in sensitive versus resistant NSCLC cell lines (p < 0.05; two-sample t-tests), and 2) for categorized DNA methylation data, significant association between methylation status and drug sensitivity/resistance (p < 0.05, Chi-squared tests) (DepMap 2023; Nusinow et al. 2020).</div>
            </div>` : '',

            methylation_Resistant_Expanded ?
                `<table style="width: 100%; border-collapse: collapse; word-wrap: break-word; table-layout: fixed;">
             <thead>
             <tr>
                 <th style="border: 1px solid black; padding: 8px; font-weight: bold; max-width: 50%; overflow-wrap: break-word;">Resistant CpG Sites</th>
                 <th style="border: 1px solid black; padding: 8px; font-weight: bold; max-width: 50%; overflow-wrap: break-word;">Sensitive CpG Sites</th>
              </tr>
              </thead>
                 <tbody>
              <tr>
              <td style="border: 1px solid black; padding: 8px; max-width: 50%; overflow-wrap: break-word;">${this.state?.CPRGeneData?.methylation_Resistant_Expanded}</td>
              <td style="border: 1px solid black; padding: 8px; max-width: 50%; overflow-wrap: break-word;">${this.state?.CPRGeneData?.methylation_Sensitive_Expanded}</td>
            </tr>
           </tbody>
               </table>
               `: '',


            fusion_Resistant_Expanded ?
                `<div>
            <div  style="font-family:'Arial',sans-serif; font-size: 12px; text-decoration: underline; font-weight: bold;">Gene Fusions:</div>
            <div  style="font-family:'Arial',sans-serif; font-size: 12px;">Resistant genes 1) had significantly more fusions (p < 0.05, Chi-squared tests), 2) differentially expressed at mRNA or protein level (p < 0.05, two-sample t-tests) with no conflict in resistant NSCLC cell lines. Similarly, sensitive genes were 1) had significantly more fusions (p < 0.05, Chi-squared tests), 2) differentially expressed at mRNA or protein level (p < 0.05, two-sample t-tests) with no conflict in sensitive NSCLC cell lines (DepMap 2023; Nusinow et al. 2020).</div>
            </div>` : '',

            fusion_Resistant_Expanded ?
                `<table style="width: 100%; border-collapse: collapse; word-wrap: break-word; table-layout: fixed;">
             <thead>
             <tr>
                 <th style="border: 1px solid black; padding: 8px; font-weight: bold; max-width: 50%; overflow-wrap: break-word;">Resistant Genes</th>
                 <th style="border: 1px solid black; padding: 8px; font-weight: bold; max-width: 50%; overflow-wrap: break-word;">Sensitive Genes</th>
              </tr>
              </thead>
                 <tbody>
              <tr>
              <td style="border: 1px solid black; padding: 8px; max-width: 50%; overflow-wrap: break-word;">${this.state?.CPRGeneData?.fusion_Resistant_Expanded}</td>
              <td style="border: 1px solid black; padding: 8px; max-width: 50%; overflow-wrap: break-word;">${this.state?.CPRGeneData?.fusion_Sensitive_Expanded}</td>
            </tr>
           </tbody>
               </table>`: '',

            (this.state?.CPRGeneData?.miRNA_Sensitive?.length > 0 || this.state?.CPRGeneData?.miRNA_Resistant?.length > 0) ?
                `<div>
               <div  style="font-family:'Arial',sans-serif; font-size: 12px; text-decoration: underline; font-weight: bold;">microRNA:</div>
               <div  style="font-family:'Arial',sans-serif; font-size: 12px;">Resistant miRNAs were expressed significantly higher (p < 0.05; two-sample t-tests; FDR < 0.25) in resistant versus sensitive NSCLC cell lines. Similarly, sensitive miRNAs were expressed significantly higher (p < 0.05; two-sample t-tests; FDR < 0.25) in sensitive versus resistant NSCLC cell lines.</div>
               </div>` : '',

            (this.state?.CPRGeneData?.miRNA_Sensitive?.length > 0 || this.state?.CPRGeneData?.miRNA_Resistant?.length > 0) ?
                `<table style="width: 100%; border-collapse: collapse; word-wrap: break-word; table-layout: fixed;">
                <thead>
                <tr>
                    <th style="border: 1px solid black; padding: 8px; font-weight: bold; max-width: 50%; overflow-wrap: break-word;">Resistant miRNAs</th>
                    <th style="border: 1px solid black; padding: 8px; font-weight: bold; max-width: 50%; overflow-wrap: break-word;">Sensitive miRNAs</th>
                 </tr>
                 </thead>
                    <tbody>
                 <tr>
                 <td style="border: 1px solid black; padding: 8px; max-width: 50%; overflow-wrap: break-word;">${this.state?.CPRGeneData?.miRNA_Resistant?.length > 0 ? this.state?.CPRGeneData?.miRNA_Resistant : 'N/A'}</td>
                 <td style="border: 1px solid black; padding: 8px; max-width: 50%; overflow-wrap: break-word;">${this.state?.CPRGeneData?.miRNA_Sensitive?.length > 0 ? this.state?.CPRGeneData?.miRNA_Sensitive : 'N/A'}</td>
               </tr>
              </tbody>
                  </table>`: '',


            this.state.base64Images.map(base64Image =>
                `<div> 
                  <span>${base64Image?.description} </span>
                  <img src="${base64Image?.base64}" alt="Image" style="width: 100%; margin-bottom: 10px;" />
                </div>`)
        ];

        const hiddenContainer = document.createElement('div');
        hiddenContainer.style.width = '210mm'; // A4 width in mm
        hiddenContainer.style.position = 'absolute';
        hiddenContainer.style.top = '-9999px';
        hiddenContainer.innerHTML = contentHTML;
        document.body.appendChild(hiddenContainer);

        const elements = hiddenContainer.children;
        let yOffset = margin;

        // for (let i = 0; i < elements.length; i++) {
        //     const element = elements[i];
        //     const elementHeight = element.offsetHeight * 0.264583; // Convert pixels to mm (assuming 96 DPI)

        //     if (yOffset + elementHeight > effectivePageHeight) {
        //         pdf.addPage();
        //         yOffset = margin;
        //     }

        //     const canvas = await html2canvas(element, {
        //         useCORS: true,
        //         allowTaint: true,
        //         scale: 2 // Increase scale for better quality
        //     });

        //     const imgData = canvas.toDataURL('image/png');
        //     const imgWidth = pageWidth - 2 * margin;
        //     const imgHeight = (canvas.height * imgWidth) / canvas.width;

        //     pdf.addImage(imgData, 'PNG', margin, yOffset, imgWidth, imgHeight);
        //     yOffset += elementHeight;
        // }
        for (let i = 0; i < elements.length; i++) {
            const element = elements[i];
            const elementHeight = element.offsetHeight * 0.264583; // Convert pixels to mm (assuming 96 DPI)

            if (elementHeight > effectivePageHeight) {
                // Element is too large to fit on a single page, so break it down
                let partOffset = 0;
                while (partOffset < elementHeight) {
                    if (yOffset + effectivePageHeight > pageHeight) {
                        pdf.addPage();
                        yOffset = margin;
                    }

                    const canvas = await html2canvas(element, {
                        useCORS: true,
                        allowTaint: true,
                        scale: 2, // Increase scale for better quality
                        height: effectivePageHeight * 3.78,
                        y: partOffset * 3.78
                    });

                    const imgData = canvas.toDataURL('image/png');
                    const imgWidth = pageWidth - 2 * margin;
                    const imgHeight = (canvas.height * imgWidth) / canvas.width;

                    pdf.addImage(imgData, 'PNG', margin, yOffset, imgWidth, imgHeight);
                    yOffset += effectivePageHeight;
                    partOffset += effectivePageHeight;
                }
            } else {
                if (yOffset + elementHeight > effectivePageHeight) {
                    pdf.addPage();
                    yOffset = margin;
                }

                const canvas = await html2canvas(element, {
                    useCORS: true,
                    allowTaint: true,
                    scale: 2 // Increase scale for better quality
                });

                const imgData = canvas.toDataURL('image/png');
                const imgWidth = pageWidth - 2 * margin;
                const imgHeight = (canvas.height * imgWidth) / canvas.width;

                pdf.addImage(imgData, 'PNG', margin, yOffset, imgWidth, imgHeight);
                yOffset += elementHeight;
            }
        }

        document.body.removeChild(hiddenContainer);
        pdf.save(`Expanded Gene List-${this.state.drugData?.attributes?.drug ?? ''}-${this.state.drugData?.attributes?.disease_drugID ?? ''}.pdf`);
        this.setState({ loader: false });

    }
    async pdfContentDownload() {
        this.setState({ loader: true });
        const pdf = new jsPDF('p', 'mm', 'a4');
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        const margin = 10;
        const effectivePageHeight = pageHeight - 2 * margin;

        const inputChild = document.getElementById('print-content');
        const childDivs = Array.from(inputChild.children).map(child => child.outerHTML);
        // const childDivs = Array.from(inputChild.getElementsByClassName('pdf-content-subclass')).map(child => child.outerHTML);

        const hiddenContainer = document.createElement('div');
        hiddenContainer.style.width = '210mm'; // A4 width in mm
        hiddenContainer.style.position = 'absolute';
        hiddenContainer.style.top = '-9999px';
        hiddenContainer.innerHTML = childDivs.join(''); // Join the array to form a single HTML string
        document.body.appendChild(hiddenContainer);

        const elements = hiddenContainer.children;

        let yOffset = margin;

        for (let i = 0; i < elements.length; i++) {
            const element = elements[i];
            const elementHeight = element.offsetHeight * 0.264583; // Convert pixels to mm (assuming 96 DPI)

            if (elementHeight > effectivePageHeight) {
                // Element is too large to fit on a single page, so break it down
                let partOffset = 0;
                while (partOffset < elementHeight) {
                    if (yOffset + effectivePageHeight > pageHeight - margin) {
                        pdf.addPage();
                        yOffset = margin;
                    }

                    const canvas = await html2canvas(element, {
                        useCORS: true,
                        allowTaint: true,
                        scale: 2, // Increase scale for better quality
                        height: effectivePageHeight * 3.78,
                        y: partOffset * 3.78
                    });

                    const imgData = canvas.toDataURL('image/png');
                    const imgWidth = pageWidth - 2 * margin;
                    const imgHeight = (canvas.height * imgWidth) / canvas.width;

                    pdf.addImage(imgData, 'PNG', margin, yOffset, imgWidth, imgHeight);
                    yOffset += imgHeight;
                    partOffset += effectivePageHeight;
                }
            } else {
                if (yOffset + elementHeight > pageHeight - margin) {
                    pdf.addPage();
                    yOffset = margin;
                }

                const canvas = await html2canvas(element, {
                    useCORS: true,
                    allowTaint: true,
                    scale: 2 // Increase scale for better quality
                });

                const imgData = canvas.toDataURL('image/png');
                const imgWidth = pageWidth - 2 * margin;
                const imgHeight = (canvas.height * imgWidth) / canvas.width;

                pdf.addImage(imgData, 'PNG', margin, yOffset, imgWidth, imgHeight);
                yOffset += imgHeight;
            }
        }

        document.body.removeChild(hiddenContainer);
        pdf.save(`Drug Page - ${this.state.drugData?.attributes?.drug ?? ''}-${this.state.drugData?.attributes?.disease_drugID ?? ''}.pdf`);
        this.setState({ loader: false });
    }


    printContentById() {
        window.print();
    }

    toggle = (id) => {
        this.setState((prevState) => ({
            tooltipOpen: {
                ...prevState.tooltipOpen,
                [id]: !prevState.tooltipOpen[id],
            },
        }));
    };

    render() {
        return (
            <>
                <div>
                    <div className="drugPage-container" id="pdf-content">
                        <div id="print-content">

                            <div className="pdf-content-subclass">
                                <div className="drug-subheader">
                                    {this.state.drugData?.attributes?.disease_drugID}
                                </div>
                                <div className="drug-header">
                                    {this.state.drugData?.attributes?.drug}
                                </div>
                                <div className="drug-subheader my-2">
                                    {this.state.drugData?.attributes?.IUPAC}
                                </div>
                                <div className="my-2">
                                    <img className="drugPage-img" src={this.state.drugData?.base64} alt="" />
                                </div>
                                <div className="drug-content my-2">
                                    {this.state.drugData?.attributes?.descr}
                                </div>
                            </div>
                            {/* <hr /> */}
                            <div className="drupPage-subConatiner">
                                <div className="drug-header">
                                    Repositioning Disease Type
                                </div>
                                {this.checkAccessFn('Repositioning Disease Type') ?
                                    <div className="drug-subheader my-2">
                                        {this.state.drugData?.attributes?.disease_type ?? 'N/A'}
                                    </div>
                                    :
                                    this.restrictedDiv()
                                }
                            </div>
                            <div className="drupPage-subConatiner">
                                <div className="drug-header">
                                    Main Repositioning Disease Type
                                </div>
                                {this.checkAccessFn('Main Repositioning Disease Type') ?
                                    <div className="drug-subheader my-2">
                                        {this.state.drugData?.attributes?.main_repositioning ?? 'N/A'}
                                    </div>
                                    :
                                    this.restrictedDiv()
                                }
                            </div>
                            <div className="drupPage-subConatiner">
                                <div className="drug-header">
                                    FDA Approval Information
                                </div>
                                {this.checkAccessFn('FDA Approval Information') ?
                                    <div className="drug-subheader my-2">

                                        {this.state.drugData?.attributes?.fdas?.data?.length > 0 ?
                                            // <div className="table-container">

                                            //     <table className="drugPage-Table">
                                            //         <tr>
                                            //             <th  >Application Number</th>
                                            //             <th  >Drug Name (Active Ingredients)</th>
                                            //             <th  >Dose</th>
                                            //             <th  >Dosage Form/Route</th>
                                            //             <th  >Strength</th>
                                            //             <th  >FDA Indication</th>
                                            //             <th  >Marketing Status</th>
                                            //             <th  >Company</th>
                                            //             <th  >Route of Administration</th>
                                            //         </tr>
                                            //         {this.state.drugData?.attributes?.fdas?.data?.map((list, index) => {
                                            //             const id = `Tooltip-${index}`;
                                            //             return (
                                            //                 <tr key={index} id={id} className="tooltip-row">
                                            //                     <td>
                                            //                         <a href={list?.attributes?.url} target="_blank">
                                            //                             {list?.attributes?.ApplicationNumber ?? 'N/A'}
                                            //                         </a>
                                            //                     </td>
                                            //                     <td>
                                            //                         <a href={list?.attributes?.url} target="_blank">
                                            //                             {list?.attributes?.drug_name ?? 'N/A'}
                                            //                         </a>
                                            //                     </td>
                                            //                     <td>
                                            //                         <a href={list?.attributes?.url} target="_blank">
                                            //                             {list?.attributes?.dose ?? 'N/A'}
                                            //                         </a>
                                            //                     </td>
                                            //                     <td>
                                            //                         <a href={list?.attributes?.url} target="_blank">
                                            //                             {list?.attributes?.DosageFormRoute ?? 'N/A'}
                                            //                         </a>
                                            //                     </td>
                                            //                     <td>
                                            //                         <a href={list?.attributes?.url} target="_blank">
                                            //                             {list?.attributes?.Strength ?? 'N/A'}
                                            //                         </a>
                                            //                     </td>
                                            //                     <td>
                                            //                         <a href={list?.attributes?.url} target="_blank">
                                            //                             {list?.attributes?.fdaindication ?? 'N/A'}
                                            //                         </a>
                                            //                     </td>
                                            //                     <td>
                                            //                         <a href={list?.attributes?.url} target="_blank">
                                            //                             {list?.attributes?.MarketingStatus ?? 'N/A'}
                                            //                         </a>
                                            //                     </td>
                                            //                     <td>
                                            //                         <a href={list?.attributes?.url} target="_blank">
                                            //                             {list?.attributes?.Company ?? 'N/A'}
                                            //                         </a>
                                            //                     </td>
                                            //                     <td>
                                            //                         <a href={list?.attributes?.url} target="_blank">
                                            //                             {list?.attributes?.routeofadmin ?? 'N/A'}
                                            //                         </a>
                                            //                     </td>
                                            //                     <Tooltip
                                            //                         placement="bottom"
                                            //                         isOpen={this.state.tooltipOpen[id]}
                                            //                         target={id}
                                            //                         toggle={() => this.toggle(id)}
                                            //                         autohide={false}
                                            //                         className="custom-tooltip"
                                            //                     >
                                            //                         {list?.attributes?.url ?
                                            //                             <a href={list?.attributes?.url} target="_blank">
                                            //                                 {list?.attributes?.url}
                                            //                             </a>
                                            //                             : `No url found for ${list?.attributes?.ApplicationNumber} application number.`}
                                            //                     </Tooltip>
                                            //                 </tr>
                                            //             )
                                            //         })}
                                            //     </table> </div> 
                                            <div className="table-container">
                                                <table className="drugPage-Table">
                                                    <tr>
                                                        <th>Application Number</th>
                                                        <th>Drug Name (Active Ingredients)</th>
                                                        <th>Dose</th>
                                                        <th>Dosage Form/Route</th>
                                                        <th>Strength</th>
                                                        <th>FDA Indication</th>
                                                        <th>Marketing Status</th>
                                                        <th>Company</th>
                                                        <th>Route of Administration</th>
                                                    </tr>
                                                    {this.state.drugData?.attributes?.fdas?.data?.map((list, rowIndex) => {
                                                        return (
                                                            <tr key={rowIndex}>
                                                                {[
                                                                    { key: 'ApplicationNumber', value: list?.attributes?.ApplicationNumber },
                                                                    { key: 'drug_name', value: list?.attributes?.drug_name },
                                                                    { key: 'dose', value: list?.attributes?.dose },
                                                                    { key: 'DosageFormRoute', value: list?.attributes?.DosageFormRoute },
                                                                    { key: 'Strength', value: list?.attributes?.Strength },
                                                                    { key: 'fdaindication', value: list?.attributes?.fdaindication },
                                                                    { key: 'MarketingStatus', value: list?.attributes?.MarketingStatus },
                                                                    { key: 'Company', value: list?.attributes?.Company },
                                                                    { key: 'routeofadmin', value: list?.attributes?.routeofadmin },
                                                                ].map((cell, cellIndex) => {
                                                                    const id = `Tooltip-${rowIndex}-${cellIndex}`;
                                                                    const cellContent = list?.attributes?.url ? (
                                                                        <a href={list?.attributes?.url} target="_blank" title={'Click for more information'}>
                                                                            {cell.value !== '' ? cell.value : 'N/A'}
                                                                        </a>
                                                                    ) : (
                                                                        <span>{cell.value !== '' ? cell.value : 'N/A'}</span>
                                                                    );
                                                                    return (
                                                                        <td key={cellIndex} id={id} className="tooltip-cell">
                                                                            {cellContent}
                                                                        </td>
                                                                    );
                                                                })}
                                                            </tr>
                                                        );
                                                    })}
                                                </table>
                                            </div>
                                            : 'N/A'}
                                    </div>
                                    :
                                    this.restrictedDiv()
                                }

                            </div>

                            <div className="drupPage-subConatiner">
                                <div className="drug-header">
                                    Patent Information
                                </div>
                                {this.checkAccessFn('Patent Information') ?
                                    <div className="drug-subheader my-2">
                                        {this.state.drugData?.attributes?.sostosPatent ? this.checkLinkPatent(this.state.drugData?.attributes?.sostosPatent) : 'N/A'}
                                    </div>
                                    :
                                    this.restrictedDiv()
                                }
                            </div>
                            <div className="drupPage-subConatiner">
                                <div className="drug-header">
                                    Lung Cancer Related Clinical Trials
                                </div>
                                {this.checkAccessFn('Lung Cancer Related Clinical Trials') ?
                                    <div className="drug-subheader my-2">
                                        {this.state.drugData?.attributes?.clin_trials?.data?.length > 0 ? <table className="drugPage-Table">
                                            <tr>
                                                <th>NCT Number</th>
                                                <th>Title</th>
                                                <th>Status</th>
                                                <th>Phase</th>
                                                <th>URL</th>
                                            </tr>
                                            {this.state.drugData?.attributes?.clin_trials?.data?.map(list => {
                                                return (
                                                    <tr>
                                                        <td>{list?.attributes?.NCT_Number ?? 'N/A'}</td>
                                                        <td>{list?.attributes?.title ?? 'N/A'}</td>
                                                        <td>{list?.attributes?.status ?? 'N/A'}</td>
                                                        <td>{list?.attributes?.phase ?? 'N/A'}</td>
                                                        <td>{list?.attributes?.url ?
                                                            <a href={list?.attributes?.url} target="_blank">
                                                                {list?.attributes?.url}
                                                            </a>
                                                            : 'N/A'}</td>
                                                    </tr>
                                                )
                                            })}
                                        </table> : 'N/A'}

                                    </div>
                                    :
                                    this.restrictedDiv()
                                }
                            </div>
                            <div className="drupPage-subConatiner">
                                <div className="drug-header">
                                    Past Case Reports
                                </div>
                                {this.checkAccessFn('Past Case Reports') ?
                                    <div className="drug-subheader my-2">
                                        {(this.state.drugData?.attributes?.past_case_reports?.data?.length > 0 && this.state.drugData?.attributes?.past_case_reports?.data[0]?.attributes?.caseReportID !== 'TBD' && this.state.drugData?.attributes?.past_case_reports?.data[0]?.attributes?.caseReport_descrption !== 'TBD') ? <table className="drugPage-Table">
                                            <tr>
                                                {/* <th style={{ width: '34%' }}>Report ID </th> */}
                                                <th style={{ width: '33%' }}>Title</th>
                                                <th style={{ width: '33%' }}>URL</th>
                                            </tr>
                                            {this.state.drugData?.attributes?.past_case_reports?.data?.map(list => {
                                                return (
                                                    <tr>
                                                        {/* <td>{list?.attributes?.caseReportID ?? 'N/A'}</td> */}
                                                        <td>{list?.attributes?.title ?? 'N/A'}</td>
                                                        <td>
                                                            {list?.attributes?.url ?
                                                                <a href={list?.attributes?.url} target="_blank">
                                                                    {list?.attributes?.url}
                                                                </a>
                                                                : 'N/A'}
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </table> : (this.state.drugData?.attributes?.past_case_reports?.data[0]?.attributes?.caseReportID === 'TBD' && this.state.drugData?.attributes?.past_case_reports?.data[0]?.attributes?.caseReport_descrption === 'TBD') ? 'TBD' : 'N/A'}
                                    </div>
                                    :
                                    this.restrictedDiv()
                                }
                            </div>
                            <div className="drupPage-subConatiner">
                                <div className="drug-header">
                                    Orphan Drug Information
                                </div>
                                {this.checkAccessFn('Orphan Drug Information') ?
                                    <div className="drug-subheader my-2">
                                        {this.state.drugData?.attributes?.orphans?.data?.length > 0 ? <table className="drugPage-Table">
                                            <tr>
                                                <th style={{ width: '34%' }}>Eneric Name</th>
                                                <th style={{ width: '33%' }}>Orphan Designation</th>
                                                <th style={{ width: '33%' }}>Designation Date</th>
                                                <th style={{ width: '33%' }}>Designation Status</th>
                                            </tr>
                                            {this.state.drugData?.attributes?.orphans?.data?.map(list => {
                                                return (
                                                    <tr>
                                                        <td>{list?.attributes?.eneric_Name ?? 'N/A'}</td>
                                                        <td>{list?.attributes?.Orphan_Designation ?? 'N/A'}</td>
                                                        <td>{list?.attributes?.Designation_Date ?? 'N/A'}</td>
                                                        <td>{list?.attributes?.Designation_Status ?? 'N/A'}</td>
                                                    </tr>
                                                )
                                            })}
                                        </table> : 'N/A'}
                                    </div>
                                    :
                                    this.restrictedDiv()
                                }
                            </div>
                            <div className="drupPage-subConatiner">
                                <div className="drug-header mb-3">
                                    Repositioning Mechanism of Action
                                </div>
                                {this.checkAccessFn('Repositioning Mechanism of Action') ?
                                    Object.keys(this.state.RMA_data).length !== 0 ?
                                        Object.entries(this.state.RMA_data).map(([MoAID, items]) => (
                                            <>
                                                <FileViewerComponent key={items?.fileData?.id} url={`${items?.fileData?.url}`} fileType={items?.fileData?.mime} refrencesList={this.state.refrencesList} renderHTMLReferenceCheck={this.renderHTMLReferenceCheck} handleClickScroll={this.handleClickScroll} />
                                                {items?.listID?.length === 1 ?
                                                    <table className="drugPage-Table">
                                                        <tr>
                                                            <td style={{ width: '50%' }}>Up-regulated Gene List</td>
                                                            <td style={{ width: '50%' }}>Down-regulated Gene List</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="drugPage_geneTableData">
                                                                    {items?.length > 0 ?
                                                                        items?.map(list => {
                                                                            if (list?.up_down == "up") {
                                                                                return (
                                                                                    <div className="drugPage_geneTableList">{list?.geneName}</div>
                                                                                )
                                                                            }
                                                                        })
                                                                        : 'N/A'}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="drugPage_geneTableData">
                                                                    {items?.length > 0 ?
                                                                        items?.map(list => {
                                                                            if (list?.up_down == "down") {
                                                                                return (
                                                                                    <div className="drugPage_geneTableList">{list?.geneName}</div>
                                                                                )
                                                                            }
                                                                        })
                                                                        : 'N/A'}
                                                                </div>

                                                            </td>
                                                        </tr>
                                                    </table> :
                                                    <table className="drugPage-Table">
                                                        <tr>
                                                            <td style={{ width: '50%' }}></td>
                                                            <td style={{ width: '50%' }}>Up-regulated Gene List</td>
                                                            <td style={{ width: '50%' }}>Down-regulated Gene List</td>
                                                        </tr>
                                                        {items?.listID.map(list_listID => {
                                                            return (
                                                                <tr>
                                                                    <td>
                                                                        <div className="drugPage_geneTableData_leftHeader">
                                                                            {list_listID ? `Gene List ${list_listID}` : 'N/A'}
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="drugPage_geneTableData">
                                                                            {items?.length > 0 ?
                                                                                items?.map(list => {
                                                                                    if (list?.up_down == "up" && list?.listID == list_listID) {
                                                                                        return (
                                                                                            <div className="drugPage_geneTableList">{list?.geneName}</div>
                                                                                        )
                                                                                    }
                                                                                })
                                                                                : 'N/A'}
                                                                        </div>


                                                                    </td>
                                                                    <td>
                                                                        <div className="drugPage_geneTableData">
                                                                            {items?.length > 0 ?
                                                                                items?.map(list => {
                                                                                    if (list?.up_down == "down" && list?.listID == list_listID) {
                                                                                        return (
                                                                                            <div className="drugPage_geneTableList">{list?.geneName}</div>
                                                                                        )
                                                                                    }
                                                                                })
                                                                                : 'N/A'}
                                                                        </div>

                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}

                                                    </table>
                                                }
                                            </>
                                        ))
                                        : 'N/A'
                                    :
                                    this.restrictedDiv()
                                }
                            </div>
                            <div className="drupPage-subConatiner">
                                <div className="drug-header">
                                    Targeted Pathway
                                </div>
                                {this.checkAccessFn('Targeted Pathway') ?
                                    <div className="drug-subheader my-2">
                                        {this.state.drugData?.attributes?.target_pathway ?? 'N/A'}
                                    </div>
                                    :
                                    this.restrictedDiv()
                                }
                            </div>
                            <div className="drupPage-subConatiner">
                                <div className="drug-header">
                                    Biological Pathway Significant in Connectivity Map (CMap)
                                </div>
                                {this.checkAccessFn('Biological Pathway Significant in Connectivity Map (CMap)') ?
                                    <div className="drug-subheader my-2">
                                        {this.state.drugData?.attributes?.CMapPathway ?? 'N/A'}
                                    </div>
                                    :
                                    this.restrictedDiv()
                                }
                            </div>
                            <div className="drupPage-subConatiner">
                                <div className="drug-header">
                                    Effective/Inhibitory Efficacy (EC<sub>50</sub>/IC<sub>50</sub>) in NSCLC Cell Lines
                                </div>
                                {this.checkAccessFn('Effective/Inhibitory Efficacy (EC50/IC50) in NSCLC Cell Lines') ?
                                    <>

                                        {
                                            this.state?.efficacy_ic_50_ec_50s_data?.length > 0 ?
                                                this.state.efficacy_ic_50_ec_50s_data?.map((list, index) => {
                                                    return (
                                                        <div className="my-2">
                                                            <img className="drugPage-img" src={list?.base64} alt="" />
                                                            <div className="drug-subheader my-2">
                                                                {this.renderHTMLReferenceCheck(list?.attributes?.efficacy_descr)}
                                                            </div>
                                                            {list?.attributes?.efficacyIC50EC50_detail?.data?.attributes?.url &&
                                                                <div className="item">
                                                                    <Button className="m-0 p-0" color="link" onClick={() => { this.downloadFile(list?.attributes?.efficacyIC50EC50_detail?.data?.attributes?.url, list?.attributes?.efficacyIC50EC50_detail?.data?.attributes?.name) }}
                                                                    >
                                                                        Detailed information
                                                                    </Button>
                                                                </div>}
                                                        </div>

                                                    )
                                                }) : 'N/A'
                                        }
                                    </>
                                    :
                                    this.restrictedDiv()
                                }
                            </div>
                            <div className="drupPage-subConatiner">
                                <div className="drug-header">
                                    Correlation of IC50/EC50 with Immune Checkpoint Inhibitor (ICI) Expression
                                </div>
                                {this.checkAccessFn('Correlation of Drug Response with Immune Checkpoint Inhibitor (ICI) Expression') ?
                                    this.state.correlation_ic_icorrs?.length > 0 ?
                                        this.state.correlation_ic_icorrs?.map((list, index) => {
                                            return (
                                                <div className="my-2">
                                                    <img className="drugPage-img" src={list?.base64} alt="" />
                                                    <div className="drug-subheader my-2">
                                                        {this.renderHTMLReferenceCheck(list?.attributes?.legend)}
                                                    </div>
                                                    {list?.attributes?.ICIcorr_detail?.data?.attributes?.url && <div className="item my-2">
                                                        <Button className="m-0 p-0" color="link" onClick={() => this.downloadFile(`${list?.attributes?.ICIcorr_detail?.data?.attributes?.url}`, list?.attributes?.ICIcorr_detail?.data?.attributes?.name)}
                                                        >
                                                            Detailed information
                                                        </Button>
                                                    </div>}
                                                </div>

                                            )
                                        }) : 'N/A'
                                    :
                                    this.restrictedDiv()
                                }

                            </div>
                            <div className="drupPage-subConatiner">
                                <div className="drug-header">
                                    Characteristics of Patient Responders
                                </div>
                                {this.checkAccessFn('Characteristics of Patient Responders') ?
                                    <>

                                        {(this.state?.CPRGeneData?.mRNA_Sensitive?.length > 0 || this.state?.CPRGeneData?.mRNA_Resistant?.length > 0 || this.state?.CPRGeneData?.CNV_amp_Resistant?.length > 0 || this.state?.CPRGeneData?.CNV_amp_Sensitive?.length > 0 || this.state?.CPRGeneData?.CNV_del_Resistant?.length > 0 || this.state?.CPRGeneData?.CNV_del_Sensitive?.length > 0 || this.state?.CPRGeneData?.methylation_Resistant?.length > 0 || this.state?.CPRGeneData?.methylation_Sensitive?.length > 0 || this.state?.CPRGeneData?.miRNA_Resistant?.length > 0 || this.state?.CPRGeneData?.miRNA_Sensitive?.length > 0 || this.state.CPRData?.length > 0 || this.state.drugData?.attributes?.responder_detail?.data?.attributes?.url) &&
                                            <div className="drug-subContainerCPR">
                                                <div className="drug-subHeaderCPR">
                                                    Drug Sensitivity Classification:
                                                </div>
                                                <div className="drug-desc">
                                                    {this.renderHTMLReferenceCheck(`Cell lines were classified as resistant if their half-maximal inhibitory concentration (IC50) or half-maximal effective concentration (EC50)
values of the studied drug exceeded the maximum dose (10 µm); conversely, cell lines were classified as sensitive if their IC50 or EC50
values of the studied drug were below the minimum dose (0.0006 µm). Utilizing the mean ± 0.5 standard deviations of the drug
sensitivity measurements, the remaining cell lines were categorized into three groups: sensitive, partial response, or resistant (Ye,
Mohamed, et al. 2021; Ye, Singh, et al. 2021).`)}
                                                </div>
                                            </div>
                                        }
                                        {(this.state?.CPRGeneData?.mRNA_Sensitive?.length > 0 || this.state?.CPRGeneData?.mRNA_Resistant?.length > 0 || this.state?.CPRGeneData?.CNV_amp_Resistant?.length > 0 || this.state?.CPRGeneData?.CNV_amp_Sensitive?.length > 0 || this.state?.CPRGeneData?.CNV_del_Resistant?.length > 0 || this.state?.CPRGeneData?.CNV_del_Sensitive?.length > 0 || this.state?.CPRGeneData?.methylation_Resistant?.length > 0 || this.state?.CPRGeneData?.methylation_Sensitive?.length > 0 || this.state?.CPRGeneData?.miRNA_Resistant?.length > 0 || this.state?.CPRGeneData?.miRNA_Sensitive?.length > 0) &&


                                            <div className="drug-subContainerCPR">
                                                <div className="drug-subHeaderCPR">
                                                    Genes Associated with Drug Sensitivity and Resistance:
                                                </div>
                                                {(this.state?.CPRGeneData?.mRNA_Sensitive?.length > 0 || this.state?.CPRGeneData?.mRNA_Resistant?.length > 0) &&
                                                    <>
                                                        <div className="drug-descHeaderCPR">
                                                            Genes with concordant mRNA and protein expression:
                                                        </div>
                                                        <div className="drug-desc">
                                                            {this.renderHTMLReferenceCheck(`Resistant genes were expressed significantly higher at both mRNA and protein level (p &lt; 0.05; two-sample t-tests) in resistant versus
sensitive NSCLC cell lines. Similarly, sensitive genes were expressed significantly higher at both mRNA and protein level (p &lt; 0.05;
two-sample t-tests) in sensitive versus resistant NSCLC cell lines (DepMap 2023; Nusinow et al. 2020).`)}
                                                        </div>
                                                        <table className="drugPage-Table">
                                                            <tr>
                                                                <td style={{ width: '50%' }}>Resistant Genes</td>
                                                                <td style={{ width: '50%' }}>Sensitive Genes</td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <div className="drugPage_geneTableData">
                                                                        {this.state?.CPRGeneData?.mRNA_Resistant?.length > 0 ?
                                                                            this.state?.CPRGeneData?.mRNA_Resistant?.map(list => {
                                                                                return (
                                                                                    <div className="drugPage_geneTableList">{list}</div>
                                                                                )
                                                                            })
                                                                            : 'N/A'}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="drugPage_geneTableData">
                                                                        {this.state?.CPRGeneData?.mRNA_Sensitive?.length > 0 ?
                                                                            this.state?.CPRGeneData?.mRNA_Sensitive.map(list => {
                                                                                return (
                                                                                    <div className="drugPage_geneTableList">{list}</div>
                                                                                )
                                                                            })
                                                                            : 'N/A'}
                                                                    </div>

                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </>
                                                }


                                                {(this.state?.CPRGeneData?.CNV_amp_Resistant?.length > 0 || this.state?.CPRGeneData?.CNV_amp_Sensitive?.length > 0 || this.state?.CPRGeneData?.CNV_del_Resistant?.length > 0 || this.state?.CPRGeneData?.CNV_del_Sensitive?.length > 0) &&
                                                    <>
                                                        <div className="drug-descHeaderCPR">
                                                            DNA Copy Number Variation:
                                                        </div>
                                                        <div className="drug-desc">
                                                            {this.renderHTMLReferenceCheck(`Resistant genes were 1) amplified, 2) expressed significantly higher at protein level, and 3) had no conflict at mRNA level in resistant
NSCLC cell lines. Similarly, sensitive genes were 1) amplified, 2) expressed significantly higher at protein level, and 3) had no conflict at
mRNA level in sensitive NSCLC cell lines (DepMap 2023; Nusinow et al. 2020). Copy number values were represented as log 2 (copy
number ratio + 1). The data were further categorized into amplification (copy number ratio ≥ 1.5), deletion (copy number ratio ≤ 0.5),
and normal (0.5 &lt; copy number ratio &lt; 1.5). DNA copy number variations associated with drug sensitivity were identified based on two
criteria: 1) for continuous copy number data, significant difference in sensitive versus resistant NSCLC cell lines (p &lt; 0.05; two-sample
t-tests), and 2) for categorized copy number data, significant association between amplification/deletion and drug sensitivity/resistance
(p &lt; 0.05, Chi-squared tests).`)}

                                                        </div>
                                                        <table className="drugPage-Table">
                                                            <tr>
                                                                <td style={{ width: '33%' }}>CNV</td>
                                                                <td style={{ width: '33%' }}>Resistant Genes</td>
                                                                <td style={{ width: '34%' }}>Sensitive Genes</td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <div className="drugPage_geneTableData">
                                                                        Amplification
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="drugPage_geneTableData">
                                                                        {this.state?.CPRGeneData?.CNV_amp_Resistant?.length > 0 ?
                                                                            this.state?.CPRGeneData?.CNV_amp_Resistant?.map(list => {
                                                                                return (
                                                                                    <div className="drugPage_geneTableList">{list}</div>
                                                                                )
                                                                            })
                                                                            : 'N/A'}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="drugPage_geneTableData">
                                                                        {this.state?.CPRGeneData?.CNV_amp_Sensitive?.length > 0 ?
                                                                            this.state?.CPRGeneData?.CNV_amp_Sensitive.map(list => {
                                                                                return (
                                                                                    <div className="drugPage_geneTableList">{list}</div>
                                                                                )
                                                                            })
                                                                            : 'N/A'}
                                                                    </div>

                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <div className="drugPage_geneTableData">
                                                                        Deletion
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="drugPage_geneTableData">
                                                                        {this.state?.CPRGeneData?.CNV_del_Resistant?.length > 0 ?
                                                                            this.state?.CPRGeneData?.CNV_del_Resistant?.map(list => {
                                                                                return (
                                                                                    <div className="drugPage_geneTableList">{list}</div>
                                                                                )
                                                                            })
                                                                            : 'N/A'}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="drugPage_geneTableData">
                                                                        {this.state?.CPRGeneData?.CNV_del_Sensitive?.length > 0 ?
                                                                            this.state?.CPRGeneData?.CNV_del_Sensitive.map(list => {
                                                                                return (
                                                                                    <div className="drugPage_geneTableList">{list}</div>
                                                                                )
                                                                            })
                                                                            : 'N/A'}
                                                                    </div>

                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </>
                                                }


                                                {(this.state?.CPRGeneData?.mutation_Resistant?.length > 0 || this.state?.CPRGeneData?.mutation_Sensitive?.length > 0) &&
                                                    <>
                                                        <div className="drug-descHeaderCPR">
                                                            Gene Mutation:
                                                        </div>
                                                        <div className="drug-desc">
                                                            {this.renderHTMLReferenceCheck(`Resistant genes 1) had significantly more mutations (p &lt; 0.05, Chi-squared tests), 2) differentially expressed at protein level (p &lt; 0.05,
two-sample t-tests), and 3) had no conflict at mRNA level in resistant NSCLC cell lines. Similarly, sensitive genes were 1) had
significantly more mutations (p &lt; 0.05, Chi-squared tests), 2) differentially expressed at protein level (p &lt; 0.05, two-sample t-tests), and
3) had no conflict at mRNA level in sensitive NSCLC cell lines (DepMap 2023; Nusinow et al. 2020).`)}
                                                        </div>
                                                        <table className="drugPage-Table">
                                                            <tr>
                                                                <td style={{ width: '50%' }}>Resistant Genes</td>
                                                                <td style={{ width: '50%' }}>Sensitive Genes</td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <div className="drugPage_geneTableData">
                                                                        {this.state?.CPRGeneData?.mutation_Resistant?.length > 0 ?
                                                                            this.state?.CPRGeneData?.mutation_Resistant?.map(list => {
                                                                                return (
                                                                                    <div className="drugPage_geneTableList">{list}</div>
                                                                                )
                                                                            })
                                                                            : 'N/A'}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="drugPage_geneTableData">
                                                                        {this.state?.CPRGeneData?.mutation_Sensitive?.length > 0 ?
                                                                            this.state?.CPRGeneData?.mutation_Sensitive.map(list => {
                                                                                return (
                                                                                    <div className="drugPage_geneTableList">{list}</div>
                                                                                )
                                                                            })
                                                                            : 'N/A'}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </>
                                                }

                                                {(this.state?.CPRGeneData?.methylation_Resistant?.length > 0 || this.state?.CPRGeneData?.methylation_Sensitive?.length > 0) &&
                                                    <>
                                                        <div className="drug-descHeaderCPR">
                                                            CpG Sites with DNA Methylation:
                                                        </div>
                                                        <div className="drug-desc">
                                                            {this.renderHTMLReferenceCheck(`Resistant genes 1) had more DNA methylations at CpG sites, 2) were expressed significantly higher at protein level (p &lt; 0.05, two-
sample t-tests), and 3) had no conflict at mRNA level in resistant NSCLC cell lines. Similarly, sensitive genes 1) had more DNA
methylations at CpG sites, 2) were expressed significantly higher at protein level (p &lt; 0.05, two-sample t-tests), and 3) had no conflict at
mRNA level in sensitive NSCLC cell lines. DNA methylation data were categorized in two ways (Luo et al. 2020): 1) Unmethylated site
(RRBS ≤ 0.2), Full-methylated site (RRBS ≥ 0.75), and Middle-methylated site (0.2 &lt; RRBS &lt; 0.75); 2) with a single cutoff of 0.6. DNA
methylations associated with drug sensitivity were identified based on two criteria: 1) for continuous DNA methylation RRBS values,
significant difference in sensitive versus resistant NSCLC cell lines (p &lt; 0.05; two-sample t-tests), and 2) for categorized DNA
methylation data, significant association between methylation status and drug sensitivity/resistance (p &lt; 0.05, Chi-squared tests)
(DepMap 2023; Nusinow et al. 2020).`)}
                                                        </div>
                                                        <table className="drugPage-Table">
                                                            <tr>
                                                                <td style={{ width: '50%' }}>Resistant CpG Sites</td>
                                                                <td style={{ width: '50%' }}>Sensitive CpG Sites</td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <div className="drugPage_geneTableData">
                                                                        {this.state?.CPRGeneData?.methylation_Resistant?.length > 0 ?
                                                                            this.state?.CPRGeneData?.methylation_Resistant?.map(list => {
                                                                                return (
                                                                                    <div className="drugPage_geneTableList">{list}</div>
                                                                                )
                                                                            })
                                                                            : 'N/A'}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="drugPage_geneTableData">
                                                                        {this.state?.CPRGeneData?.methylation_Sensitive?.length > 0 ?
                                                                            this.state?.CPRGeneData?.methylation_Sensitive.map(list => {
                                                                                return (
                                                                                    <div className="drugPage_geneTableList">{list}</div>
                                                                                )
                                                                            })
                                                                            : 'N/A'}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </>
                                                }
                                                {(this.state?.CPRGeneData?.fusion_Resistant?.length > 0 || this.state?.CPRGeneData?.fusion_Sensitive?.length > 0) &&
                                                    <>
                                                        <div className="drug-descHeaderCPR">
                                                            Gene Fusions:
                                                        </div>
                                                        <div className="drug-desc">
                                                            {this.renderHTMLReferenceCheck(`Resistant genes 1) had significantly more fusions (p &lt; 0.05, Chi-squared tests), 2) differentially expressed at protein level (p &lt; 0.05,
two-sample t-tests), and 3) had no conflict at mRNA level in resistant NSCLC cell lines. Similarly, sensitive genes were 1) had
significantly more fusions (p &lt; 0.05, Chi-squared tests), 2) differentially expressed at protein level (p &lt; 0.05, two-sample t-tests), and 3)
had no conflict at mRNA level in sensitive NSCLC cell lines (DepMap 2023; Nusinow et al. 2020).`)}
                                                        </div>
                                                        <table className="drugPage-Table">
                                                            <tr>
                                                                <td style={{ width: '50%' }}>Resistant Genes</td>
                                                                <td style={{ width: '50%' }}>Sensitive Genes</td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <div className="drugPage_geneTableData">
                                                                        {this.state?.CPRGeneData?.fusion_Resistant?.length > 0 ?
                                                                            this.state?.CPRGeneData?.fusion_Resistant?.map(list => {
                                                                                return (
                                                                                    <div className="drugPage_geneTableList">{list}</div>
                                                                                )
                                                                            })
                                                                            : 'N/A'}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="drugPage_geneTableData">
                                                                        {this.state?.CPRGeneData?.fusion_Sensitive?.length > 0 ?
                                                                            this.state?.CPRGeneData?.fusion_Sensitive.map(list => {
                                                                                return (
                                                                                    <div className="drugPage_geneTableList">{list}</div>
                                                                                )
                                                                            })
                                                                            : 'N/A'}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </>
                                                }
                                                {(this.state?.CPRGeneData?.miRNA_Resistant?.length > 0 || this.state?.CPRGeneData?.miRNA_Sensitive?.length > 0) &&
                                                    <>
                                                        <div className="drug-descHeaderCPR">
                                                            microRNA:
                                                        </div>
                                                        <div className="drug-desc">
                                                            {this.renderHTMLReferenceCheck(`Resistant miRNAs were expressed significantly higher (p &lt; 0.05; two-sample t-tests; FDR &lt; 0.25) in resistant versus sensitive NSCLC
cell lines. Similarly, sensitive miRNAs were expressed significantly higher (p &lt; 0.05; two-sample t-tests; FDR &lt; 0.25) in sensitive versus
resistant NSCLC cell lines (DepMap 2023).`)}
                                                        </div>
                                                        <table className="drugPage-Table">
                                                            <tr>
                                                                <td style={{ width: '50%' }}>Resistant miRNAs</td>
                                                                <td style={{ width: '50%' }}>Sensitive miRNAs</td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <div className="drugPage_geneTableData">
                                                                        {this.state?.CPRGeneData?.miRNA_Resistant?.length > 0 ?
                                                                            this.state?.CPRGeneData?.miRNA_Resistant?.map(list => {
                                                                                return (
                                                                                    <div className="drugPage_geneTableList">{list}</div>
                                                                                )
                                                                            })
                                                                            : 'N/A'}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="drugPage_geneTableData">
                                                                        {this.state?.CPRGeneData?.miRNA_Sensitive?.length > 0 ?
                                                                            this.state?.CPRGeneData?.miRNA_Sensitive.map(list => {
                                                                                return (
                                                                                    <div className="drugPage_geneTableList">{list}</div>
                                                                                )
                                                                            })
                                                                            : 'N/A'}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </>
                                                }
                                            </div>
                                        }

                                        {this.state.CPRData?.length > 0 &&
                                            <div className="drug-subContainerCPR">
                                                {this.state.CPRData?.map((list) => {
                                                    if (list?.attributes?.expanded_y?.toLowerCase() == 'no') {
                                                        return (

                                                            <>
                                                                <div className="drug-desc">
                                                                    {this.renderHTMLReferenceCheck(list?.attributes?.description)}
                                                                </div>
                                                                <div className="CPR-imgConatiner">
                                                                    <img className="CPR-img" src={list?.base64} alt="" />
                                                                </div>

                                                            </>
                                                        )
                                                    }
                                                })}
                                            </div>
                                        }

                                        {!(this.state?.CPRGeneData?.mRNA_Sensitive?.length > 0 || this.state?.CPRGeneData?.mRNA_Resistant?.length > 0 || this.state?.CPRGeneData?.CNV_amp_Resistant?.length > 0 || this.state?.CPRGeneData?.CNV_amp_Sensitive?.length > 0 || this.state?.CPRGeneData?.CNV_del_Resistant?.length > 0 || this.state?.CPRGeneData?.CNV_del_Sensitive?.length > 0 || this.state?.CPRGeneData?.methylation_Resistant?.length > 0 || this.state?.CPRGeneData?.methylation_Sensitive?.length > 0 || this.state?.CPRGeneData?.miRNA_Resistant?.length > 0 || this.state?.CPRGeneData?.miRNA_Sensitive?.length > 0 || this.state.CPRData?.length > 0 || this.state.drugData?.attributes?.responder_detail?.data?.attributes?.url) && 'N/A'}

                                        {(this.state?.CPRGeneData?.mRNA_Sensitive_Expanded != 'N/A' || this.state?.CPRGeneData?.mRNA_Resistant_Expanded != 'N/A' || this.state?.CPRGeneData?.CNV_amp_Resistant_Expanded != 'N/A' || this.state?.CPRGeneData?.CNV_amp_Sensitive_Expanded != 'N/A' || this.state?.CPRGeneData?.CNV_del_Resistant_Expanded != 'N/A' || this.state?.CPRGeneData?.CNV_del_Sensitive_Expanded != 'N/A' || this.state?.CPRGeneData?.methylation_Resistant_Expanded != 'N/A' || this.state?.CPRGeneData?.methylation_Sensitive_Expanded != 'N/A' || this.state?.CPRGeneData?.miRNA_Sensitive?.length > 0 || this.state?.CPRGeneData?.miRNA_Resistant?.length > 0) &&
                                            <div className="item">
                                                <Button className="m-0 p-0" color="link" onClick={this.exportPdf}>
                                                    Expanded Gene List
                                                </Button>
                                            </div>
                                        }

                                        {this.state.drugData?.attributes?.responder_detail?.data?.attributes?.url &&
                                            <div className="item">
                                                <Button className="m-0 p-0" color="link" onClick={() => { this.downloadFile(`${this.state.drugData?.attributes?.responder_detail?.data?.attributes?.url}`, this.state.drugData?.attributes?.responder_detail?.data?.attributes?.name) }}>
                                                    Detailed information
                                                </Button>
                                            </div>
                                        }
                                    </>
                                    :
                                    this.restrictedDiv()
                                }
                            </div>
                            <div className="drupPage-subConatiner">
                                <div className="drug-header">
                                    Recommended Dosage and Method for NSCLC
                                </div>
                                {this.checkAccessFn('Recommended Dosage and Method for NSCLC') ?
                                    <>
                                        {(this.state.drugData?.attributes?.recMethod && this.state.drugData?.attributes?.recDosage &&
                                            this.state.drugData?.attributes?.recMethod != 'TBD' && this.state.drugData?.attributes?.recDosage != 'TBD')
                                            ?
                                            <table className="drugPage-Table">
                                                <tr>
                                                    <th style={{ width: '34%' }}>Drug</th>
                                                    <th style={{ width: '33%' }}>Distribution Type</th>
                                                    <th style={{ width: '33%' }}>Dose</th>
                                                </tr>
                                                <tr>
                                                    <td>{this.state.drugData?.attributes?.drug ?? 'N/A'}</td>
                                                    <td>{this.state.drugData?.attributes?.recMethod ?? 'N/A'}</td>
                                                    <td>{this.state.drugData?.attributes?.recDosage ?? 'N/A'}</td>
                                                </tr>
                                            </table> :
                                            (this.state.drugData?.attributes?.recMethod === 'TBD' && this.state.drugData?.attributes?.recDosage === 'TBD') ? 'TBD' : 'N/A'
                                        }
                                        {this.state.drugData?.attributes?.deliveryRoute_detail?.data?.attributes?.url &&
                                            <div className="item my-2">
                                                <Button className="m-0 p-0" color="link" onClick={() => { this.downloadFile(`${this.state.drugData?.attributes?.deliveryRoute_detail?.data?.attributes?.url}`, this.state.drugData?.attributes?.deliveryRoute_detail?.data?.attributes?.name) }}
                                                >
                                                    Detailed information
                                                </Button>
                                            </div>}
                                    </>
                                    :
                                    this.restrictedDiv()
                                }
                            </div>
                            <div className="drupPage-subConatiner">
                                <div className="drug-header">
                                    Recommended Delivery Route for NSCLC
                                </div>
                                {this.checkAccessFn('Recommended Delivery Route for NSCLC') ?
                                    <div className="drug-subheader my-2">
                                        {this.state.drugData?.attributes?.deliveryRoute ?? 'N/A'}
                                    </div>
                                    :
                                    this.restrictedDiv()
                                }
                            </div>
                            <div className="drupPage-subConatiner">
                                <div className="drug-header">
                                    Recommended Nanoparticle Carriers for NSCLC
                                </div>
                                {this.checkAccessFn('Recommended Nanoparticle Carriers for NSCLC') ?
                                    <div className="drug-subheader my-2">
                                        {this.state.drugData?.attributes?.nanoparticleCarrier ?? 'N/A'}
                                    </div>
                                    :
                                    this.restrictedDiv()
                                }
                            </div>
                            <div className="drupPage-subConatiner">
                                <div className="drug-header">
                                    Structure Modification
                                </div>
                                {this.checkAccessFn('Structure Modification') ?
                                    <div className="drug-subheader my-2">
                                        {this.state.drugData?.attributes?.structure_modifications?.data[0]?.attributes?.structureModificationID ?? 'TBD'}
                                    </div>
                                    :
                                    this.restrictedDiv()
                                }
                            </div>
                            {this.state.refrencesList?.length > 0 &&
                                <div style={{ marginTop: '1.5rem' }}>
                                    <div className="drug-header">
                                        References
                                    </div>
                                    <div style={{ padding: '0.4rem 1.5rem 1rem' }}>
                                        {
                                            this.state.refrencesList?.map((list, index) => {
                                                return (
                                                    list?.attributes?.url ?
                                                        <div id={`section-${list?.id}`} style={{ display: "flex", fontSize: '0.75rem' }} className="mt-3">
                                                            <div style={{ marginLeft: '2px' }}>
                                                                <a href={list?.attributes?.url} target="_blank">
                                                                    {list?.attributes?.reference_content}
                                                                </a>
                                                            </div>

                                                        </div>
                                                        :
                                                        <div id={`section-${list?.id}`} style={{ display: "flex", fontSize: '0.75rem' }} className="mt-3">
                                                            {this.checkReferenceLinkFn(list?.attributes?.reference_content)}
                                                        </div>



                                                )
                                            })
                                        }

                                    </div>
                                </div>
                            }
                        </div>
                        <hr />
                        <DrugPageLikeQuery url={this.props?.location} loaderChange={this.loaderChange} idForAPI={this.state?.drugData?.id} />
                    </div>
                </div>
                {this.state.loader &&
                    <div className="backdrop">
                        <div className="loader">
                            <Loader type="ball-pulse-rise" />
                        </div>
                    </div>}
            </>
        );
    }
};

export default connect(mapStateToProps)(DrugPage);