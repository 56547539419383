import ThemeOptions from "./ThemeOptions";
import counterSlice from "./counterSlice";
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import persistConfig from "../store/persistConfig";

const rootReducer = combineReducers({
  ThemeOptions,
  counterSlice
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer;