import React, { Component } from 'react';
import lottie from 'lottie-web';
import animationData from '../../assets/gif/success.json';

class SuccessAnimation extends Component {
  componentDidMount() {
    this.animation = lottie.loadAnimation({
      container: this.animationContainer,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animationData,
    });
  }

  componentWillUnmount() {
    this.animation.destroy();
  }

  render() {
    return (
      <div 
        style={{ width: '120px', height: '120px' }}
        ref={(ref) => {
          this.animationContainer = ref;
        }}
      />
    );
  }
}

export default SuccessAnimation;