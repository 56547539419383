import React, { Component } from "react";
import {Button, Input } from "reactstrap";

export default class CardDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardHolderName: '',
      cardNumber: '',
      expiration: '',
      cvv: '',
      pincode: '', 
      errorMessage: ''
    };
  };

  handleCardHolderName = (e) => {
    this.setState({ cardHolderName: e.target.value });
  };
  handleExpiration = (e) => {
    this.setState({ expiration: e.target.value });
  };
  handleCVV = (e) => {
    this.setState({ cvv: e.target.value });
  };
  handlePinCode = (e) => {
    this.setState({ pincode: e.target.value });
  };
  handleSubmit = (e) => {
    
  };
 





  render() {
    return (
      <>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div className="card-details-div">
            <Input type="text" name="cardHolderName" className="card-holder-name"  placeholder="Cardholder Name"
              value={this.state.cardHolderName} onChange={this.handleCardHolderName} />
            <br></br>
            <div className="d-flex">
            <Input type="text" name="cardNumber" className="card-number"  placeholder="Card Number"
              value={this.state.cardNumber} onChange={this.handleCardNum} />
              
            <Input type="text" name="expiration" className="card-cvv" placeholder="Expiration"
              value={this.state.expiration} onChange={this.handleExpiration} />
              
            <Input type="text" name="cvv" className="card-cvv" placeholder="CVV"
              value={this.state.cvv} onChange={this.handleCVV} />
            </div>

            <br></br>
            <Input type="text" name="pincode"  className="card-pin" placeholder="Postal Code"
              value={this.state.pincode} onChange={this.handlePinCode} />
            <br></br>
           
          </div>
          <div className="save-button-div w-100">

            
            <Button type="submit" size="lg" color="primary" className="save-button"
              onClick={this.handleSubmit}
              disabled={!(this.state.cardHolderName && this.state.cardNumber && this.state.cvv && this.state.expiration && this.state.pincode)}>
              Update
            </Button>

          </div>
        </div>
          
          



      </>
    );
  }
}