import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { Button, FormGroup, Label, Input, FormFeedback } from "reactstrap";
import backlogo from '../../../assets/images/svg/Back.svg';
import { setPrimaryRole } from "../../../reducers/counterSlice";
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import Loader from "react-loaders";
import axios from "axios";
import { encryptPassword } from "../../../utils/passwordEncryption";

const mapStateToProps = (state) => {

    return {
        reduxData: state.counterSlice
    };
};

const mapDispatchToProps = {
    setPrimaryRole
};


class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            isValidPassword: '',
            confirmPassword: '',
            isValidConfirmPassword: '',
            errorMessage: '',
            passwordChangePopup: false,
            loader: false,
        };

    }





    handlePassChange = (e) => {
        this.setState({ password: e.target.value });
    };
    handleConfirmPassChange = (e) => {
        this.setState({ confirmPassword: e.target.value });
    };

    handleSubmit = async(e) => {
        try {
            e.preventDefault();
            const { password, confirmPassword } = this.state;
            
            if (password?.length < 8) {
                this.setState({ isValidPassword: 'Password must contain at least eight characters.', isValidConfirmPassword: '', errorMessage: "" })
            } else if (!(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,}$/.test(password))) {
                this.setState({ isValidPassword: 'Password must contain at least one number, both lower and uppercase letters, and one special character.', isValidConfirmPassword: '', errorMessage: "" });
            } else if (confirmPassword?.length < 8) {
                this.setState({ isValidConfirmPassword: 'Password must contain at least eight characters.', isValidPassword: '', errorMessage: "" })
            } else if (!(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,}$/.test(confirmPassword))) {
                this.setState({ isValidConfirmPassword: 'Password must contain at least one number, both lower and uppercase letters, and one special character.', isValidPassword: '', errorMessage: "" });
            } else if (password !== confirmPassword) {
                this.setState({ isValidPassword: '', isValidConfirmPassword: '', errorMessage: "Passwords do not match. Please try again." });
            } else {
                this.setState({ loader: true, isValidPassword: '', isValidConfirmPassword: '', errorMessage: "" });
                const encryptedPassword = await encryptPassword(password);
                axios.put(process.env.REACT_APP_BASE_URL + `api/user?id=${this.props?.match?.params?.id}`, {
                    'password': encryptedPassword
                })
                    .then(response => {
                        this.setState({ errorMessage: '', passwordChangePopup: true });
                    })
                    .catch(error => {
                        this.setState({ errorMessage: 'Something went wrong.Please try after sometime.' });
                    })
                    .finally(() => {
                        this.setState({ loader: false });
                    });
            }
        } catch (error) {
            console.error('Failed to encrypt password:', error);
        }
    }

    render() {
        return (
            <>

                <div >
                    <Link className='btn-link-primary'
                        to={{ pathname: '/auth/forgotpassword' }}>
                        <div className="d-inline-flex gap-2" >
                            <img src={backlogo} alt="" />
                            <span className="back-text">Back</span>
                        </div>
                    </Link>

                    <div className="login-text">Reset Password</div>

                    <div className="login-subtext mb-3">
                        You may now go ahead and reset your password.
                    </div>
                    <div className="">
                        <FormGroup floating>
                            <Input type="password" name="password" id="examplePassword" placeholder="Password" maxLength={16} minLength={8}
                                value={this.state.password} onChange={this.handlePassChange} invalid={this.state.isValidPassword} />
                            <Label for="examplePassword">
                                Password
                            </Label>
                            <FormFeedback>{this?.state?.isValidPassword}</FormFeedback>
                        </FormGroup>
                        <FormGroup floating>
                            <Input type="password" name="ConfirmPassword" id="exampleConfirmPassword" placeholder="Confirm Password" maxLength={16}
                                value={this.state.confirmPassword} onChange={this.handleConfirmPassChange} invalid={this.state?.isValidConfirmPassword} />
                            <Label for="exampleConfirmPassword">
                                Confirm Password
                            </Label>
                            <FormFeedback>{this?.state?.isValidConfirmPassword}</FormFeedback>
                        </FormGroup>
                        <div className="forgot-pass-text">
                            Should contain at least eight characters, at least one number, both lower and uppercase letters, and one special character.
                        </div>
                    </div>

                    <div className="login-button w-100 mt-4">

                        {this.state.errorMessage &&
                            <div className="error-msg-display">
                                {this.state.errorMessage}
                            </div>
                        }
                        <Button type="submit" size="lg" color="primary" className=" w-100"
                            onClick={this.handleSubmit}
                            disabled={!(this.state.password && this.state.confirmPassword)}>
                            Proceed
                        </Button>

                    </div>
                    <div className="remember-pass">
                        <div className="remember-pass-text">
                            Remember Your Password?
                        </div>
                        <div >
                            <Link
                                to={{ pathname: '/auth/login' }}>
                                <button className="btn btn-outline-primary "  >Sign In</button>
                            </Link>

                        </div>
                    </div>
                </div>
                <SweetAlert title="Success!" show={this.state.passwordChangePopup}
                    confirmBtnText='Sign In'
                    onConfirm={() => { this.setState({ passwordChangePopup: false }); this.props.history.push('/auth/login'); }}>
                    Your password has been successfully changed. You can now use your new password to log in securely. Thank you!
                </SweetAlert>
                {this.state.loader &&
                    <div className="backdrop">
                        <div className="loader">
                            <Loader type="ball-pulse-rise" />
                        </div>
                    </div>
                }
            </>
        );
    } ks
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
