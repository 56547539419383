import React, { Component } from "react";
import {
    FormGroup,
    Input,
    Button
} from "reactstrap";
import Select from "react-select";
import Loader from "react-loaders";
import ReactPaginate from 'react-paginate';
import { connect } from 'react-redux';
import { BiFile } from "react-icons/bi";
import { BiSolidLike } from "react-icons/bi";
import { AiOutlineLike } from "react-icons/ai";
import commentSvg from '../../assets/images/svg/comment.svg';
import axiosInstance from "../configuration/Request";
import { removeEmojis } from "../ReusableComponent/RemoveEmoji";
const mapStateToProps = (state) => {
    
    return {
        reduxData: state.counterSlice
    };
};


class ForumDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            pageCount: 1,
            selectedPage: 1,
            selectedOptionSortBy: { label: 'Latest', value: 'DESC' },
            sortByOptions: [
                { label: 'All', value: 'ASCI' },
                { label: 'Latest', value: 'DESC' },
                { label: 'Oldest', value: 'ASC' },
                { label: 'Most Liked', value: 'mostLiked' },
            ],
            queryList: [],
            searchText: '',
            communityGuideline: ''
        };
    }

    componentDidMount() {
        this.forumListApiCall();
        axiosInstance.get(process.env.REACT_APP_BASE_URL + `api/community-guideline`)
            .then(res => {
                
                this.setState({ communityGuideline: res.data?.data?.attributes?.guidelines })
            }).catch(err => {
                console.log(err)
            }).finally(() => {
            });
    };

    forumListApiCall = () => {
        this.setState({ loader: true })
        const idToCheck = this.props?.reduxData?.loginDetails?.user?.id;


        if (this.state?.selectedOptionSortBy?.value !== 'mostLiked') {
            axiosInstance
                .get(process.env.REACT_APP_BASE_URL + `api/forums?filters[title][$containsi]=${this.state?.searchText}&sort=${this.state?.selectedOptionSortBy?.label == 'All' ? `title:ASC` : `createdAt:${this.state?.selectedOptionSortBy?.value}`}&pagination[page]=${this.state.selectedPage?.toString()}&populate=user_comments&populate=liked_by&populate=posted_by`)
                .then(res => {
                    
                    res.data?.data.map((list) => {
                        const changedList = list;
                        changedList.attributes.liked = list?.attributes.liked_by.data.some(item => item.id === idToCheck)
                        return changedList
                    })

                    this.setState({ queryList: res.data?.data, pageCount: res?.data?.meta?.pagination?.pageCount })
                }).catch(err => {
                    console.log(err)
                }).finally(() => {
                    this.setState({ loader: false })

                });
        } else {

            axiosInstance
                .get(process.env.REACT_APP_BASE_URL + `api/mostLiked?title=${this.state?.searchText}&page=${this.state.selectedPage?.toString()}&pageSize=25`)
                .then(res => {
                    const ModifiedList = res.data?.data.map((list) => {
                        const changedList = {};
                        changedList.id = list?.id;
                        changedList.attributes = {
                            ...list,
                            liked: list?.liked_by?.some(item => item?.id === idToCheck),
                            liked_by: {
                                data: list?.liked_by
                            },
                            user_comments: {
                                data: list?.user_comments
                            },
                            posted_by: {
                                data: {
                                    attributes: {
                                        username: list?.posted_by?.username
                                    }
                                }
                            }
                        };
                        return changedList;
                    });

                    
                    this.setState({ queryList: ModifiedList, pageCount: res?.data?.meta?.pagination?.pageCount })
                }).catch(err => {
                    console.log(err)
                }).finally(() => {
                    this.setState({ loader: false })

                });

        }


    }

    handleChangeSortBy = (event) => {
        this.setState({ selectedOptionSortBy: event }, () => {
            this.forumListApiCall();
        });
    };


    handlePageClick = (event) => {
        
        this.setState({ selectedPage: event?.selected + 1 }, () => {
            this.forumListApiCall();
        })
    }

    searchTextChange = (event) => {
        const filteredValue = removeEmojis(event.target.value);
        this.setState({ searchText: filteredValue }, () => {
            this.forumListApiCall();
        })
    };

    getDays = (createdTime) => {
        const currentTime = new Date();
        const parsedTimestamp = new Date(createdTime);
        const differenceInMs = currentTime - parsedTimestamp;

        const hours = Math.floor(differenceInMs / (1000 * 60 * 60));
        const days = Math.floor(hours / 24);
        const minutes = Math.floor(differenceInMs / (1000 * 60));

        let difference;
        if (days > 0) {
            difference = `${days} day${days !== 1 ? 's' : ''}`;
        } else if (hours > 0) {
            difference = `${hours} hour${hours !== 1 ? 's' : ''}`;
        } else {
            difference = `${minutes} minute${minutes !== 1 ? 's' : ''}`;
        }

        return difference;
    }

    getLikeCount = (count) => {
        return count > 1 ? count + ' Likes' : count + ' Like';
    };

    getCommentCount = (count) => {
        return count > 1 ? count + ' Comments' : count + ' Comment';
    };

    toggleLikeForumLike = (list, liked) => {

        this.setState({ loader: true });
        let ids = []
        if (this.state?.selectedOptionSortBy?.value !== 'mostLiked') {
            ids = list?.attributes?.liked_by?.data?.map(item => item.id);
        } else {
            ids = list?.liked_by?.map(item => item.id);
        }
        

        if (!liked) {
            ids.push(this.props?.reduxData?.loginDetails?.user?.id)
        } else {
            ids.splice(ids.indexOf(this.props?.reduxData?.loginDetails?.user?.id), 1)
        }

        axiosInstance.put(process.env.REACT_APP_BASE_URL + `api/forums/${list?.id}`, {
            "data": {
                "liked_by": ids
            }
        })
            .then(response => {
                this.forumListApiCall();
            })
            .catch(error => {
            }).finally(() => {
            })
    };

    render() {

        const { loader, selectedOptionSortBy, sortByOptions, queryList, searchText, communityGuideline } = this.state;

        return (
            <>
                <div class="containerForum">
                    <div class="left_columnForum">
                        <div className="left_columnForumHeader">
                            <div>
                                <FormGroup>
                                    <Input
                                        id="exampleEmail"
                                        name="text"
                                        placeholder="Search"
                                        type="text"
                                        style={{ height: '48px' }}
                                        value={searchText}
                                        minLength={3}
                                        maxLength={50}
                                        onChange={this.searchTextChange}
                                    />
                                </FormGroup>
                            </div>
                            <div> <FormGroup>
                                <Select
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            height: '48px',
                                            cursor: "pointer"
                                        }),
                                        option: (base) => ({
                                            ...base,
                                            cursor: "pointer",
                                        }),
                                    }}
                                    value={selectedOptionSortBy} onChange={this.handleChangeSortBy} options={sortByOptions} />
                            </FormGroup>
                            </div>
                            <div>
                                <Button className="mb-2 me-2 w-100" color="primary" style={{ borderRadius: '4px', height: '48px', fontSize: '1rem' }} onClick={() => { this.props.history.push('/app/ForumNew') }}>
                                    New Discussion
                                </Button>
                            </div>
                        </div>
                        <div className="left_columnForumbody">
                            <div className="left_columnForumbodylist">
                                {(queryList?.length > 0 && !loader) ?
                                    queryList?.map(list => {
                                        return (
                                            < div className="" style={{ backgroundColor: '#FFFFFF', padding: '1rem', borderRadius: '0.5rem' }} key={list?.attributes?.drug}>
                                                <div className="drugList-header" onClick={() => { this.props.history.push(`/app/forumDescription/${list?.id}`) }} role='button'>
                                                    {list?.attributes?.title}
                                                </div>
                                                <div className="forumDescription_body_subtitle">
                                                    <div className="d-flex ">
                                                        <div className="header-userNameInitial">
                                                            {list?.attributes?.posted_by?.data?.attributes?.username?.substring(0, 1) ?? 'D'}
                                                        </div>
                                                        <div>
                                                            <span className="" style={{ marginTop: '0.2rem' }}>
                                                                {list?.attributes?.posted_by?.data?.attributes?.username ?? 'Deleted User'}
                                                            </span>{' '}
                                                            asked {this.getDays(list?.attributes?.createdAt)} ago
                                                        </div>
                                                    </div>
                                                    <div className="d-flex gap-3">
                                                        <div className="d-flex">
                                                            {(list?.attributes?.liked) ? <BiSolidLike size={'19px'} color={'#545cd8'} /> : <AiOutlineLike size={'19px'} />}
                                                            <div className="ms-1" style={{ color: '#333333', fontWeight: 400 }}>{this.getLikeCount(list?.attributes?.liked_by?.data?.length)}</div>
                                                        </div>
                                                        <div className="d-flex" >
                                                            <img src={commentSvg} />
                                                            <div className="ms-1 text-nowrap" style={{ color: '#333333', fontWeight: 400 }}>{this.getCommentCount(list?.attributes?.user_comments?.data?.length)}</div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        )
                                    })
                                    :
                                    !loader &&
                                    <div className="cancerPage-noDataFound">
                                        <span className="lnr-magnifier"> No data found based on your search.</span>
                                    </div>
                                }
                            </div>
                            {
                                <ReactPaginate
                                    nextLabel=">"
                                    onPageChange={this.handlePageClick}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={2}
                                    pageCount={this.state?.pageCount}
                                    forcePage={this.state.selectedPage - 1}
                                    previousLabel="<"
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    breakLabel="..."
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link"
                                    containerClassName="pagination"
                                    activeClassName="active"
                                    renderOnZeroPageCount={null}
                                />
                            }
                        </div>
                    </div>

                    <div class="right_columnForum">
                        <div className="right_columnForumbody">
                            {communityGuideline}
                        </div>
                        <hr></hr>
                        <div className="right_columnForumHeader" onClick={() => { this.props?.history?.push('/app/guidelines') }}>
                            <BiFile size={'20px'} /> Community guidelines
                        </div>

                    </div>
                </div>
                {loader &&
                    <div className="backdrop">
                        <div className="loader">
                            <Loader type="ball-pulse-rise" />
                        </div>
                    </div>
                }
            </>
        );
    }
}

export default connect(mapStateToProps)(ForumDashboard);
