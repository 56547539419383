import React, { Component } from "react";
import { connect } from 'react-redux';
import Loader from "react-loaders";
import { Slide, ToastContainer } from "react-toastify";

const mapStateToProps = (state) => {
    
    return {
        reduxData: state.counterSlice
    };
};

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    };

componentDidMount = () => {
    
}

    render() {

        return (
            <>
                <div>
                    <div className="grid-container-profile">
                        <div className="grid-item-profile-header">Name:</div>
                        <div className="grid-item-profile">{this.props?.reduxData?.loginDetails?.user?.username ?? 'N/A'}</div>
                        <div className="grid-item-profile-header">Email:</div>
                        <div className="grid-item-profile">{this.props?.reduxData?.loginDetails?.user?.email ?? 'N/A'}</div>
                        <div className="grid-item-profile-header">Profession:</div>
                        <div className="grid-item-profile">{this.props?.reduxData?.loginDetails?.user?.profession ? this.props?.reduxData?.loginDetails?.user?.profession :  'N/A'}</div>
                        <div className="grid-item-profile-header">Work:</div>
                        <div className="grid-item-profile">{this.props?.reduxData?.loginDetails?.user?.work ?this.props?.reduxData?.loginDetails?.user?.work :  'N/A'}</div>
                    </div>
                </div>
                <ToastContainer transition={Slide} />
                {this.state.loader &&
                    <div className="backdrop">
                        <div className="loader">
                            <Loader type="ball-pulse-rise" />
                        </div>
                    </div>
                }

            </>
        );
    }
}

export default connect(mapStateToProps)(Profile);
