import React, { Component } from "react";
import { Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import downArrow from "../../assets/images/svg/down-arrow.svg";
import signout from "../../assets/images/svg/Signout.svg";
import profile from "../../assets/images/png/icons8-customer-64.png";
import SOSTOSLogo from "../../assets/images/svg/Sostoslogo.svg";
import { Link } from "react-router-dom";
import { setloginDetails, setupgradePopup, setlogoutPopup } from "../../reducers/counterSlice";
import { connect } from 'react-redux';
import { AiOutlineDownload } from "react-icons/ai";
import { AiOutlinePrinter } from "react-icons/ai";
import eventEmitter from "../ReusableComponent/EventEmitter";
import SweetAlert from 'react-bootstrap-sweetalert';
import SilverCrown from '../../assets/images/svg/SilverCrown.svg'
import GoldCrown from '../../assets/images/svg/GoldCrown.svg'

const dropdownMenuStyle = {
  width: "200px",
  maxHeight: "300px",
  overflowY: "auto",
};

const arrowRotateStyle = {
  transform: "rotate(180deg)",
};

const buttonStyles = {
  backgroundColor: "transparent",
  color: "inherit",
  ":hover": {
    backgroundColor: "transparent",
    color: "inherit",
  },
  ":active": {
    backgroundColor: "transparent",
    color: "inherit",
  },
  ":focus": {
    backgroundColor: "transparent",
    color: "inherit",
  },
};

const mapStateToProps = (state) => {
  return {
    reduxData: state.counterSlice
  };
};
const mapDispatchToProps = {
  setloginDetails, setupgradePopup, setlogoutPopup
};

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      userName: props?.reduxData?.loginDetails?.user?.username,
      printAndDownloadVisibilty: props.location?.pathname.includes('drugpage') ?? false,
      activePlanDetails: {},
      upgradePopup: props?.reduxData?.upgradePopup ?? true,
      logoutPopup: props?.reduxData?.logoutPopup ?? false,
    };
  }

  componentDidMount() {
    const queryString = new URLSearchParams(this.props?.location?.search);
    

    let highestIdObject = { id: 0 };
    if (this.props?.reduxData?.loginSubscriptionDetail?.length > 0) {

      this.props?.reduxData?.loginSubscriptionDetail?.forEach(item => {
        if (item.id > highestIdObject?.id) {

          highestIdObject = { ...item };
        }
      });
    }
    
    this.setState({ activePlanDetails: highestIdObject })
    window.showLogoutPopup = this.showLogoutPopup;


  }

  componentDidUpdate(prevProps) {
    
    const { location, reduxData } = this.props;

    if (location?.pathname !== prevProps.location?.pathname) {
      const shouldShowPrintAndDownload = location?.pathname.includes('drugpage') ?? false;

      if (this.state.printAndDownloadVisibilty !== shouldShowPrintAndDownload) {
        this.setState({ printAndDownloadVisibilty: shouldShowPrintAndDownload });
      }
    }

    // if (reduxData?.logoutPopup !== prevProps.reduxData?.logoutPopup) {
    //   this.setState({ logoutPopup: location?.reduxData?.logoutPopup ?? false });
    // }

  }

  showLogoutPopup = () => {
    this.setState({ logoutPopup: true });
  }

  toggleDropdown = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };

  onLogoutClicked = () => {
    this.props?.setloginDetails('');
  }

  handlePrint = () => {
    eventEmitter.emit('callhandlePrintPDF');

  };

  handleDownloadPDF = () => {
    eventEmitter.emit('callhandleDownloadPDF');
  };

  linkStyle = (isSelected) => ({
    fontWeight: isSelected ? 'bold' : 'normal',
    // fontSize: isSelected ? '16px' : '14px',
    color: isSelected ? '#434aad' : 'inherit',
    borderBottom: isSelected ? '2px solid #434aad' : 'none',
    borderRadius: isSelected ? '1px' : 'none',
    padding: isSelected ? '12px 2px' : 'none',
  });

  render() {
    const { dropdownOpen, userName, printAndDownloadVisibilty, activePlanDetails } = this.state;
    return (
      <>
        <div className="header-container">

          <div className="header-leftContent">
            <div className="header-logo">
              <Link
                className="btn-link-primary"
                to={{ pathname: `/` }}
              >
                <img src={SOSTOSLogo} alt="" style={{ width: "120px" }} />
              </Link>
            </div>
            <div className="d-flex align-items-baseline gap-4">
              {/* <div className="header-search" > */}
              <div className={`header-search ${this.props?.location?.pathname?.includes('cancer') || this.props?.location?.pathname?.includes('drug') ? 'header-search-active' : ''}`}>
                <Link
                  className="btn-link-primary "
                  to={{ pathname: `/app/cancerType` }}
                // style={this.linkStyle(this.props?.location?.pathname?.includes('cancer') || this.props?.location?.pathname?.includes('drug'))}
                >
                  <span style={{ fontSize: '1rem' }}>Explore</span>
                </Link>
              </div>
              {/* <div className="header-search"> */}
              <div className={`header-search ${this.props?.location?.pathname?.toLowerCase()?.includes('forum') ? 'header-search-active' : ''}`}>
                <Link
                  className="btn-link-primary"
                  to={{ pathname: `/app/forum` }}
                // style={this.linkStyle(this.props?.location?.pathname?.toLowerCase()?.includes('forum'))}
                >
                  <span style={{ fontSize: '1rem' }}>Forum</span>
                </Link>
              </div>
            </div>
          </div>
          <div className="header-rightContent d-flex gap-4">
            {/* Subscription_Commented_Code */}
            {/* Uncomment this Subscription_Commented_Code */}
            {/* {activePlanDetails?.attributes?.plan?.toLowerCase() === 'bronze' &&
              <div>
                <Link
                  to={{
                    pathname: '/app/profileEdit',
                    state: { param: 'billing' }
                  }}
                >
                  <Button className="w-100" color="primary" style={{ borderRadius: '22px', height: '40px', fontSize: '1rem' }} >
                    Subscribe Now
                  </Button>
                </Link>
              </div>
            } */}
            {printAndDownloadVisibilty &&
              <>
                <div className="AiOutlineBackground">
                  <AiOutlineDownload size={'1rem'} onClick={this.handleDownloadPDF} />
                </div>
                <div className="AiOutlineBackground">
                  <AiOutlinePrinter size={'1rem'} onClick={this.handlePrint} />

                </div>
              </>
            }
            <div>
              <Dropdown isOpen={dropdownOpen} toggle={this.toggleDropdown}>
                <DropdownToggle
                  tag="div"
                  data-toggle="dropdown"
                  aria-expanded={dropdownOpen}
                >
                  <Button
                    className="header-rightContent-user"
                    style={buttonStyles}
                  >
                    <div className="header-rightContent-userNameInitial">
                      {userName?.substring(0, 1)}
                      {/* Subscription_Commented_Code */}
                      {/* Uncomment this Subscription_Commented_Code */}
                      {/* {activePlanDetails?.attributes?.plan?.toLowerCase() === 'silver' && <img src={SilverCrown} alt="Crown" className="crown-icon" />}
                      {activePlanDetails?.attributes?.plan?.toLowerCase() === 'gold' && <img src={GoldCrown} alt="Crown" className="crown-icon" />} */}
                    </div>
                    <div className="header-rightContent-userName">{userName}</div>
                    <div>
                      <img
                        src={downArrow}
                        alt=""
                        style={{
                          width: "10px",
                          ...(dropdownOpen ? arrowRotateStyle : {}),
                        }}
                      />
                    </div>
                  </Button>
                </DropdownToggle>
                <DropdownMenu style={dropdownMenuStyle}>
                  <Link to={{ pathname: '/app/profileEdit' }}>
                    <DropdownItem>

                      <div className="d-flex">
                        <div>
                          <img src={profile}
                            alt=""
                            style={{
                              width: "20px",
                            }} />
                        </div>
                        <div className="Over-Flow-Item" style={{ paddingLeft: "8px" }}>
                          Edit Profile
                        </div>
                      </div>


                    </DropdownItem>
                  </Link>
                  <Link to={{ pathname: '/' }} >
                    <DropdownItem onClick={this.onLogoutClicked}>

                      <div className="d-flex">
                        <div>
                          <img src={signout}
                            alt=""
                            style={{
                              width: "23px",
                              height: "15px",
                            }} />
                        </div>
                        <div className="Over-Flow-Item" style={{ paddingLeft: "6px" }}>
                          Sign Out
                        </div>
                      </div>


                    </DropdownItem>
                  </Link>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
        </div>

        <SweetAlert
          show={this.state.upgradePopup}
          custom
          showCancel
          showCloseButton
          confirmBtnText="Upgrade Subscription"
          cancelBtnText="No"
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="light"
          onConfirm={() => { this.setState({ upgradePopup: false }); this.props.history.push({ pathname: '/app/profileEdit', state: { param: 'billing' } }); this.props?.setupgradePopup(false); }}
          onCancel={() => { this.setState({ upgradePopup: false }); this.props?.setupgradePopup(false); }}
        >
          Upgrade to premium subscription plan to access the data without any limitations.!
        </SweetAlert>
        <SweetAlert
          show={this.state?.logoutPopup}
          style={{ width: '550px' }}
          title="Oops! Your session timed out."
          confirmBtnText="Sign Back In"
          onConfirm={() => { this.setState({ logoutPopup: false }); this.props.history.push({ pathname: '/auth/login' }); this.props?.setlogoutPopup(false); }}
          onCancel={() => { this.setState({ logoutPopup: false }); this.props.history.push({ pathname: '/auth/login' }); this.props?.setlogoutPopup(false); }}>
          It looks like you've been inactive for a while. To keep your account secure, we've logged you out. Please log in to pick up where you left off.
        </SweetAlert>
      </>


    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
